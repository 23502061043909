import React, { useState, useEffect, Fragment } from "react";
import { useToast } from '../../../shared/toast/ToastContent';
import "./LoadManagement.css";
import { handleGetConnectorsForCharger, resetConnectorPowerLimit } from "../../../stores/chargerStore";
import { sleep } from "../../../helpers/timeHelper";
import Spinner from "../../../shared/spinner/Spinner";

interface Connector {
    id: number;
    connectorName: string,
    connectorType: string,
    electricCurrent: string,
    maxPower?: number,
    powerLimit?: number,
    staticPowerLimit?: number,
}

interface Props {
    selectedCharger: number,
    handleExit: (changed: boolean) => void
}

const ResetPowerLimit: React.FC<Props> = ({ selectedCharger, handleExit }) => {
    const [selected, setSelected] = useState<number[]>([]);
    const [connectors, setConnectors] = useState<Connector[]>([] as Connector[]);
    const [loading, setLoading] = useState<boolean>(false);
    const { showToast } = useToast();

    useEffect(() => {
        const fetchConnectors = async () => {
            const data = await handleGetConnectorsForCharger(selectedCharger);
            if (data?.connectors) {
                setConnectors(data.connectors);
            }
        }
        fetchConnectors();
    }, [selectedCharger])

    const handleSelection = (id: number) => {
        setSelected((prevSelected) => {
            if (!prevSelected.includes(id)) {
                return [...prevSelected, id];
            } else {
                return prevSelected.filter((selectedId) => selectedId !== id);
            }
        });
    };

    const handleResetBtnClick = async () => {
        const resetPowerLimit = async () => {
            selected.forEach(id => resetConnectorPowerLimit(id));
        };
        setLoading(true);
        await resetPowerLimit();
        await sleep(1000);
        await handleGetConnectorsForCharger(selectedCharger);
        handleExit(true);
        setLoading(false);
        showToast('Power limit reset successful', 'success');
    };

    return (
        <>
            <div className="modal-background" onClick={() => handleExit(false)} />
            <div className="absolute-set-power-limit pt-2">
                <div className="flex flex-col items-center justify-start gap-3">
                    <div className="flex justify-end items-center w-full p-5">
                        <img
                            src="/icons/exit/cross-big.svg"
                            alt=""
                            onClick={() => handleExit(false)}
                            className="cursor-pointer"
                        />
                    </div>
                    <div className="horizontal-gray-line w-full"></div>
                    <div className="flex flex-col justify-start items-stretch w-full px-10 py-7 gap-7">
                        <div className="text-[20px] font-semibold text-[#111F47]">
                            Are you sure you want to reset the power limit, and use the default charger max power?
                        </div>
                        <div className="flex flex-col items-stretch justify-between overflow-auto table-container overflow-visible">
                            <table className="table table-striped">
                                <thead>
                                    <tr className="header-row table-row">
                                        <th></th>
                                        <th>Connector ID</th>
                                        <th>Connector Type</th>
                                        <th>Electric Current</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {connectors.map((connector, index) => (
                                        <Fragment key={index}>
                                            <tr
                                                className={
                                                    selected.includes(connector.id)
                                                        ? "selected-row selectable-row"
                                                        : "selectable-row"

                                                }
                                                onClick={() => handleSelection(connector.id)}
                                            >
                                                <td>

                                                    {selected.includes(connector.id) ? (
                                                        <img
                                                            src="/icons/correct-incorrect-icons/correct-icon-squared-large.svg"
                                                            alt=""
                                                        />
                                                    ) : (
                                                        <div className="checker-border-large" />
                                                    )}
                                                </td>
                                                <td
                                                    className={
                                                        selected.includes(connector.id)
                                                            ? "td-selected-row"
                                                            : ""
                                                    }
                                                >
                                                    {connector.connectorName}
                                                </td>
                                                <td
                                                    className={
                                                        selected.includes(connector.id)
                                                            ? "td-selected-row"
                                                            : ""
                                                    }
                                                >
                                                    {connector.connectorType}
                                                </td>
                                                <td
                                                    className={
                                                        selected.includes(connector.id)
                                                            ? "td-selected-row"
                                                            : ""
                                                    }
                                                >
                                                    {connector.electricCurrent}
                                                </td>
                                            </tr>
                                        </Fragment>))}
                                </tbody>
                            </table>
                        </div>
                        <div className="w-full mt-2 flex justify-end">
                            <button
                                className={`register-btn ${selected.length > 0 && 'register-btn-active'}`}
                                disabled={selected.length === 0}
                                onClick={handleResetBtnClick}
                            >
                                Reset
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            {loading && <Spinner />}

        </>
    );
};

export default ResetPowerLimit;