import { useEffect, useState } from "react";
import { Connector, IChargerRoaming } from "../../../interfaces/IRoaming";
import { useToast } from "../../../shared/toast/ToastContent";
import { PublishChargerOnRoaming } from "../../../stores/roamingStore";

interface Props {
  charger: IChargerRoaming;
  close: (value: boolean) => void;
  closeChargerModal: (value: number) => void;
  refetchUnpublished: () => void;
}

const PublishPopup = ({
  close,
  charger,
  closeChargerModal,
  refetchUnpublished,
}: Props) => {
  const [selectedConnector, setSelectedConnector] = useState<Connector | null>(
    charger.connectors[0]
  );
  const { showToast } = useToast();

  const handlePublish = async () => {
    if (charger) {
      const formattedHotlinePhoneNumber =
        charger.hotlinePhoneNumber?.startsWith("+")
          ? charger.hotlinePhoneNumber
          : `+${charger.hotlinePhoneNumber}`;

      await PublishChargerOnRoaming(
        charger.id,
        formattedHotlinePhoneNumber!,
        charger.chargingStationName!,
        charger.accessibility!,
        charger.accessibilityLocation!
      ).then((res) => {
        if (res) {
          refetchUnpublished();
          showToast("EVSEs successfully published", "success");
          close(false);
          closeChargerModal(0);
        } else {
          close(false);
          closeChargerModal(0);
          showToast("Error while publishing EVSEs", "error");
        }
      });
    }
  };

  useEffect(() => {
    document.body.classList.add("overflow-hidden");
    return () => {
      document.body.classList.remove("overflow-hidden");
    };
  }, []);

  return (
    <>
      <div className="modal-background" />
      <div
        style={{ zIndex: "1200" }}
        className="absolute inset-0 flex justify-center items-center"
      >
        <div
          style={{
            boxShadow: "0px 14px 7.8px 0px rgba(0, 0, 0, 0.10)",
          }}
          className="flex flex-col bg-white w-[505px] rounded-lg"
        >
          <div className="flex flex-col p-6 w-full">
            <div className="flex flex-col w-full">
              <p className="text-[20px] font-semibold text-[#111F47]">
                Are you sure you want to publish EVSEs?
              </p>
              <p className="text-[16px] font-semibold text-[#B5BAC6]">
                Review all entered data one more time
              </p>
              <div className="flex flex-col items-center w-full pr-4 max-w-md mx-auto mt-8">
                <div className="flex w-full relative">
                  {charger.connectors.map((connector, index) => (
                    <button
                      key={index}
                      onClick={() => setSelectedConnector(connector)}
                      className={`flex-1 text-left px-1 mr-2 text-[16px] cursor-pointer transition-colors overflow-hidden ${
                        selectedConnector?.connectorName ===
                        connector.connectorName
                          ? "text-blue-600 font-semibold"
                          : "text-gray-500"
                      }`}
                    >
                      {connector.connectorName}
                    </button>
                  ))}
                </div>

                <div className="relative w-full h-1 bg-gray-300 mt-2">
                  <div
                    className="absolute h-full bg-blue-600 transition-transform duration-150"
                    style={{
                      width: `${100 / charger.connectors.length}%`,
                      transform: `translateX(${
                        charger.connectors.findIndex(
                          (connector) =>
                            connector.connectorName ===
                            selectedConnector?.connectorName
                        ) * 100
                      }%)`,
                    }}
                  />
                </div>
              </div>
              <div className="flex flex-col mt-4 py-2 pr-3 h-[350px] overflow-y-auto custom-scrollbar-roaming">
                <div className="flex items-center justify-between py-2.5">
                  <p className="text-[14px] font-medium text-[#111F47]">
                    EVSE ID
                  </p>
                  <p className="text-[14px] font-medium text-[#B5BAC6]">
                    {selectedConnector?.evseId}
                  </p>
                </div>
                <img
                  className="w-full"
                  src="/icons/roaming/line.svg"
                  alt="line"
                />
                <div className="flex items-center justify-between py-2.5">
                  <p className="text-[14px] font-medium text-[#111F47]">
                    Accessibility
                  </p>
                  <p className="text-[14px] font-medium text-[#B5BAC6]">
                    {charger?.accessibility}
                  </p>
                </div>
                <img
                  className="w-full"
                  src="/icons/roaming/line.svg"
                  alt="line"
                />
                <div className="flex items-center justify-between py-2.5">
                  <p className="text-[14px] font-medium text-[#111F47]">
                    Accessibility location
                  </p>
                  <p className="text-[14px] font-medium text-[#B5BAC6]">
                    {charger?.accessibilityLocation}
                  </p>
                </div>
                <img
                  className="w-full"
                  src="/icons/roaming/line.svg"
                  alt="line"
                />
                <div className="flex items-center justify-between py-2.5">
                  <p className="text-[14px] font-medium text-[#111F47]">
                    Hotline phone number
                  </p>
                  <p className="text-[14px] font-medium text-[#B5BAC6]">
                    +{charger?.hotlinePhoneNumber}
                  </p>
                </div>
                <img
                  className="w-full"
                  src="/icons/roaming/line.svg"
                  alt="line"
                />
                <div className="flex items-center justify-between py-2.5">
                  <p className="text-[14px] font-medium text-[#111F47]">
                    Charging station name
                  </p>
                  <p className="text-[14px] font-medium text-[#B5BAC6]">
                    {charger?.chargingStationName}
                  </p>
                </div>
                <img
                  className="w-full"
                  src="/icons/roaming/line.svg"
                  alt="line"
                />
                <div className="flex items-center justify-between py-2.5">
                  <p className="text-[14px] font-medium text-[#111F47]">
                    Location name
                  </p>
                  <p className="text-[14px] font-medium text-[#B5BAC6]">
                    {charger?.locationName}
                  </p>
                </div>
                <img
                  className="w-full"
                  src="/icons/roaming/line.svg"
                  alt="line"
                />
                <div className="flex items-center justify-between py-2.5">
                  <p className="text-[14px] font-medium text-[#111F47]">
                    Address
                  </p>
                  <p className="text-[14px] font-medium text-[#B5BAC6]">
                    {charger?.address}
                  </p>
                </div>
                <img
                  className="w-full"
                  src="/icons/roaming/line.svg"
                  alt="line"
                />
                <div className="flex items-center justify-between py-2.5">
                  <p className="text-[14px] font-medium text-[#111F47]">
                    GeoCoordinates
                  </p>
                  <p className="text-[14px] font-medium text-[#B5BAC6]">
                    {charger?.longitude.toFixed(3)},{" "}
                    {charger?.latitude.toFixed(3)}
                  </p>
                </div>
                <img
                  className="w-full"
                  src="/icons/roaming/line.svg"
                  alt="line"
                />
                <div className="flex items-center justify-between py-2.5">
                  <p className="text-[14px] font-medium text-[#111F47]">
                    Plug type
                  </p>
                  <p className="text-[14px] font-medium text-[#B5BAC6]">
                    {selectedConnector?.connectorType}
                  </p>
                </div>
                <img
                  className="w-full"
                  src="/icons/roaming/line.svg"
                  alt="line"
                />
                <div className="flex items-center justify-between py-2.5">
                  <p className="text-[14px] font-medium text-[#111F47]">
                    Power type
                  </p>
                  <p className="text-[14px] font-medium text-[#B5BAC6]">
                    {selectedConnector?.powerType}
                  </p>
                </div>
                <img
                  className="w-full"
                  src="/icons/roaming/line.svg"
                  alt="line"
                />
                <div className="flex items-center justify-between py-2.5">
                  <p className="text-[14px] font-medium text-[#111F47]">
                    Power in kW
                  </p>
                  <p className="text-[14px] font-medium text-[#B5BAC6]">
                    {selectedConnector?.powerKw}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="flex w-full">
            <button
              onClick={() => close(false)}
              className="text-[16px] font-medium h-[57px] bg-[#E7E9ED] text-[#111F47] px-2.5 w-full rounded-bl-lg"
            >
              No, Go Back
            </button>
            <button
              onClick={handlePublish}
              className="text-[16px] font-medium h-[57px] bg-[#1E4CDC] text-[#FFFFFF] px-2.5 w-full rounded-br-lg"
            >
              Yes, Publish
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default PublishPopup;
