import Papa from "papaparse";

export const downloadCSV = (data: any, fileName: string) => {
  const csv = Papa.unparse(data);
  const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
  const urlCSV = URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.href = urlCSV;
  a.download = `${fileName}.csv`;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
};
