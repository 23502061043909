import React, { useState, useEffect, useMemo } from "react";
import {
  IChargerDropdown,
  IChargerWithConnectors,
} from "../../../interfaces/ICharger";
import {
  ChargerStore,
  GetDetailedChargerLog,
  handleGetChargers,
  handleGetConnectorsForCharger,
} from "../../../stores/chargerStore";
import {
  closeConnection,
  startConnection,
} from "../../../helpers/signalRHelper";
import { useStore } from "react-stores";
import "./OperationsRemote.css";
import Table from "../../../shared/table/Table";
import Popup from "../../../shared/popup/Popup";
import Paging from "../../../shared/paging/Paging";
import AvailabilityUnlockModal from "./AvailabilityUnlockModal/AvailabilityUnlockModal";
import {
  ILocationDropdown,
  IMarketDropdown,
} from "../../../interfaces/IMarket";
import InfoPopup from "./info-popup/InfoPopup";
import { IConnectorInCharger } from "../../../interfaces/IConnector";
import Spinner from "../../../shared/spinner/Spinner";
import AdvancedFilter from "../../../shared/advancedFilter/AdvancedFilter";
import LoadingPopup, { Step, StepState } from "../../../shared/loadingPopup/LoadingPopup";
import { closeNewConnection, handleAvailabilityChange, handleChargerReset, handleUnlock } from "../../../helpers/loadingPopupLogicHelper";

interface Paging {
  page: number;
  perPage: number;
}

const OperationsRemote: React.FC = () => {
  const PUBLIC_BASE_URL = process.env.REACT_APP_BASE_URL;
  const [paging, setPaging] = useState<Paging>({ page: 1, perPage: 10 });
  const [sort, setSort] = useState({ field: "", descending: true });
  const [selected, setSelected] = useState<number>(0);
  const [expanded, setExpanded] = useState<number>(0);
  const [isHovered, setIsHovered] = useState(false);

  const [resetChargerPopup, setResetChargerPopup] = useState<boolean>(false);
  const [isHardReset, setIsHardReset] = useState<boolean>(false);
  const [modalTable, setModalTable] = useState(false);
  const [modalTableType, setModalTableType] = useState<"unlock" | "changeAvailability" | "">("");

  const [selectedFilters, setSelectedFilters] = useState({
    markets: [] as IMarketDropdown[],
    locations: [] as ILocationDropdown[],
    chargers: [] as IChargerDropdown[],
  });

  const [connection, setConnection] = useState<signalR.HubConnection | null>(
    null
  );
  const [connectorsOfCharger, setConnectorsOfCharger] =
    useState<IChargerWithConnectors | null>(null);
  const [connectorsForModal, setConnectorsForModal] = useState<IConnectorInCharger[]>([]);

  let connectorsOfChargerTemp: IChargerWithConnectors | null = null;

  const [loading, setLoading] = useState<boolean>(false);
  const [loadingPopupReset, setLoadingPopupReset] = useState<boolean>(false);
  const [loadingPopupAvailability, setLoadingPopupAvailability] = useState<boolean>(false);
  const [loadingPopupUnlock, setLoadingPopupUnlock] = useState<boolean>(false);

  const [connectorId, setConnectorId] = useState<number>(0);
  const [availability, setAvailability] = useState<0 | 1 | undefined>(undefined);


  const { chargers, chargersSize } = useStore(ChargerStore);

  const initialLoadingResetSteps = () => {
    return [
      {
        text: "Sending control command to charger",
        status: StepState.Pending,
        progressReady: false,
      },
      {
        text: `Charger accepts ${isHardReset ? 'emergency ' : ''}reset`,
        status: StepState.Pending,
        progressReady: false,
      }
    ];
  };

  const [loadingResetSteps, setLoadingResetSteps] = useState<Step[]>(initialLoadingResetSteps());
  const [loadingAvailabilitySteps, setLoadingAvailabilitySteps] = useState<Step[]>(initialAvailabilityChangedSteps());
  const [loadingUnlockSteps, setLoadingUnlockSteps] = useState<Step[]>(initialUnlockSteps());

  const rowsStyling = useMemo(() =>
    chargers.map(el =>
      el.numberOfUnavailableFaultedConnectors > 0
        ? { numberOfUnavailableFaultedConnectors: 'faulted-connectors-col' }
        : { numberOfUnavailableFaultedConnectors: '' })
    , [chargers])

  useEffect(() => {
    if (selectedFilters.markets.length > 0) {
      handleGetChargers(
        selectedFilters.markets.map((market) => market.id),
        selectedFilters.locations.map((location) => location.id),
        selectedFilters.chargers.map((charger) => charger.id),
        paging.page,
        paging.perPage,
        sort.field,
        sort.descending
      );
    }
  }, [paging, selectedFilters, sort]);

  useEffect(() => {
    const getConnectors = async () => {
      const data = await handleGetConnectorsForCharger(expanded)
        .then((res) => (res ? res : null))
        .then((res) => {
          setConnectorsOfCharger(res);
          // eslint-disable-next-line react-hooks/exhaustive-deps
          connectorsOfChargerTemp = res;
          return res;
        });

      if (data && data.connectors.length > 0)
        handleSignalRConnection(data.connectors);
    };
    expanded ? getConnectors() : setConnectorsOfCharger(null);
    return () => {
      connection && closeConnection(connection);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [expanded]);

  const handleSort = async (sortingField, sortingMethod) => {
    if (sortingMethod === "default") {
      setSort({ field: "", descending: true });
    } else {
      setSort({
        field: sortingField,
        descending: sortingMethod === "descending" ? true : false,
      });
    }
  };

  const popupMainText = () => {
    return (
      <div className="flex flex-col items-start gap-3">
        <div className="remote-popup-main-text">
          Please confirm {isHardReset ? "emergency" : ""} reset of the charger
        </div>
        <div className="flex flex-row justify-start items-center gap-3">
          <img src="/icons/nav/activeDot.svg" alt="" />
          <div className="popout-other-text">
            Charger ID:{" "}
            {chargers.find((charger) => charger.id === selected)?.ocppChargerId}
          </div>
        </div>
        <div className="h-[2.5px] bg-[#E7E9ED] w-[100%] my-2"></div>
      </div>
    );
  };

  const handleDetailedChargerLog = async () => {
    const selectedCharger = chargers.find(ch => ch.id === selected);
    if (selectedCharger) {
      setLoading(true);
      await GetDetailedChargerLog(selectedCharger.ocppChargerId);
      setLoading(false);
    }
  };

  const handleSignalRConnection = async (connectors) => {
    const newConnection = await startConnection(
      `${PUBLIC_BASE_URL}hubs/connectorStatus`
    );
    setConnection(newConnection);

    const connectorIds = connectors.map((connector) => connector.id);
    await newConnection
      .invoke("Subscribe", connectorIds)
      .then(() => console.log("Subscribed Id's: " + connectorIds))
      .catch((err) => console.error("Error while subscribing", err));

    newConnection.on(
      "updateConnectorStatus",
      (updateConnectorStatusSignalRDto) => {
        console.log(
          `Status for connector ${updateConnectorStatusSignalRDto.connectorId}: ${updateConnectorStatusSignalRDto.status}`
        );
        if (
          connectorsOfChargerTemp &&
          connectorsOfChargerTemp.connectors.length > 0
        ) {
          let updatedConnectors = connectorsOfChargerTemp.connectors.map(
            (con) => {
              if (
                Number(con.id) ===
                Number(updateConnectorStatusSignalRDto.connectorId)
              ) {
                return {
                  ...con,
                  connectorStatus: updateConnectorStatusSignalRDto.status,
                };
              } else return con;
            }
          );
          connectorsOfChargerTemp = {
            chargerId: connectorsOfChargerTemp.chargerId,
            connectors: updatedConnectors,
          };
          setConnectorsOfCharger((prev) => {
            return {
              chargerId: prev?.chargerId || 0,
              connectors: updatedConnectors,
            };
          });
        }
      }
    );
  };

  const handleSelection = (id: number) => {
    setSelected(selected === id ? 0 : id);
  };

  const handleExpanded = (id: number) => {
    if (expanded === id) {
      setExpanded(0);
    } else {
      setExpanded(id);
    }
  };

  const handleCheckboxClick = (chargerId: number) => {
    handleExpanded(chargerId);
    handleSelection(chargerId);
  };

  const getConnectorsForModal = async () => {
    const data = await handleGetConnectorsForCharger(selected);
    const returnArr = data && data.connectors ? data.connectors : [];
    setConnectorsForModal(returnArr);
  };

  return (
    <div className="flex-1 p-3.5 flex flex-col gap-3.5 items-stretch main-wrapper">
      <div className="bg-white card p-5">
        <div className="flex flex-row justify-between items-center flex-wrap">
          <div className="operations-remote-management">
            <span>
              <span className="operations-remote-management-span">
                Operations/
              </span>
              <span className="operations-remote-management-span2">
                Remote Management
              </span>
            </span>
          </div>
          <div className="flex flex-row gap-5 flex-wrap items-center">
            <AdvancedFilter
              handleSelection={(newMarkets) => {
                setSelectedFilters(newMarkets);
                setPaging((prev) => ({ page: 1, perPage: prev.perPage }));
              }}
            />
            <button
              className={`${selected ? "chargerLogButtonActive" : ""
                } chargerLogButton`}
              onClick={handleDetailedChargerLog}
            >
              <img
                src={
                  selected
                    ? "/icons/download/download-gray.svg"
                    : "/icons/download/download-darkblue.svg"
                }
                alt=""
              />
              Detailed Charger Log
            </button>
          </div>
        </div>
      </div>
      <div className="bg-white card p-0">
        <div className="operations-remote-btns">
          <div className="flex gap-3">
            <button
              disabled={!selected}
              className={`${selected ? "resetButtonsActive" : ""
                } resetButtons flex flex-row gap-3 items-center justify-center`}
              onClick={() => {
                setIsHardReset(false);
                setResetChargerPopup(true);
              }}
            >
              Reset
            </button>
            <button
              disabled={!selected}
              className={`${selected ? "resetButtonsActive" : ""
                } resetButtons flex flex-row gap-3 items-center justify-center`}
              onClick={() => {
                setIsHardReset(true);
                setResetChargerPopup(true);
              }}
            >
              Emergency Reset
            </button>
            <button
              disabled={!selected}
              className={`${selected ? "resetButtonsActive" : ""
                } resetButtons flex flex-row gap-3 items-center justify-center`}
              onClick={async () => {
                await getConnectorsForModal();
                setModalTableType("changeAvailability");
                setModalTable(true);
              }}
            >
              Change Availability
            </button>
            <button
              disabled={!selected}
              className={`${selected ? "resetButtonsActive" : ""
                } resetButtons flex flex-row gap-3 items-center justify-center`}
              onClick={async () => {
                await getConnectorsForModal();
                setModalTableType("unlock");
                setModalTable(true);
              }}
            >
              Unlock
            </button>
          </div>
          <div>
            <div
              className="info-button relative"
              onMouseEnter={() => setIsHovered(true)}
              onMouseLeave={() => setIsHovered(false)}
            >
              <p className="i">i</p>
            </div>
            {isHovered && <InfoPopup />}
          </div>
        </div>
        <div className="horizontal-gray-line mt-6 mb-3 mx-5"></div>

        <div className="table-container p-5">
          <Table
            tableHeaders={tableHeaders}
            tableRowOrder={tableRowOrder}
            handleSelection={(id) => handleSelection(id)}
            selected={selected}
            tableRows={chargers}
            expanded={expanded}
            handleExpanded={(id) => handleCheckboxClick(id)}
            sortColumns={tableHeaders}
            handleSort={(sortingField, sortingMethod) =>
              handleSort(sortingField, sortingMethod)
            }
            rowsStyling={rowsStyling}
            preloadRowNumber={8}
          >
            <tr className="subtable-row">
              <td id="initial-td" colSpan={5}>
                <div>
                  <table className="w-[100%]">
                    <thead>
                      <tr>
                        <th className="subtable-header"></th>
                        <th className="subtable-header"></th>
                        <th className="subtable-header">Connector ID</th>
                        <th className="subtable-header">Connector Status</th>
                        <th className="subtable-header">Connector Type</th>
                        <th className="subtable-header">Electric Current</th>
                      </tr>
                    </thead>
                    <tbody>
                      {connectorsOfCharger &&
                        connectorsOfCharger.chargerId === expanded &&
                        connectorsOfCharger.connectors.map((connector) => (
                          <tr className="subtable-tr" key={connector.id}>
                            <td className="subtable-td" colSpan={2}>
                              {" "}
                            </td>
                            <td className="subtable-td">
                              {connector.connectorName}
                            </td>
                            <td className="subtable-td">
                              <div
                                className={`${connector.connectorStatus === "Available"
                                  ? "available"
                                  : connector.connectorStatus === "Faulted"
                                    ? "offline"
                                    : "in-use"
                                  } connector-status-container`}
                              >
                                {connector.connectorStatus}
                              </div>
                            </td>
                            <td className="subtable-td">
                              {connector.connectorType}
                            </td>
                            <td className="subtable-td">
                              {connector.electricCurrent}
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </td>
            </tr>
          </Table>
          <Paging
            tableSize={chargersSize}
            pageChangeFunc={(page, perPage) =>
              setPaging({ page: page, perPage: perPage })
            }
          />
        </div>
      </div>

      {resetChargerPopup && (
        <Popup
          mainText={popupMainText()}
          confirmText="Confirm"
          closeText="Close"
          // confirmFunction={() => handleChargerReset()}
          confirmFunction={() => {
            setResetChargerPopup(false);
            setLoadingPopupReset(true);
            handleChargerReset(selected, isHardReset, setLoadingResetSteps);
          }}
          closeFunction={() => setResetChargerPopup(false)}
        />
      )}
      {modalTable && (
        <AvailabilityUnlockModal
          type={modalTableType}
          showModal={setModalTable}
          connectors={connectorsForModal}
          chargerId={selected}
          handleConfirm={(connectorId, availability) => {
            setModalTable(false);
            if (modalTableType === 'changeAvailability') {
              setLoadingPopupAvailability(true);
              setConnectorId(connectorId);
              setAvailability(availability);
              handleAvailabilityChange(selected, connectorId, availability!, setLoadingAvailabilitySteps)
            } else {
              setLoadingPopupUnlock(true);
              setConnectorId(connectorId);
              handleUnlock(connectorId, setLoadingUnlockSteps);
            }
          }}
        />
      )}
      {loading && <Spinner />}
      {loadingPopupReset && (
        <LoadingPopup
          closeFunction={() => {
            setLoadingPopupReset(false);
            closeNewConnection();
            setLoadingResetSteps(initialLoadingResetSteps());
          }}
          steps={loadingResetSteps}
          handleReset={() => {
            setLoadingResetSteps(initialLoadingResetSteps());
            handleChargerReset(selected, isHardReset, setLoadingResetSteps);
          }}
        />
      )}
      {loadingPopupAvailability && (
        <LoadingPopup
          closeFunction={() => {
            setLoadingPopupAvailability(false);
            closeNewConnection();
            setLoadingAvailabilitySteps(initialAvailabilityChangedSteps());
          }}
          steps={loadingAvailabilitySteps}
          handleReset={() => {
            setLoadingAvailabilitySteps(initialAvailabilityChangedSteps());
            handleAvailabilityChange(selected, connectorId, availability!, setLoadingAvailabilitySteps);
          }}
        />
      )}
      {loadingPopupUnlock && (
        <LoadingPopup
          closeFunction={() => {
            setLoadingPopupUnlock(false);
            closeNewConnection();
            setLoadingUnlockSteps(initialUnlockSteps());
          }}
          steps={loadingUnlockSteps}
          handleReset={() => {
            setLoadingUnlockSteps(initialUnlockSteps());
            handleUnlock(connectorId, setLoadingUnlockSteps);
          }}
        />
      )}
    </div>
  );
};

export default OperationsRemote;

const initialAvailabilityChangedSteps = () => {
  return [
    {
      text: "Sending status change command to the charger",
      status: StepState.Pending,
      progressReady: false,
    },
    {
      text: 'Charger accepts to change status',
      status: StepState.Pending,
      progressReady: false,
    },
    {
      text: 'Charger changes the connector status',
      status: StepState.Pending,
      progressReady: false,
    }
  ];
};

const initialUnlockSteps = () => {
  return [
    {
      text: "Sending control command to charger",
      status: StepState.Pending,
      progressReady: false,
    },
    {
      text: 'Charger sends feedback about a successful unlocking',
      status: StepState.Pending,
      progressReady: false,
    }
  ];
};

const tableHeaders: string[] = [
  "Charger ID",
  "Market",
  "Available Connectors",
  "Unavailable/faulted Connectors",
  "Location Name",
  "Address",
  "Charger Information",
];
const tableRowOrder: string[] = [
  "ocppChargerId",
  "companyMarketName",
  "numberOfAvailableConnectors",
  "numberOfUnavailableFaultedConnectors",
  "locationName",
  "street",
  "chargerInformation",
];
