import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import { handleGetMarketsForDropdown } from "../stores/marketStore";
import { IMarketDropdown } from "../interfaces/IMarket";

const COMPANY_MARKETS_CACHE_KEY = "companyMarketsDropdown";

async function fetchCompanyMarketsDropdown(): Promise<IMarketDropdown[]> {
  const data = await handleGetMarketsForDropdown();
  return data;
}

const defaultCompanyMarketsQueryOptions: UseQueryOptions<IMarketDropdown[]> = {
  queryKey: [COMPANY_MARKETS_CACHE_KEY],
  queryFn: fetchCompanyMarketsDropdown,
  staleTime: 10 * 60 * 1000,
  retry: 1,
  refetchOnWindowFocus: false,
};

type CustomCompanyMarketsQueryOptions = Omit<
  UseQueryOptions<IMarketDropdown[]>,
  "queryKey" | "queryFn"
>;

export function useCompanyMarketsDropdownQuery(
  options?: CustomCompanyMarketsQueryOptions
) {
  return useQuery({
    ...defaultCompanyMarketsQueryOptions,
    ...options,
  });
}
