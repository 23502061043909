import React, { useRef, useState } from "react";
import './NeedHelp.css';
import { useOutsideAlerter } from "../../../helpers/componentsHelper";


const NeedHelp: React.FC = () => {

    const [expanded, setExpanded] = useState(false);

    const wrapperRef = useRef(null);
    useOutsideAlerter(wrapperRef, () => setExpanded(false));


    return (
        <div className="flex flex-col justify-start items-end gap-3 relative" ref={wrapperRef}>
            <div className="flex justify-center items-center header-icons-container" onClick={() => setExpanded(prev => !prev)}>
                <img src="/icons/nav/help-circle.svg" alt="" />
            </div>
            {expanded && (
                <div className="need-help-container">
                    <div className="need-help">
                        Need help? Contact us!
                    </div>
                    <a
                        className="support-mail"
                        href="https://mail.google.com/mail/?view=cm&fs=1&to=contact@power-up.green&su=Operator%20portal%20question&body=Dear%20PowerUp%20support,%0A%0A"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        contact@power-up.green
                    </a>
                </div>
            )}
        </div>

    )
}

export default NeedHelp;