import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import {
  GetCompanyMarketsDropdownRoaming,
  RoamingStore,
} from "../../stores/roamingStore";
import { ICompanyMarketRoaming } from "../../interfaces/IRoaming";

const COMPANY_MARKETS_CACHE_KEY = "companyMarketsDropdownRoaming";

async function fetchCompanyMarketsDropdown(
  registered: boolean
): Promise<ICompanyMarketRoaming[]> {
  try {
    await GetCompanyMarketsDropdownRoaming(registered);
    return RoamingStore.state.companyMarketsDropdown || [];
  } catch (error) {
    console.error("Error fetching company markets dropdown:", error);
    return [];
  }
}

const defaultCompanyMarketsQueryOptions: UseQueryOptions<
  ICompanyMarketRoaming[]
> = {
  queryKey: [COMPANY_MARKETS_CACHE_KEY],
  staleTime: 10 * 60 * 1000,
  retry: 1,
  refetchOnWindowFocus: false,
};

type CustomCompanyMarketsQueryOptions = Omit<
  UseQueryOptions<ICompanyMarketRoaming[]>,
  "queryKey" | "queryFn"
>;

export function useCompanyMarketsDropdownQuery(
  registered?: boolean,
  options?: CustomCompanyMarketsQueryOptions
) {
  return useQuery({
    ...defaultCompanyMarketsQueryOptions,
    queryKey: [COMPANY_MARKETS_CACHE_KEY, `registered:${registered}`],
    queryFn: () => fetchCompanyMarketsDropdown(registered!),
    ...options,
  });
}
