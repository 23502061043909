import { useEffect, useState } from "react";
import "./MarkerModal.css";
import Table from "../../../../shared/table/Table";
import { useStore } from "react-stores";
import { PricingStore } from "../../../../stores/pricingStore";
import ToggleButtons from "../../../../shared/toggleBtns/ToggleButtons";
import LocationInformation from "./locationInformation/LocationInformation";
import ChargerInformation from "./chargerInformation/ChargerInformation";
import {
  GetChargers,
  GetLocationInfo,
  LocationStore,
} from "../../../../stores/locationStore";

interface Props {
  closeModal: Function;
  locationId: number;
  onLocationUpdate: (updatedLocation: any) => void;
  setScreen?: (value: string) => void;
}

const MarkerModal = ({
  closeModal,
  locationId,
  onLocationUpdate,
  setScreen,
}: Props) => {
  const [activeButton, setActiveButton] = useState("location");
  const { locationInfo } = useStore(LocationStore);
  const [isDataFetched, setIsDataFetched] = useState(false);

  const handleButtonClick = (buttonType) => {
    setActiveButton(buttonType);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        await Promise.all([
          GetLocationInfo(locationId),
          GetChargers(locationId),
        ]);
        setIsDataFetched(true);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
      }
    };

    if (locationId) {
      fetchData();
    }
  }, [locationId]);

  if (!isDataFetched) {
    return null;
  }

  return (
    <>
      <div className="modal-background" onClick={() => closeModal(false)} />
      <div
        className={`create-location-container ${
          activeButton === "charger" ? "wide" : "narrow"
        }`}
      >
        <div className="modal-header justify-between">
          <ToggleButtons onClick={handleButtonClick} active={activeButton} />
          <img
            src="/icons/shared/exit-cross.svg"
            alt=""
            onClick={() => closeModal(false)}
            className="cursor-pointer"
          />
        </div>
        <div className="line"></div>
        <div className="content-location-container">
          {activeButton === "location" ? (
            <LocationInformation
              location={locationInfo}
              closeModal={closeModal}
              onLocationUpdate={onLocationUpdate}
              setScreen={setScreen!}
            />
          ) : (
            <ChargerInformation locationId={locationId} />
          )}
        </div>
      </div>
    </>
  );
};

export default MarkerModal;
