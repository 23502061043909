import Chart, { CategoryScale } from "chart.js/auto";
import { Bar } from "react-chartjs-2";
import { useStore } from "react-stores";
import { useState } from "react";
import { callback } from "chart.js/dist/helpers/helpers.core";


interface AlarmsByTypeProps {
    xValues: string[],
    yValues: number[],
}

const AlarmsByType = ({ xValues, yValues }: AlarmsByTypeProps) => {
    Chart.register(CategoryScale);
    const dotsMapping = {
        Warning: 'graph-orange-dot.svg',
        Error: 'graph-red-dot.svg',
        Notification: 'graph-red-dot.svg',
    }

    const data = {
        labels: xValues,
        datasets: [
            {
                data: yValues,
                backgroundColor: ['#FF8800', '#FF3139', '#1E4CDC'],
                hoverBackgroundColor: ['#F08000', '#EA2E35', '#1B42BD'],
                categoryPercentage: 0.5,
                borderRadius: 4,
            },
        ],
    };

    const options = {
        maintainAspectRatio: false,
        responsive: true,
        scales: {
            x: {
                grid: {
                    display: false,
                },
                ticks: {
                    font: {
                        family: 'Inter',
                    },
                    color: "#B5BAC6",
                },
            },
            y: {
                grid: {
                    display: true,
                    color: "#F4F4F4",
                    lineWidth: 1,
                },
                ticks: {
                    font: {
                        family: 'Inter',
                    },
                    color: "#B5BAC6",
                    maxTicksLimit: 6,
                    maxRotation: 0,
                    minRotation: 0
                },
            },
        },
        plugins: {
            legend: {
                display: false,
            },
            tooltip: {
                backgroundColor: "#E9EDFC",
                borderColor: 'white',
                borderWidth: 2,
                titleColor: '#111F47',
                titleAlign: 'center' as const,
                titleFont: {
                    family: 'Inter',
                    size: 11,
                },
                bodyColor: '#111F47',
                bodyFont: {
                    family: 'Inter',
                    size: 11,
                },
                displayColors: false,
                cursor: "pointer",
                padding: {
                    top: 6,
                    bottom: 6,
                    left: 8,
                    right: 8,
                },
                callbacks: {
                    title: function (tooltipItem) {
                        // Remove the title
                        return '';
                    },
                },
            },
        },
        onHover: (event, activeElements) => {
            (event?.native?.target as HTMLElement).style.cursor =
                activeElements?.length > 0 ? "pointer" : "auto";
        },
    };

    return (
        <div className="h-full">
            <Bar data={data} options={options} />
        </div>
    );
};

export default AlarmsByType;
