import React, { useRef, useState } from "react";
import './ProfilePhotoModal.css';
import { useOutsideAlerter } from "../../../helpers/componentsHelper";
import { useNavigate } from "react-router-dom";
import { logout } from "../../../stores/authStore";

type ProfilePhotoModalProps = {
    name?: string;
    surname?: string;
}

const ProfilePhotoModal: React.FC<ProfilePhotoModalProps> = ({ name, surname }) => {
    const [expanded, setExpanded] = useState(false);
    const navigate = useNavigate();

    const wrapperRef = useRef(null);
    useOutsideAlerter(wrapperRef, () => setExpanded(false));

    return (
        <div className="flex flex-col justify-start items-end gap-3 relative" ref={wrapperRef}>
            <div onClick={() => setExpanded(prev => !prev)}
                className="flex flex-row gap-5 items-center hover:cursor-pointer"
            >
                <div className="username">
                    {name} {surname}
                </div>
                <img
                    src="/icons/nav/profileIcon.png"
                    alt=""
                    className="profile-icon"
                />
            </div>
            {expanded && (
                <div className="profile-modal-container">
                    <div className="settings" onClick={() => {
                        navigate("/dashboard/profile");
                        setExpanded(false);
                    }}>
                        <img src="/icons/nav/home.svg" alt="" />
                        <p>Profile settings</p>
                    </div>
                    <div className="settings" onClick={() => {
                        navigate("/dashboard/company");
                        setExpanded(false);
                    }}>
                        <img src="/icons/nav/user.svg" alt="" />
                        <p>Company settings</p>
                    </div>
                    <div className="settings mt-3 border-t-2" onClick={logout}>
                        <img src="/icons/nav/signOut.svg" alt="" />
                        <p>Sign Out</p>
                    </div>
                </div>
            )}
        </div>

    )
}

export default ProfilePhotoModal;