import { Store } from "react-stores";
import { ISessionStore } from "../interfaces/IStores";
import { AuthStore } from "./authStore";
import { formatDateLocale } from "../helpers/dataHelper";

const PUBLIC_BASE_URL = process.env.REACT_APP_BASE_URL;

export const SessionStore = new Store<ISessionStore>({
  feesInfo: [],
  feesInfoCount: 0,
  feeDetailsMap: new Map(),
});

const formatMarkets = (marketIds: number[]): string => {
  return marketIds
    .map((id) => `CompanyMarketsFilter.CompanyMarketIds=${id}`)
    .join("&");
};

export const GetFeesInfo = async (
  companyMarketIds: number[],
  sortingField: string,
  sortingDescending: boolean,
  pageIndex: number,
  pageSize: number
) => {
  try {
    let jwtString: string | null = AuthStore.state.jwt;
    if (!jwtString) jwtString = sessionStorage.getItem("jwt");

    const myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + jwtString);

    let marketsString = formatMarkets(companyMarketIds);
    const url = new URL(
      `${PUBLIC_BASE_URL}api/v1.1/B2B/Sessions/GetFeesInfoPaginated?${marketsString}`
    );
    url.searchParams.append("SortingCriteria.Field", sortingField);
    url.searchParams.append(
      "SortingCriteria.Descending",
      sortingDescending.toString()
    );
    url.searchParams.append("PageIndex", pageIndex.toString());
    url.searchParams.append("PageSize", pageSize.toString());

    const response = await fetch(url.toString(), {
      method: "GET",
      headers: myHeaders,
    });

    const data = await response.json();

    const feeDetailsMap = new Map();

    const modifiedData = data.data.map((item: any, index: number) => {
      const id = index + 1;
      const dateOfIssue = formatDateLocale(item.dateOfIssue);
      const dueDate = formatDateLocale(item.dueDate);
      const totalBill = item.totalBill.toFixed(2) + ' ' + item.currencyIso;

      feeDetailsMap.set(id, {
        transactionFee: item.transactionFee ? { fee: item.transactionFee.fee.toFixed(1) + '%', amount: item.transactionFee.amount.toFixed(2) + ' ' + item.currencyIso, total: item.transactionFee.total.toFixed(2) + ' ' + item.currencyIso } : undefined,
        chargerMontlyFee: item.chargerMontlyFee ? { fee: item.chargerMontlyFee.fee, amount: item.chargerMontlyFee.amount.toFixed(2) + ' ' + item.currencyIso, total: item.chargerMontlyFee.total.toFixed(2) + ' ' + item.currencyIso } : undefined,
        annualRoamingFee: item.annualRoamingFee ? { fee: item.annualRoamingFee.fee, amount: item.annualRoamingFee.amount.toFixed(2) + ' ' + item.currencyIso, total: item.annualRoamingFee.total.toFixed(2) + ' ' + item.currencyIso } : undefined,
      });

      return {
        ...item,
        id,
        dateOfIssue,
        dueDate,
        totalBill
      };
    });
    SessionStore.setState({
      feesInfo: modifiedData,
      feesInfoCount: data.totalCount,
      feeDetailsMap,
    });
  } catch (e) {
    console.log("Error: ", e);
  }
};

export const StopSessionById = async (sessionId: number) => {
  try {
    let jwtString: string | null = AuthStore.state.jwt;
    if (!jwtString) jwtString = sessionStorage.getItem("jwt");

    const response = await fetch(
      `${PUBLIC_BASE_URL}api/v1/B2B/Sessions/StopSessionById/${sessionId}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "text/plain",
          Authorization: `Bearer ${jwtString}`,
        },
      }
    );

    return response.ok;
  } catch (e) {
    return false;
  }
};
