import { Store } from "react-stores";
import { AuthStore } from "./authStore";
import { IRoamingStore } from "../interfaces/IStores";
import { IChargerRoaming } from "../interfaces/IRoaming";

const PUBLIC_BASE_URL = process.env.REACT_APP_BASE_URL;

export const RoamingStore = new Store<IRoamingStore>({
  publishedChargers: [],
  unpublishedChargers: [],
  accessibilityTypes: [],
  accessibilityLocationTypes: [],
  companyMarketsPaginated: [],
  paginatedCount: 0,
  companyMarketsDropdown: [],
});

export const GetPublishedChargers = async (
  companyMarketId: number,
  sortingField: string,
  sortingDescending: boolean,
  pageIndex: number,
  pageSize: number
) => {
  try {
    let jwtString = AuthStore.state.jwt || sessionStorage.getItem("jwt");
    if (!jwtString) {
      console.log("No JWT token found. User might not be authenticated.");
      return;
    }

    const params = new URLSearchParams();
    if (companyMarketId !== undefined)
      params.append("CompanyMarketId", String(companyMarketId));
    if (sortingField !== "")
      params.append("SortingCriteria.Field", String(sortingField));
    if (sortingDescending !== undefined)
      params.append("SortingCriteria.Descending", String(sortingDescending));
    if (pageIndex !== undefined) params.append("PageIndex", String(pageIndex));
    if (pageSize !== undefined) params.append("PageSize", String(pageSize));

    const url = `${PUBLIC_BASE_URL}api/Roaming/Sessions/GetPublishedChargers?${params.toString()}`;

    const response = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${jwtString}`,
      },
    });

    if (!response.ok) {
      console.error(`Error: ${response.status} - ${response.statusText}`);
      return;
    }

    const data = await response.json();

    RoamingStore.setState({
      publishedChargers: data.data,
    });
  } catch (e) {
    console.error("Error fetching chargers:", e);
  }
};

export const GetUnPublishedChargers = async (
  companyMarketId: number,
  sortingField: string,
  sortingDescending: boolean,
  pageIndex: number,
  pageSize: number
) => {
  try {
    let jwtString = AuthStore.state.jwt || sessionStorage.getItem("jwt");
    if (!jwtString) {
      console.log("No JWT token found. User might not be authenticated.");
      return;
    }
    const params = new URLSearchParams();
    if (companyMarketId !== undefined)
      params.append("CompanyMarketId", String(companyMarketId));
    if (sortingField !== "")
      params.append("SortingCriteria.Field", String(sortingField));
    if (sortingDescending !== undefined)
      params.append("SortingCriteria.Descending", String(sortingDescending));
    if (pageIndex !== undefined) params.append("PageIndex", String(pageIndex));
    if (pageSize !== undefined) params.append("PageSize", String(pageSize));

    const url = `${PUBLIC_BASE_URL}api/Roaming/Sessions/GetUnPublishedChargers?${params.toString()}`;

    const response = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${jwtString}`,
      },
    });

    if (!response.ok) {
      console.error(`Error: ${response.status} - ${response.statusText}`);
      return;
    }

    const data = await response.json();

    RoamingStore.setState({
      unpublishedChargers: data.data,
    });
  } catch (e) {
    console.error("Error fetching chargers:", e);
  }
};

export const GetCompanyMarketsDropdownRoaming = async (
  registered?: boolean
) => {
  try {
    const jwtString = AuthStore.state.jwt || sessionStorage.getItem("jwt");
    if (!jwtString) {
      return;
    }

    const params = new URLSearchParams();
    if (registered !== undefined)
      params.append("Registered", String(registered));
    const url = `${PUBLIC_BASE_URL}api/Roaming/Sessions/GetRoamingCompanyMarketsDropdown?${params.toString()}`;

    const response = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${jwtString}`,
        Accept: "application/json",
      },
    });

    if (!response.ok) {
      console.error(`Error: ${response.status} - ${response.statusText}`);
      return;
    }

    const data = await response.json();
    RoamingStore.setState({ companyMarketsDropdown: data });
  } catch (e) {
    console.error("Error fetching company markets:", e);
  }
};

export const GetRoamingCompanyMarketsPaginated = async (
  sortingField: string = "",
  sortingDescending: boolean,
  pageIndex: number,
  pageSize: number,
  registered?: boolean
) => {
  try {
    const jwtString = AuthStore.state.jwt || sessionStorage.getItem("jwt");
    if (!jwtString) {
      return;
    }

    const params = new URLSearchParams();
    if (registered !== undefined)
      params.append("Registered", String(registered));
    if (sortingField !== "")
      params.append("SortingCriteria.Field", String(sortingField));
    if (sortingDescending !== undefined)
      params.append("SortingCriteria.Descending", String(sortingDescending));
    if (pageIndex !== undefined) params.append("PageIndex", String(pageIndex));
    if (pageSize !== undefined) params.append("PageSize", String(pageSize));

    const url = `${PUBLIC_BASE_URL}api/Roaming/Sessions/GetRoamingCompanyMarketsPaginated?${params.toString()}`;

    const response = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${jwtString}`,
        Accept: "application/json",
      },
    });

    if (!response.ok) {
      console.error(`Error: ${response.status} - ${response.statusText}`);
      return;
    }

    const data = await response.json();
    RoamingStore.setState({
      companyMarketsPaginated: data.data,
      paginatedCount: data.totalCount,
    });
  } catch (e) {
    console.error("Error fetching company markets:", e);
  }
};

export const GetAccessibilityTypes = async () => {
  try {
    const jwtString = AuthStore.state.jwt || sessionStorage.getItem("jwt");
    if (!jwtString) {
      return;
    }

    const url = `${PUBLIC_BASE_URL}api/Roaming/Sessions/GetAccessibilityTypes`;
    const response = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${jwtString}`,
        Accept: "application/json",
      },
    });

    if (!response.ok) {
      console.error(`Error: ${response.status} - ${response.statusText}`);
      return;
    }

    const data: string[] = await response.json();

    RoamingStore.setState({ accessibilityTypes: data });
  } catch (e) {
    console.error("Error fetching accessibility types:", e);
  }
};

export const GetAccessibilityLocationTypes = async () => {
  try {
    const jwtString = AuthStore.state.jwt || sessionStorage.getItem("jwt");
    if (!jwtString) {
      return;
    }

    const url = `${PUBLIC_BASE_URL}api/Roaming/Sessions/GetAccessibilityLocationTypes`;
    const response = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${jwtString}`,
        Accept: "application/json",
      },
    });

    if (!response.ok) {
      console.error(`Error: ${response.status} - ${response.statusText}`);
      return;
    }

    const data: string[] = await response.json();

    RoamingStore.setState({ accessibilityLocationTypes: data });
  } catch (e) {
    console.error("Error fetching accessibility location types:", e);
  }
};

export const PublishChargerOnRoaming = async (
  chargerId: number,
  hotlinePhoneNumber: string,
  chargingStationName: string,
  accessibility: string,
  accessibilityLocation: string
) => {
  try {
    let jwtString = AuthStore.state.jwt || sessionStorage.getItem("jwt");
    if (!jwtString) {
      console.log("No JWT token found. User might not be authenticated.");
      return;
    }

    const url = `${PUBLIC_BASE_URL}api/v1.1/B2B/Chargers/PublishChargerOnRoaming`;

    const body = {
      chargerId,
      hotlinePhoneNumber,
      chargingStationName,
      accessibility,
      accessibilityLocation,
    };

    const response = await fetch(url, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${jwtString}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    });

    if (response.ok) {
      return true;
    } else {
      console.error("Error:", response.statusText);
      return false;
    }
  } catch (e) {
    console.error("Error publishing charger:", e);
  }
};

export const WithdrawChargerFromRoaming = async (chargerId: number) => {
  try {
    let jwtString = AuthStore.state.jwt || sessionStorage.getItem("jwt");
    if (!jwtString) {
      console.log("No JWT token found. User might not be authenticated.");
      return;
    }

    const url = `${PUBLIC_BASE_URL}api/v1.1/B2B/Chargers/WithdrawChargerFromRoaming`;

    const body = {
      chargerId,
    };

    const response = await fetch(url, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${jwtString}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    });

    if (response.ok) {
      return true;
    } else {
      console.error("Error:", response.statusText);
      return false;
    }
  } catch (e) {
    console.error("Error publishing charger:", e);
  }
};

export const RegisterCompanyMarket = async (
  companyMarketId: number,
  threeLetterAcronym: string,
  hotlinePhoneNumber: string
) => {
  try {
    let jwtString = AuthStore.state.jwt || sessionStorage.getItem("jwt");
    if (!jwtString) {
      console.log("No JWT token found. User might not be authenticated.");
      return;
    }

    const url = `${PUBLIC_BASE_URL}api/Roaming/Sessions/RegisterCompanyMarket`;

    const body = {
      companyMarketId,
      threeLetterAcronym,
      hotlinePhoneNumber,
    };

    const response = await fetch(url, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${jwtString}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    });

    if (response.ok) {
      return true;
    } else {
      console.error("Error:", response.statusText);
      return false;
    }
  } catch (e) {
    console.error("Error publishing charger:", e);
  }
};
