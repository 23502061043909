import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import { AuthStore } from "../stores/authStore";

const COMPANY_INFO_CACHE_KEY = "companyInfo";

export interface ICompanyInfo {
  id: number;
  companyName: string;
  contactEmail: string;
  address: string;
}

async function fetchCompanyInfo(): Promise<ICompanyInfo> {
  let jwtString: string | null = AuthStore.state.jwt;
  if (!jwtString) {
    jwtString = sessionStorage.getItem("jwt");
  }

  if (!jwtString) {
    throw new Error("JWT token not found");
  }

  const myHeaders = new Headers();
  myHeaders.append("Authorization", "Bearer " + jwtString);
  myHeaders.append("Content-Type", "application/json");

  const response = await fetch(
    `${process.env.REACT_APP_BASE_URL}api/v1.1/B2B/Companies/GetCompanyInfo`,
    {
      method: "GET",
      headers: myHeaders,
    }
  );

  if (!response.ok) {
    throw new Error("Failed to fetch company info");
  }

  return response.json();
}

const defaultCompanyInfoQueryOptions: UseQueryOptions<ICompanyInfo> = {
  queryKey: [COMPANY_INFO_CACHE_KEY],
  queryFn: fetchCompanyInfo,
  staleTime: 5 * 60 * 1000,
  retry: 1,
  refetchOnWindowFocus: false,
};

type CustomCompanyInfoQueryOptions = Omit<
  UseQueryOptions<ICompanyInfo>,
  "queryKey" | "queryFn"
>;

export function useCompanyInfoQuery(options?: CustomCompanyInfoQueryOptions) {
  return useQuery({
    ...defaultCompanyInfoQueryOptions,
    ...options,
  });
}
