import { Doughnut } from "react-chartjs-2";
import "../DashboardOverview.css";
import { ThreeDots } from "../../../shared/threeDots/ThreeDots";
import { DashboardStore } from "../../../stores/dashboardStore";
import { useStore } from "react-stores";
import { downloadCSV } from "../../../helpers/csvHelper";
import { spacing } from "react-select/dist/declarations/src/theme";
import { EVSEStatus } from "../DashboardOverview";

interface ChargerStatusChartProps {
  values: number[];
  openModal: (status: EVSEStatus) => void;
}

const ChargerStatus = ({ values, openModal }: ChargerStatusChartProps) => {
  const { chargersStatus } = useStore(DashboardStore);

  const available = chargersStatus?.available ?? 0;
  const unavailable = chargersStatus?.unavailable ?? 0;
  const outOfService = chargersStatus?.outOfService ?? 0;

  const totalSum = available + unavailable + outOfService;

  const labels = [
    "Occupied - charging",
    "Available - not charging",
    "Out of order - offline",
  ];

  const threeDotstext = () => (
    <div className="flex flex-row justify-between items-center gap-4">
      <p className="threedots-text">Download data in .csv</p>
      <img src="/icons/download/download-blue.svg" alt="" />
    </div>
  );

  const data = {
    labels: labels,
    datasets: [
      {
        data: values,
        backgroundColor: ["#FCDC4D", "#03CEA4", "#DC1E35"],
        border: 0,
        borderRadius: 10,
      },
    ],
    hoverOffset: 4,
  };

  const options: any = {
    maintainAspectRatio: false,
    responsive: true,
    cutout: "65%",
    plugins: {
      legend: {
        position: "right",
        labels: {
          usePointStyle: true,
          font: {
            family: "Inter",
            size: 11,
          },
          color: "black",
        },
      },
      tooltip: {
        backgroundColor: "#E9EDFC",
        borderColor: "white",
        borderWidth: 2,
        bodyColor: "#111F47",
        bodyAlign: "center" as const,
        bodyFont: {
          family: "Inter",
          size: 11,
          weight: 600,
        },
        displayColors: false,
        cursor: "pointer",
        padding: {
          top: 6,
          bottom: 6,
          left: 8,
          right: 8,
        },
        callbacks: {
          title: function () {
            return "";
          },
          label: function (tooltipItem) {
            const percentage =
              totalSum > 0 ? (tooltipItem.formattedValue / totalSum) * 100 : 0;
            if (Number.isInteger(percentage)) {
              return `${tooltipItem.formattedValue} (${percentage}%)`;
            } else {
              return `${tooltipItem.formattedValue} (${percentage.toFixed(
                1
              )}%)`;
            }
          },
        },
      },
    },
    onHover: (event, activeElements) => {
      (event?.native?.target as HTMLElement).style.cursor =
        activeElements?.length > 0 ? "pointer" : "auto";
    },
    onClick: (event: any) => {
      const label = event.chart.tooltip._tooltipItems[0]?.label;
      if (label) {
        const labelToStatusMap: Record<string, EVSEStatus> = {
          "Occupied - charging": "Unavailable",
          "Available - not charging": "Available",
          "Out of order - offline": "OutOfService",
        };

        const status = labelToStatusMap[label];
        if (status) {
          openModal(status);
        }
      }
    },
  };

  const handleGetEVSEStatusCSV = () => {
    const formattedStatuses = [
      {
        Occupied: unavailable,
        Available: available,
        OutOfOrder: outOfService,
      },
    ];
    downloadCSV(formattedStatuses, "EVSE");
  };

  return (
    <>
      <div className="w-[100%] relative">
        <div className="chart-title">EVSE status overview</div>
        <div className="absolute top-0 right-0">
          <ThreeDots
            actions={[
              {
                text: threeDotstext(),
                handleClick: () => handleGetEVSEStatusCSV(),
              },
            ]}
          />
        </div>
      </div>
      <Doughnut data={data} options={options} className="w-[100%]" />
    </>
  );
};

export default ChargerStatus;
