import { Chart as ChartJS, CategoryScale } from "chart.js";
import { Bar } from "react-chartjs-2";
import { ThreeDots } from "../../../shared/threeDots/ThreeDots";
import { useStore } from "react-stores";
import { DashboardStore } from "../../../stores/dashboardStore";
import { downloadCSV } from "../../../helpers/csvHelper";
import { max } from "date-fns";
import { callback } from "chart.js/dist/helpers/helpers.core";
import { useState } from "react";
import { IPointDto } from "../../../interfaces/IGraph";

interface RevenueProps {
  xValues: string[],
  yValues: IPointDto[],
  currency: string
}

const RevenueChart = ({ xValues, yValues, currency }: RevenueProps) => {
  const { revenue } = useStore(DashboardStore);
  ChartJS.register(CategoryScale);

  const threeDotstext = () => (
    <div className="flex flex-row justify-between items-center gap-4">
      <p className="threedots-text">Download data in .csv</p>
      <img src="/icons/download/download-blue.svg" alt="" />
    </div>
  );

  const data = {
    labels: xValues,
    datasets: [
      {
        data: yValues,
        backgroundColor: "#B9C8F4",
        hoverBackgroundColor: "#1E4CDC",
        categoryPercentage: 0.6,
        borderRadius: 4,
        parsing: {
          yAxisKey: 'value',
          xAxisKey: 'middle',
        },
      },
    ],
  };

  const options = {
    maintainAspectRatio: false,
    responsive: true,
    scales: {
      x: {
        grid: {
          display: false,
        },
        ticks: {
          font: {
            family: 'Inter',
          },
          color: "#B5BAC6",
          maxTicksLimit: 12,
          maxRotation: 0,
          minRotation: 0
        },
      },
      y: {
        grid: {
          display: true,
          color: "#F4F4F4",
          lineWidth: 1,
        },
        ticks: {
          font: {
            family: 'Inter',
          },
          color: "#B5BAC6",
          maxTicksLimit: 6,
          maxRotation: 0,
          minRotation: 0
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        backgroundColor: "#E9EDFC",
        borderColor: 'white',
        borderWidth: 2,
        titleColor: '#111F47',
        titleAlign: 'center' as const,
        titleFont: {
          family: 'Inter',
          size: 11,
        },
        bodyColor: '#111F47',
        bodyFont: {
          family: 'Inter',
          size: 11,
        },
        displayColors: false,
        cursor: "pointer",
        padding: {
          top: 6,
          bottom: 6,
          left: 8,
          right: 8,
        },
        callbacks: {
          title: function (tooltipItem) {
            // Remove the title
            return `${tooltipItem[0].raw.value.toFixed(2)} ${currency}`;
          },
          label: function (tooltipItem) {
            const { dataset, dataIndex } = tooltipItem;
            // Customize the label of the tooltip
            return `${dataset.data[dataIndex].from} - ${dataset.data[dataIndex].to}`;
          },
        },
      },
    },
    onHover: (event, activeElements) => {
      (event?.native?.target as HTMLElement).style.cursor =
        activeElements?.length > 0 ? "pointer" : "auto";
    },
  };

  const handleGetRevenueCSV = () => {
    const formattedRevenue = yValues.map((point) => {
      return {
        revenue: point.value.toFixed(2),
        fromDate: point.from,
        toDate: point.to,
      };
    });
    downloadCSV(formattedRevenue, "revenue");
  };

  return (
    <>
      <div className="flex flex-row justify-start items-center gap-1 w-[100%] relative">
        <div className="chart-title">Revenue,</div>
        <p>{currency}</p>
        <div className="absolute top-0 right-0">
          <ThreeDots
            actions={[
              {
                text: threeDotstext(),
                handleClick: handleGetRevenueCSV,
              },
            ]}
          />
        </div>
      </div>
      <Bar data={data} options={options} className="w-[100%]" />
    </>
  );
};

export default RevenueChart;
