import React, { useState, useEffect, useRef } from "react";
import { IUpdateMarket } from "../../interfaces/IMarket";
import {
  MarketStore,
  handleGetMarkets,
  handleUpdateCompanyMarket,
} from "../../stores/marketStore";
import "./Company.css";
import { useStore } from "react-stores";
import { SingleValue } from "react-select";
import { MAPBOX_ACCESS_TOKEN } from "../../helpers/constants";
import {
  CommonStore,
  GetCountriesForDropdown,
  GetCurrenciesForDropdown,
} from "../../stores/commonStore";
import { useToast } from "../../shared/toast/ToastContent";
import Spinner from "../../shared/spinner/Spinner";

interface Props {
  editMode: boolean;
  setEditMode: Function;
  selected: number;
}
interface Country {
  value: string;
  label: string;
}
interface SelectOption {
  value: string;
  label: string;
}

interface Currency {
  value: number;
  label: string;
}

const Edit: React.FC<Props> = ({ editMode, setEditMode, selected }) => {
  const [edited, setEdited] = useState<IUpdateMarket>({
    id: 0,
    name: "",
    currencyId: 0,
    address: "",
    countryIso: "",
    email: "",
    hotlinePhoneNumber: "",
  });
  const [selectedMarket, setSelectedMarket] = useState<IUpdateMarket>({
    id: 0,
    name: "",
    currencyId: 0,
    address: "",
    countryIso: "",
    email: "",
    hotlinePhoneNumber: "",
  });
  const [addressOptions, setAddressOptions] = useState<SelectOption[]>([]);
  const [previousAddress, setPreviousAddress] = useState("");
  const [isFocused, setIsFocused] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);

  const [canConfirm, setCanConfirm] = useState<boolean>(false);
  const [selectedCountry, setSelectedCountry] = useState<Country | null>(null);
  const [selectedCurrency, setSelectedCurrency] = useState<Currency | null>(
    null
  );
  const { showToast } = useToast();
  const [loading, setLoading] = useState<boolean>(false);
  const [query, setQuery] = useState(edited.address);

  const marketStore = useStore(MarketStore);
  const { markets } = marketStore;
  const { countriesForDropDown, currenciesForDropdown } = useStore(CommonStore);

  const handleSelectAddressChange = (selectedOption: SelectOption | null) => {
    setEdited((prev) => ({ ...prev, address: selectedOption?.label || "" }));
    setQuery(selectedOption?.label || "");
    setAddressOptions([]);
  };

  useEffect(() => {
    setQuery(edited.address);
  }, [edited.address]);

  const [countriesSelection, setCountriesSelection] = useState<Country[]>([]);

  useEffect(() => {
    const checkDifference = () => {
      const editedValues = Object.values(edited);
      const selectedValues = Object.values(selectedMarket);
      const isDifferent =
        JSON.stringify(editedValues) !== JSON.stringify(selectedValues);
      isDifferent && edited.name && edited.address && query
        ? setCanConfirm(true)
        : setCanConfirm(false);
    };

    checkDifference();
  }, [edited, selectedMarket]);

  useEffect(() => {
    const getMarket = () => {
      markets.forEach((market) => {
        if (market.id === selected) {
          // eslint-disable-next-line array-callback-return
          countriesForDropDown.map((country) => {
            if (country.internationalName === market.country) {
              setEdited({
                id: market.id,
                name: market.name,
                currencyId: market.currencyId,
                countryIso: country.iSO,
                address: market.address,
                email: market.email || "",
                hotlinePhoneNumber: market.hotlinePhoneNumber || "",
              });
              setSelectedMarket({
                id: market.id,
                name: market.name,
                currencyId: market.currencyId,
                countryIso: country.iSO,
                address: market.address,
                email: market.email || "",
                hotlinePhoneNumber: market.hotlinePhoneNumber || "",
              });
              setSelectedCountry({
                value: country.iSO,
                label: country.internationalName,
              });
              const curr = currenciesForDropdown.find(
                (currency) => currency.id === market.currencyId
              );
              setSelectedCurrency({
                value: market.currencyId,
                label: curr?.iSO || "",
              });
            }
          });
          setPreviousAddress(market.address);
          return;
        }
      });
    };

    getMarket();
  }, [markets, countriesForDropDown, selected, currenciesForDropdown]);

  useEffect(() => {
    GetCountriesForDropdown();
    GetCurrenciesForDropdown();
  }, []);

  const exitEditMode = () => {
    setEditMode(false);
    setEdited({
      id: 0,
      name: "",
      currencyId: 0,
      address: "",
      countryIso: "",
      email: "",
      hotlinePhoneNumber: "",
    });
  };

  const handleEditMarket = async () => {
    if (selected === edited.id) {
      setLoading(true);
      const res = await handleUpdateCompanyMarket(JSON.stringify(edited));
      if (res) {
        handleGetMarkets();
        setLoading(false);
        showToast("Market successfully updated", "success");
        exitEditMode();
      } else {
        setLoading(false);
        showToast("There was an error while updating market", "error");
      }
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setEdited((prevEdited) => ({ ...prevEdited, [name]: value }));
  };

  const handleSelectCountryChange = (selectedOption: SingleValue<Country>) => {
    setSelectedCountry(selectedOption);
    setEdited((prevEdited) => ({
      ...prevEdited,
      countryIso: selectedOption ? selectedOption.value : "",
    }));
  };

  const handleSelectCurrencyChange = (
    selectedOption: SingleValue<Currency>
  ) => {
    setSelectedCurrency(selectedOption);
    setEdited((prevEdited) => ({
      ...prevEdited,
      currencyId: selectedOption ? selectedOption.value : 0,
    }));
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        inputRef.current &&
        !inputRef.current.contains(event.target as Node)
      ) {
        setIsFocused(false);
      }
    };

    if (isFocused) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isFocused]);

  useEffect(() => {
    const fetchSuggestions = async () => {
      if (query.length > 2 && query.length < previousAddress.length) {
        const response = await fetch(
          `https://api.mapbox.com/geocoding/v5/mapbox.places/${query}.json?access_token=${MAPBOX_ACCESS_TOKEN}&autocomplete=true&proximity=18.29,43.29&bbox=12.29,41.29,21.89,47.09`
        );
        const data = await response.json();
        const options = data.features.slice(0, 3).map((feature: any) => ({
          value: feature.place_name,
          label: feature.place_name,
        }));
        if (options.some((option) => option.label === query)) {
          setAddressOptions([]);
        } else {
          setAddressOptions(options);
        }
      } else {
        setAddressOptions([]);
      }
    };

    fetchSuggestions();
  }, [query, previousAddress.length]);

  useEffect(() => {
    setCountriesSelection(
      countriesForDropDown.map((country) => ({
        value: country.iSO,
        label: country.internationalName,
      }))
    );
  }, [countriesForDropDown]);

  return (
    <>
      {editMode && (
        <>
          <div className="modal-background" onClick={exitEditMode} />
          <div className="absolute-edit-company pt-2">
            <div className="flex flex-col items-center justify-start gap-2">
              <div className="flex justify-end items-center w-full p-5">
                <img
                  src="/icons/exit/cross-big.svg"
                  alt=""
                  onClick={exitEditMode}
                  className="cursor-pointer"
                />
              </div>
              <div className="horizontal-gray-line w-full"></div>
              <div className="flex flex-col justify-start items-stretch w-full px-10 py-5 gap-6">
                <div className="flex justify-between items-center">
                  <p className="edit-market-text">Edit Market</p>
                  <button
                    className={`edit-market-confirm-btn ${canConfirm ? "edit-market-confirm-btn-active" : ""
                      }`}
                    disabled={!canConfirm}
                    onClick={handleEditMarket}
                  >
                    Confirm
                  </button>
                </div>
                <div className="h-[2.5px] bg-[#B5BAC680] w-full mt-2"></div>
                <div className="flex flex-col gap-5 items-stretch justify-between p-2">
                  <div className="flex justify-stretch gap-5">
                    <div className="flex flex-col items-start flex-1 gap-2">
                      <label htmlFor="market" className="edit-market-header">
                        Market Name
                      </label>
                      <input
                        className="edit-market-input"
                        id="market"
                        placeholder="Market Name"
                        name="name"
                        value={edited.name}
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className="flex flex-col items-start flex-1 gap-2">
                      <label htmlFor="password" className="edit-market-header">
                        Company Name
                      </label>
                      <input
                        className="edit-market-input-readonly"
                        id="market"
                        placeholder="Market Name"
                        name="name"
                        value="Energize Mobility Soutions d.o.o."
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="flex justify-stretch gap-5">
                    <div className="flex flex-col items-start flex-1 gap-2">
                      <label htmlFor="country" className="edit-market-header">
                        Country
                      </label>
                      <input
                        className="edit-market-input-readonly"
                        id="country"
                        placeholder="Country"
                        name="country"
                        value={selectedCountry?.label}
                        readOnly
                      />
                    </div>
                    <div className="flex flex-col items-start flex-1 gap-2 relative">
                      <label className="edit-market-header">
                        Company Address
                      </label>
                      <input
                        className="edit-market-input"
                        type="text"
                        name="address"
                        placeholder="Address"
                        value={query}
                        onFocus={() => setIsFocused(true)}
                        ref={inputRef}
                        onChange={(e) => setQuery(e.target.value)}
                      />
                      {addressOptions.length > 0 && (
                        <ul className="w-full address-options-container">
                          {addressOptions.map((option) => (
                            <li
                              className="address-options"
                              key={option.value}
                              onClick={() => handleSelectAddressChange(option)}
                            >
                              {option.label}
                            </li>
                          ))}
                        </ul>
                      )}
                    </div>
                  </div>
                  <div className="flex justify-stretch gap-5">
                    <div className="flex flex-col items-start flex-1 gap-2">
                      <label htmlFor="email" className="edit-market-header">
                        Email
                      </label>
                      <input
                        className="edit-market-input"
                        id="email"
                        placeholder="Email"
                        name="email"
                        value={edited.email}
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className="flex flex-col items-start flex-1 gap-2">
                      <label
                        htmlFor="hotlinePhoneNumber"
                        className="edit-market-header"
                      >
                        Hotline Phone Number
                      </label>
                      <input
                        className="edit-market-input"
                        id="hotlinePhoneNumber"
                        placeholder="Hotline Phone Number"
                        name="hotlinePhoneNumber"
                        pattern="^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$"
                        value={edited.hotlinePhoneNumber}
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      {loading && <Spinner />}
    </>
  );
};

export default Edit;
