import React, { useState, useEffect, Fragment } from "react";
import { useStore } from "react-stores";
import "./Tickets.css";
import DateSelectRange from "../../../shared/calendar/DateSelectRange";
import Paging from "../../../shared/paging/Paging";
import Table from "../../../shared/table/Table";
import { formatDateLocale } from "../../../helpers/dataHelper";
import TicketPopup from "./TicketPopup";
import {
  handleGetTicketCount,
  handleGetTickets,
  handleSetSelectedTicket,
  TicketStore,
} from "../../../stores/ticketStore";

const Tickets = (props) => {
  const [paging, setPaging] = useState({ page: 1, perPage: 10 });
  const [calendar, setCalendar] = useState({ startDate: "", endDate: "" });
  const [showDateModal, setShowDateModal] = useState(false);
  const [searched, setSearched] = useState<string>("");
  const [sort, setSort] = useState({ field: "", method: "descending" });
  const [ticketPopup, setTicketPopup] = useState<boolean>(false);
  const { tickets, ticketsCount, ticketsDashboardData, selectedTicket } =
    useStore(TicketStore);
  console.log(selectedTicket);
  const [selected, setSelected] = useState<number>(selectedTicket || 0);

  useEffect(() => {
    handleGetTickets(
      paging.page,
      paging.perPage,
      calendar.startDate,
      calendar.endDate,
      searched,
      sort.field,
      sort.method === "descending" ? true : false
    );
  }, [paging, searched, calendar, sort]);

  useEffect(() => {
    handleGetTicketCount(calendar.startDate, calendar.endDate);
  }, [calendar]);

  useEffect(() => {
    tickets.find((el) => el.id === selected) && selected
      ? setTicketPopup(true)
      : setTicketPopup(false);
  }, [selected, tickets]);

  const handleClearDate = (event) => {
    event.stopPropagation();
    setCalendar({ startDate: "", endDate: "" });
  };

  const searchHistory = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearched(e.target.value);
    setPaging((prev) => ({ page: 1, perPage: prev.perPage }));
  };

  const handleSelection = (id: number) => {
    setSelected((prev) => (prev === id ? 0 : id));
  };

  const handleSortChanging = (sortingColumn: string) => {
    if (sort.field !== sortingColumn) {
      let newSort = { field: sortingColumn, method: "descending" };
      setSort(newSort);
    } else {
      let newSort = { ...sort };
      newSort.method =
        sort.method === "descending" ? "ascending" : "descending";
      newSort.field = sort.method === "descending" ? sort.field : "";
      setSort(newSort);
    }
  };

  return (
    <div className="flex-1 p-3.5 flex flex-col gap-3.5 items-stretch main-wrapper">
      <div className="bg-white card p-5">
        <div className="flex flex-row justify-between items-center header flex-wrap">
          <div className="tickets">
            <span>
              <span className="tickets-span">Operations/</span>
              <span className="tickets-span2">Tickets</span>
            </span>
          </div>
          <div className="flex flex-row gap-5 flex-wrap">
            <div className="relative">
              <img
                className="absolute h-5 left-3 top-2.5"
                src="/icons/table/searchIcon.svg"
                alt=""
              />
              <input
                className="searchInputTickets"
                placeholder=""
                onChange={searchHistory}
              />
            </div>
            <button
              onClick={() => setShowDateModal(!showDateModal)}
              className={`calendarButton ${
                showDateModal ? "calendarButtonActive" : ""
              } flex flex-row gap-3 items-center justify-center`}
            >
              {calendar.startDate && calendar.endDate ? (
                <div
                  className={
                    showDateModal
                      ? "calendarButtonActive picked-date-container-blue"
                      : "picked-date-container"
                  }
                >
                  <p className="date">
                    {formatDateLocale(calendar.startDate)} -{" "}
                    {formatDateLocale(calendar.endDate)}
                  </p>
                  {!showDateModal && (
                    <div className="date-exit-container">
                      <img
                        className="cursor-pointer exit-calendar-cross"
                        src="/icons/exit/cross.svg"
                        alt=""
                        onClick={handleClearDate}
                      />
                    </div>
                  )}
                </div>
              ) : (
                <>
                  <img
                    src={
                      showDateModal
                        ? "/icons/calendar/calendarIconWhite.svg"
                        : "/icons/calendar/calendarIcon.svg"
                    }
                    alt=""
                  />
                  Time Period
                  <img
                    src={
                      showDateModal
                        ? "/icons/arrow/arrowUpWhite.svg"
                        : "/icons/arrow/arrowDownDarkBlue.svg"
                    }
                    alt=""
                  />
                </>
              )}
            </button>
            {showDateModal && (
              <DateSelectRange
                showCalendar={setShowDateModal}
                start={calendar.startDate}
                end={calendar.endDate}
                handleApply={(start, end) => {
                  setCalendar({ startDate: start, endDate: end });
                  setPaging((prev) => ({ page: 1, perPage: prev.perPage }));
                }}
              />
            )}
          </div>
        </div>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-3">
        <div className="md:col-span-1 flex flex-col gap-2.5 items-stretch justify-between p-5 tickets-stats-container">
          <p className="tickets-stats-title">Unresolved tickets</p>
          <p className="tickets-stats-num">
            {ticketsDashboardData?.unresolvedTickets}
          </p>
        </div>
        <div className="md:col-span-1 flex flex-col gap-2.5 items-stretch justify-between p-5 tickets-stats-container">
          <p className="tickets-stats-title">Raised last 24h</p>
          <p className="tickets-stats-num">
            {ticketsDashboardData?.raisedTicketsLast24h}
          </p>
        </div>
        <div className="md:col-span-1 flex flex-col gap-2.5 items-stretch justify-between p-5 tickets-stats-container">
          <p className="tickets-stats-title">% of resolved tickets</p>
          <p className="tickets-stats-num">
            {ticketsDashboardData?.resolvedPercentage}%
          </p>
        </div>
      </div>
      <div className="p-5 table-container">
        <div className="flex flex-col items-stretch justify-between overflow-auto table-container overflow-visible">
          <table className="table table-striped">
            <thead>
              <tr className="header-row table-row">
                {tableHeaders.map((tableHeader, index) => (
                  <th
                    key={index}
                    onClick={() => handleSortChanging(tableRowOrder[index])}
                  >
                    <div className="table-header-with-sort">
                      <p>{tableHeader}</p>
                      {sort.field === tableRowOrder[index] ? (
                        sort.method === "ascending" ? (
                          <div className="flex flex-col items-center">
                            <img
                              src="/icons/arrow/sortArrowUpActive.svg"
                              alt=""
                            />
                            <img
                              src="/icons/arrow/sortArrowDownInactive.svg"
                              alt=""
                            />
                          </div>
                        ) : sort.method === "descending" ? (
                          <div className="flex flex-col items-center">
                            <img
                              src="/icons/arrow/sortArrowUpInactive.svg"
                              alt=""
                            />
                            <img
                              src="/icons/arrow/sortArrowDownActive.svg"
                              alt=""
                            />
                          </div>
                        ) : (
                          <div className="flex flex-col items-center">
                            <img
                              src="/icons/arrow/sortArrowUpInactive.svg"
                              alt=""
                            />
                            <img
                              src="/icons/arrow/sortArrowDownInactive.svg"
                              alt=""
                            />
                          </div>
                        )
                      ) : tableHeader === "User Phone Number" ? null : (
                        <div className="flex flex-col items-center">
                          <img
                            src="/icons/arrow/sortArrowUpInactive.svg"
                            alt=""
                          />
                          <img
                            src="/icons/arrow/sortArrowDownInactive.svg"
                            alt=""
                          />
                        </div>
                      )}
                    </div>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {tickets.map((dataRow) => (
                <Fragment key={dataRow.id}>
                  <tr
                    key={dataRow.id}
                    className={
                      selected === dataRow.id
                        ? "selected-row selectable-row"
                        : "selectable-row"
                    }
                    onClick={() => handleSelection(dataRow.id)}
                  >
                    {tableRowOrder.map((tableElement) =>
                      tableElement === "status" ? (
                        <td
                          className={
                            selected === dataRow.id ? "td-selected-row" : ""
                          }
                          key={tableElement}
                        >
                          <div
                            className={`${
                              dataRow[tableElement] === "Resolved"
                                ? "resolved-row"
                                : "unresolved-row"
                            } connector-status-container`}
                          >
                            {dataRow[tableElement]}
                          </div>
                        </td>
                      ) : (
                        <td
                          className={
                            selected === dataRow.id ? "td-selected-row" : ""
                          }
                          key={tableElement}
                        >
                          {dataRow[tableElement]}
                        </td>
                      )
                    )}
                  </tr>
                </Fragment>
              ))}
            </tbody>
          </table>
        </div>
        <Paging
          tableSize={ticketsCount}
          pageChangeFunc={(page, perPage) =>
            setPaging({ page: page, perPage: perPage })
          }
        />
      </div>
      {ticketPopup && (
        <TicketPopup
          ticketData={tickets.find((el) => el.id === selected)!}
          handleExit={() => {
            setSelected(0);
            handleGetTickets(
              paging.page,
              paging.perPage,
              calendar.startDate,
              calendar.endDate,
              searched
            );
            handleGetTicketCount(calendar.startDate, calendar.endDate);
            setTicketPopup(false);
          }}
        />
      )}
    </div>
  );
};

export default Tickets;

const tableHeaders = [
  "Ticket ID",
  "Raised Datetime",
  "Raised By",
  "User Phone Number",
  "Session ID",
  "Title",
  "Ticket Status",
  "Latest Response",
];

const tableRowOrder = [
  "id",
  "raisedAt",
  "userEmail",
  "userPhoneNumber",
  "sessionId",
  "title",
  "status",
  "respondedAt",
];
