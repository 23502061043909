import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import { AuthStore } from "../stores/authStore";

const COMPANY_ADMINS_CACHE_KEY = "companyAdmins";

export interface ICompanyAdmin {
  firstName: string;
  lastName: string;
  email: string;
  activated: boolean;
}

async function fetchCompanyAdmins(): Promise<ICompanyAdmin[]> {
  let jwtString: string | null = AuthStore.state.jwt;
  if (!jwtString) {
    jwtString = sessionStorage.getItem("jwt");
  }

  if (!jwtString) {
    throw new Error("JWT token not found");
  }

  const myHeaders = new Headers();
  myHeaders.append("Authorization", "Bearer " + jwtString);
  myHeaders.append("Content-Type", "application/json");

  const response = await fetch(
    `${process.env.REACT_APP_BASE_URL}api/v1.1/B2B/Companies/GetCompanyAdmins`,
    {
      method: "GET",
      headers: myHeaders,
    }
  );

  if (!response.ok) {
    throw new Error("Failed to fetch company admins");
  }

  return response.json();
}

const defaultCompanyAdminsQueryOptions: UseQueryOptions<ICompanyAdmin[]> = {
  queryKey: [COMPANY_ADMINS_CACHE_KEY],
  queryFn: fetchCompanyAdmins,
  staleTime: 5 * 60 * 1000,
  retry: 1,
  refetchOnWindowFocus: false,
};

type CustomCompanyAdminsQueryOptions = Omit<
  UseQueryOptions<ICompanyAdmin[]>,
  "queryKey" | "queryFn"
>;

export function useCompanyAdminsQuery(
  options?: CustomCompanyAdminsQueryOptions
) {
  return useQuery({
    ...defaultCompanyAdminsQueryOptions,
    ...options,
  });
}
