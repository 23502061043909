import { useEffect, useRef, useState } from "react";
import "./LocationInformation.css";
import { ILocation, ILocationList } from "../../../../../interfaces/ILocation";
import { useStore } from "react-stores";
import {
  DeleteLocation,
  GetLocationList,
  LocationStore,
  UpdateLocationInformation,
} from "../../../../../stores/locationStore";
import {
  fetchCoordinates,
  fetchCoordinatesOnlyLatLon,
  MAPBOX_ACCESS_TOKEN,
} from "../../../../../helpers/constants";
import {
  MarketStore,
  handleGetMarkets,
} from "../../../../../stores/marketStore";
import Popup from "../../../../../shared/popup/Popup";
import { useToast } from "../../../../../shared/toast/ToastContent";

interface Props {
  location: ILocationList | null;
  closeModal: Function;
  onLocationUpdate: (updatedLocation: any) => void;
  setScreen: (value: string) => void;
}

interface SelectOption {
  value: string;
  label: string;
  street: string;
  postalNumber: string;
  city: string;
}

const LocationInformation = ({
  location,
  closeModal,
  onLocationUpdate,
  setScreen,
}: Props) => {
  const [changesMade, setChangesMade] = useState(false);
  const { locations } = useStore(LocationStore);
  const [addressOptions, setAddressOptions] = useState<SelectOption[]>([]);
  const [previousAddress, setPreviousAddress] = useState("");
  const [query, setQuery] = useState(location?.street || "");
  const [updateToast, setUpdateToast] = useState(false);
  const [address, setAddress] = useState(location?.address || "");

  const [deleteLocation, setDeleteLocation] = useState(false);
  const [deleteLocationWithChargers, setDeleteLocationWithChargers] =
    useState(false);
  const { markets } = useStore(MarketStore);

  const { showToast } = useToast();

  const [edited, setEdited] = useState({
    id: location?.id || 0,
    companyMarketId: 0,
    name: location?.locationName || "",
    description: "test",
    connectorsCount: location?.connectorsCount,
    city: location?.city || "",
    street: location?.street || "",
    postalNumber: location?.postalNumber || "",
    latitude: location?.latitude || 0,
    longitude: location?.longitude || 0,
  });

  useEffect(() => {
    if (location) {
      const market = markets.find(
        (market) => market.name === location.companyMarketName
      );

      setEdited({
        id: location.id,
        companyMarketId: market ? market.id : 0,
        name: location.locationName,
        description: "test",
        city: location.city,
        street: location.street,
        connectorsCount: location.connectorsCount,
        postalNumber: location.postalNumber,
        latitude: location.latitude,
        longitude: location.longitude,
      });
      setAddress(location.address);
      setQuery(`${location.street}, ${location.postalNumber} ${location.city}`);
      setPreviousAddress(
        `${location.street}, ${location.postalNumber} ${location.city}`
      );
    }
  }, [location, markets]);

  useEffect(() => {
    const checkDifference = () => {
      const original = {
        locationName: location?.locationName || "",
        address: location?.address || "",
      };

      const current = {
        locationName: edited.name,
        address: address,
      };

      const isDifferent = JSON.stringify(original) !== JSON.stringify(current);
      setChangesMade(isDifferent);
    };

    checkDifference();
  }, [edited, location, address]);

  useEffect(() => {
    const fetchSuggestions = async () => {
      if (query.length > 2 && query.length < previousAddress.length) {
        const response = await fetch(
          `https://api.mapbox.com/geocoding/v5/mapbox.places/${query}.json?access_token=${MAPBOX_ACCESS_TOKEN}&autocomplete=true&proximity=18.29,43.29&bbox=12.29,41.29,21.89,47.09`
        );
        const data = await response.json();
        const options = data.features.slice(0, 3).map((feature: any) => {
          const addressComponents = feature.place_name.split(",");
          const street = addressComponents[0].trim();
          let city = "";
          let postalNumber = "";
          if (addressComponents.length > 1) {
            const postalAndCity = addressComponents[1].trim().split(" ");
            postalNumber = postalAndCity.shift();
            city = postalAndCity.join(" ").trim();
          }

          return {
            value: feature.place_name,
            label: feature.place_name,
            street: street,
            postalNumber: postalNumber,
            city: city,
          };
        });
        if (options.some((option) => option.label === query)) {
          setAddressOptions([]);
        } else {
          setAddressOptions(options);
        }
      } else {
        setAddressOptions([]);
      }
    };

    fetchSuggestions();
  }, [query, previousAddress.length]);

  useEffect(() => {
    handleGetMarkets();
  }, []);

  const handleInputChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setEdited((prevEdited) => ({ ...prevEdited, [name]: value }));
  };

  const handleSelectChange = async (selectedOption: SelectOption | null) => {
    if (selectedOption) {
      const { label, street, postalNumber, city } = selectedOption;

      setEdited((prevEdited) => ({
        ...prevEdited,
        street,
        postalNumber,
        city,
      }));

      setAddress(label);
      setQuery(label);
      setAddressOptions([]);

      const coordinates = await fetchCoordinatesOnlyLatLon(label);
      if (coordinates) {
        const updatedLocation = {
          ...edited,
          street,
          postalNumber,
          city,
          latitude: coordinates.latitude,
          longitude: coordinates.longitude,
        };

        if (setScreen) {
          setScreen("map");
        }
        setEdited(updatedLocation);
        onLocationUpdate(updatedLocation);
        closeModal();
      }
    }
  };

  const handleEditSave = () => {
    edited.latitude = Number(edited.latitude);
    edited.longitude = Number(edited.longitude);
    UpdateLocationInformation(edited).then((res) => {
      if (res) {
        GetLocationList(1, 10, "LocationName", true);
        showToast("Location successfully updated", "success");
        setChangesMade(false);
      } else {
        showToast("Error while updating location", "error");
      }
    });
  };

  const popupMainText = () => {
    return (
      <div className="delete-charger-popup">
        <div className="popout-main-text">
          Do you want to delete this location?
        </div>
        <div className="flex flex-row justify-start items-center gap-3">
          <img src="/icons/nav/activeDot.svg" alt="" />
          <div className="popout-other-text">Location ID: {location?.id}</div>
        </div>
        <div className="border-b-[2.5px] border-solid border-[#E7E9ED] w-full my-3"></div>
      </div>
    );
  };

  const mainTextDeleteLocationWithChargers = () => {
    return (
      <div className="delete-charger-popup">
        <div className="flex flex-row justify-start items-center gap-3">
          <img src="/icons/nav/activeDot.svg" alt="" />
          <div className="text-[18px] font-semibold text-[#111F47]">
            Location ID: {location?.id}
          </div>
        </div>
        <div className="border-b-[2.5px] border-solid border-[#E7E9ED] w-full my-2"></div>
        <div className="text-[16px] font-medium text-[#B5BAC6] text-left mb-5">
          Please delete all chargers from selected location before deleting it
        </div>
      </div>
    );
  };

  const handleDeleteLocation = async () => {
    const locationDeletedSuccessfully = await DeleteLocation(location?.id!);
    if (locationDeletedSuccessfully) {
      showToast("Selected location successfully deleted", "success");
      closeModal();
    } else {
      showToast("Failed to delete location", "error");
    }
  };

  const handleSetDeleteLocation = () => {
    location?.chargersCount! > 0
      ? setDeleteLocationWithChargers(true)
      : setDeleteLocation(true);
  };

  return (
    <div className="location-information-container">
      <div className="flex-location" style={{ margin: "30px 0px" }}>
        <p className="title 2xl:text-[24px] md:text-[20px] sm:text-[16px]">
          Location Information
        </p>
        <div className="location-btn-group">
          <button
            className="delete-location-btn"
            onClick={handleSetDeleteLocation}
          >
            Delete
          </button>
          <button
            disabled={!changesMade}
            className={changesMade ? "editBtnActive" : "editBtn"}
            onClick={handleEditSave}
          >
            Save
          </button>
        </div>
      </div>
      <div className="line" />
      <div className="location-information-table">
        <div className="flex justify-between gap-5 w-full">
          <div className="flex-fields">
            <p className="title-1">Location ID</p>
            <div className="unactive-fields">
              <p className="unactive-data">{location?.id}</p>
            </div>
          </div>
          <div className="flex-fields">
            <p className="title-1">Location Name</p>
            <input
              className="location-info-edit-input"
              placeholder="Location Name"
              name="name"
              value={edited.name}
              onChange={handleInputChange}
            />
          </div>
        </div>

        <div className="flex justify-between gap-5 w-full">
          <div className="flex-fields relative">
            <p className="title-1">Address</p>
            <div className="relative w-full">
              <input
                className="location-info-edit-input pr-8"
                type="text"
                name="address"
                placeholder="Address"
                value={query}
                onChange={(e) => setQuery(e.target.value)}
              />
              {query.length > 0 && (
                <img
                  onClick={(e) => setQuery("")}
                  className="absolute right-3 top-1/2 transform -translate-y-1/2 cursor-pointer"
                  height={16}
                  width={16}
                  src="/icons/shared/exit-cross.svg"
                  alt="cross"
                />
              )}
              {addressOptions.length > 0 && (
                <ul className="address-container-edit-location">
                  {addressOptions.map((option) => (
                    <li
                      className="address-options"
                      key={option.value}
                      onClick={() => handleSelectChange(option)}
                    >
                      {option.label}
                    </li>
                  ))}
                </ul>
              )}
            </div>
          </div>

          <div className="flex-fields">
            <p className="title-1">Longitude & Latitude</p>
            <div className="unactive-fields">
              <p className="unactive-data">
                ({location?.longitude}, {location?.latitude})
              </p>
            </div>
          </div>
        </div>
        <div className="flex justify-between gap-5 w-full">
          <div className="flex-fields">
            <p className="title-1">Chargers</p>
            <div className="unactive-fields">
              <p className="unactive-data">{location?.chargersCount}</p>
            </div>
          </div>
          <div className="flex-fields">
            <p className="title-1">Connectors</p>
            <div className="unactive-fields">
              <p className="unactive-data">{location?.connectorsCount}</p>
            </div>
          </div>
        </div>

        <div className="flex justify-between gap-5">
          <div className="flex-fields">
            <p className="title-1">Market</p>
            <div className="unactive-fields">
              <p className="unactive-data">{location?.companyMarketName}</p>
            </div>
          </div>
          <div className="flex-fields-last">
            <p className="title-1">Market</p>
            <div className="unactive-fields">
              <p className="unactive-data">{location?.companyMarketName}</p>
            </div>
          </div>
        </div>
      </div>
      {deleteLocation && (
        <Popup
          mainText={popupMainText()}
          confirmText="Yes"
          closeText="No"
          confirmFunction={handleDeleteLocation}
          closeFunction={() => setDeleteLocation(false)}
        />
      )}
      {deleteLocationWithChargers && (
        <Popup
          mainText={mainTextDeleteLocationWithChargers()}
          closeText="Close"
          confirmFunction={handleDeleteLocation}
          closeFunction={() => setDeleteLocationWithChargers(false)}
        />
      )}
    </div>
  );
};

export default LocationInformation;
