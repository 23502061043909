import "./InfoPopup.css";

const InfoPopup = () => {
  return (
    <div className="info-container ">
      <div className="section">
        <p className="section-title">Reset</p>
        <div className="text-container">
          <p className="text">
            Attempts to safely stop any active charge session and other
            running commands, allowing the charger to recover from any
            issues you may be facing.
          </p>
        </div>
      </div>
      <div className="section">
        <p className="section-title">Emergency Reset</p>
        <div className="text-container">
          <p className="text">
            Aggressively stops any ongoing charge sessions before
            rebooting the charger's internal software.
          </p>
        </div>
      </div>
      <div className="section">
        <p className="section-title">Change Availability</p>
        <div className="text-container">
          <p className="text">Changes the connector status to ‘available’ or ‘unavailable,’
            with the status updating after the vehicle finishes charging if
            it is currently connected.</p>
        </div>
      </div>
      <div className="section">
        <p className="section-title">Unlock</p>
        <div className="text-container">
          <p className="text">
            When pressed, unlocks the connector plug connected to
            the charger.
          </p>
        </div>
      </div>
    </div>
  );
};

export default InfoPopup;
