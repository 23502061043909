import React, { useState, useEffect } from "react";
import {
  PricingStore,
  moveConnectorInPricingGroup,
  handleGetConnectorsForMoving,
} from "../../../stores/pricingStore";
import Table from "../../../shared/table/Table";
import "./AddConnectors.css";
import { useStore } from "react-stores";
import { useToast } from "../../../shared/toast/ToastContent";
import Paging from "../../../shared/paging/Paging";
import Spinner from "../../../shared/spinner/Spinner";

interface Paging {
  page: number;
  perPage: number;
}

const AddConnectors: React.FC<{
  pricingGroupId: number;
  companyMarketId: number;
  exit: Function;
}> = ({ pricingGroupId, companyMarketId, exit }) => {
  const [selected, setSelected] = useState<any>([]);
  const [paging, setPaging] = useState<Paging>({ page: 1, perPage: 10 });
  const { connectorsNotInPricingGroup, connectorsNotInPricingGroupSize } = useStore(PricingStore);
  const { showToast } = useToast();
  const [loading, setLoading] = useState(false);

  const tableHeaders = [
    "Connector ID",
    "Group Name",
    "Market",
    "Connector Type",
    "Electric Current",
  ];

  const tableRowOrder = [
    "connectorName",
    "groupName",
    "companyMarketName",
    "connectorTypeName",
    "electricityCurrent",
  ];

  useEffect(() => {
    handleGetConnectorsForMoving(pricingGroupId, [companyMarketId], paging.page, paging.perPage);
  }, [companyMarketId, pricingGroupId, paging]);

  useEffect(() => {
    return () => {
      PricingStore.setState({
        ...PricingStore.state,
        unassignedConnectors: [],
        unassignedConnectorsSize: 0,
      });
    };
  }, []);

  const handleSelection = (id: number) => {
    console.log(id)
    if (selected.includes(id)) {
      setSelected(prev => prev.filter((item) => item !== id));
    } else {
      setSelected(prev => [...prev, id]);
    }
  };

  async function submitConnectorAdd() {
    setLoading(true);
    const res = await moveConnectorInPricingGroup(pricingGroupId, selected);
    setLoading(false);
    res ?
      showToast(`${selected.length} ${selected.length === 1 ? 'connector' : 'connectors'} successfully added to pricing group`, 'success')
      : showToast('There was error while adding connectors to pricing group', 'error');
    exit();
  }

  return (
    <>
      <div className="modal-background" onClick={() => exit()} />
      <div className="absolute-add-connectors pt-2">
        <div className="flex flex-col items-center justify-start gap-2">
          <div className="flex justify-end items-center w-full p-5">
            <img
              src="/icons/exit/cross-big.svg"
              alt=""
              onClick={() => exit()}
              className="cursor-pointer"
            />
          </div>
          <div className="horizontal-gray-line w-full"></div>
          <div className="flex flex-col justify-start items-stretch w-full px-10 py-7 gap-5">
            <div className="flex justify-between items-center">
              <p className="addConnectorsText">Add connector(s) to pricing group</p>
              <button
                className={`confirm-connectors-btn ${selected.length > 0 ? "confirm-connectors-btn-active" : ""
                  }`}
                disabled={selected.length <= 0}
                onClick={submitConnectorAdd}
              >
                Confirm
              </button>
            </div>
            <div className="h-[2.5px] bg-[#B5BAC680] w-full mt-3"></div>
            {selected.length ? (
              <div className="confirm-addition-span">
                {selected.length} selected {selected.length === 1 ? 'connector' : 'connectors'}
              </div>
            ) : <div />}
            <Table
              tableHeaders={tableHeaders}
              tableRowOrder={tableRowOrder}
              tableRows={connectorsNotInPricingGroup}
              selected={selected}
              handleSelection={handleSelection}
              preloadRowNumber={5}
            />
            <Paging
              tableSize={connectorsNotInPricingGroupSize}
              pageChangeFunc={(page, perPage) =>
                setPaging({ page: page, perPage: perPage })
              }
            />
          </div>
        </div>
      </div>
      {loading && <Spinner />}
    </>
  );
};

export default AddConnectors;
