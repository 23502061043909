export interface IAlarm {
    id: number;
    createdAt: string;
    title: string;
    type: AlarmType;
    shortDescription: string;
    fullDescription: string;
    connectorId: number;
    connectorStringId: string;
    chargerId: number;
    chargerStringId: string;
    locationId: number;
    location: string;
    hasBeenRead: boolean;
}

export interface IAlarmsCountByType {
    notificationCount: number;
    warningCount: number;
    errorCount: number;
}

export interface IAlarmPerDay {
    day: string;
    count: number;
}

export interface IAlarmsNotification {
    id: number;
    createdAtRelative: string;
    title: string;
    shortDescription: string;
    hasBeenRead: boolean;
}

export enum AlarmType {
    Warning = 'Warning',
    Error = 'Error',
    Notification = 'Notification',
}