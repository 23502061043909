import { Store } from "react-stores";
import { INotificationsStore, IPricingStore } from "../interfaces/IStores";
import { AuthStore } from "./authStore";
import { formatRelativeTime } from "../helpers/dataHelper";

const PUBLIC_BASE_URL = process.env.REACT_APP_BASE_URL;

export const NotificationsStore = new Store<INotificationsStore>({
  alarms: [],
  tickets: [],
  alarmsCount: 0,
  ticketsCount: 0,
});

export const handleGetNotifications = async () => {
  try {
    let jwtString: string | null = AuthStore.state.jwt;
    if (!jwtString) jwtString = sessionStorage.getItem("jwt");
    const myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + jwtString);
    let response = await fetch(
      `${PUBLIC_BASE_URL}api/v1.1/B2B/Notifications/GetNotifications`,
      {
        method: "GET",
        headers: myHeaders,
      }
    );

    let { ticketsCount, alarmsCount, alarms, tickets } = await response.json();
    alarms.forEach(alarm => alarm.createdAtRelative = formatRelativeTime(alarm.createdAt));
    tickets.forEach(ticket => {
      ticket.raisedAtRelative = formatRelativeTime(ticket.raisedAt);
      ticket.responded = ticket.status === 'Resolved';
    });
    NotificationsStore.setState({
      alarms: alarms,
      tickets: tickets,
      alarmsCount: alarmsCount,
      ticketsCount: ticketsCount,
    });
  } catch (e) {
    console.log("Error: ", e);
  }
};