import React, { useState, useEffect } from "react";
import { useToast } from "../../../shared/toast/ToastContent";
import { handleTicketResolve, handleTicketResponse } from "../../../stores/ticketStore";
import { ITicket } from "../../../interfaces/ITickets";
import Spinner from "../../../shared/spinner/Spinner";


interface Props {
    ticketData: ITicket
    handleExit: () => void;
}

const TicketPopup: React.FC<Props> = ({ ticketData, handleExit }) => {
    const [ticketResponse, setTicketResponse] = useState<string>(ticketData.response || '');
    const [loading, setLoading] = useState(false);
    const { showToast } = useToast();
    const [responded, setResponded] = useState<boolean>(Boolean(ticketData.response));

    const handleResolve = async () => {
        setLoading(true);
        const res = await handleTicketResolve(ticketData.id);
        setLoading(false);
        if (res) {
            showToast(`Ticket ${ticketData.id} successfully resolved`, 'success');
            handleExit();
        } else {
            showToast(`There was an error while resolving ticket ${ticketData.id}`, 'error');
        }
    };

    const respondOnTicket = async () => {
        if (ticketResponse) {
            setLoading(true);
            const res = await handleTicketResponse({ id: ticketData.id, title: ticketData.title || '', response: ticketResponse });
            setLoading(false);
            if (res) {
                setResponded(true);
                showToast('Ticket response successful', 'success');
            } else {
                showToast('There was an error while responding on a ticket', 'error');
            }
        }
    };


    return (
        <>
            <div className="modal-background" onClick={() => handleExit()} />
            <div className="absolute-ticket-info pt-2">
                <div className="flex flex-col items-center justify-start gap-3">
                    <div className="flex justify-end items-center w-full p-5">
                        <img
                            src="/icons/exit/cross-big.svg"
                            alt=""
                            onClick={() => handleExit()}
                            className="cursor-pointer"
                        />
                    </div>
                    <div className="horizontal-gray-line w-full"></div>
                    <div className="flex flex-col justify-start items-stretch w-full px-7 py-5 gap-4">
                        <div className="flex justify-end items-center">
                            <button className={`${ticketData.status === 'Resolved' ? 'respond-btn' : 'respond-btn-active'}`}
                                onClick={handleResolve}
                                disabled={ticketData.status === 'Resolved'}
                            >
                                Resolve
                            </button>
                        </div>
                        <div className="flex justify-stretch gap-5">
                            <div className="flex flex-col items-start flex-1 gap-2">
                                <p className="input-title">Ticket ID</p>
                                <input
                                    type="text"
                                    placeholder="Ticket ID"
                                    value={ticketData.id}
                                    className="ticket-input-readonly"
                                    readOnly
                                />
                            </div>
                            <div className="flex flex-col items-start flex-1 gap-2">
                                <p className="input-title">Title</p>
                                <input
                                    type="text"
                                    placeholder="Title"
                                    value={ticketData.title}
                                    className="ticket-input-readonly"
                                    readOnly
                                />
                            </div>
                        </div>
                        <div className="flex justify-stretch gap-5">
                            <div className="flex flex-col items-start flex-1 gap-2">
                                <p className="input-title">Description</p>
                                <textarea
                                    placeholder="Description"
                                    value={ticketData.description}
                                    className="ticket-textarea-readonly"
                                    rows={4}
                                    readOnly
                                />
                            </div>
                        </div>
                        <div className="flex justify-stretch gap-5">
                            <div className="flex flex-col items-start flex-1 gap-2">
                                <p className="input-title">Response</p>
                                <textarea
                                    placeholder=""
                                    value={ticketResponse}
                                    onChange={e => setTicketResponse(e.target.value)}
                                    className={`${responded || ticketData.status === 'Resolved' ? 'ticket-textarea-readonly' : 'ticket-textarea'}`}
                                    rows={4}
                                    readOnly={responded || ticketData.status === 'Resolved'}
                                />
                            </div>
                        </div>
                        <div className="flex justify-end items-center">
                            <button className={`${responded || ticketData.status === 'Resolved' ? 'respond-btn' : 'respond-btn-active'}`}
                                disabled={responded || ticketData.status === 'Resolved'}
                                onClick={respondOnTicket}>
                                Respond
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            {loading && <Spinner />}
        </>
    )
}

export default TicketPopup;
