import { useState } from "react";

interface Props {
  onSelect: (status: EVSERoamingType) => void;
}
export enum EVSERoamingType {
  Published = "Published",
  Unpublished = "Unpublished",
}
const EVSEStatusDropdown = ({ onSelect }: Props) => {
  const [expanded, setExpanded] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState<EVSERoamingType>(
    EVSERoamingType.Unpublished
  );

  const handleSelect = (status: EVSERoamingType, event: React.MouseEvent) => {
    event.stopPropagation();
    onSelect(status);
    setSelectedStatus(status);
    setExpanded(false);
  };

  return (
    <div
      onClick={() => setExpanded((prev) => !prev)}
      className={`flex text-[14px] font-medium w-[200px] gap-2.5 items-center justify-between rounded-[10px] py-2.5 px-3 cursor-pointer relative ${
        expanded ? "bg-[#1E4CDC] text-white" : "bg-[#E7E9ED80] text-[#111F47]"
      }`}
    >
      {selectedStatus || "EVSE Roaming Status"}
      <img
        src={
          expanded
            ? "/icons/arrow/arrowUpWhite.svg"
            : "/icons/arrow/arrowDownDarkBlue.svg"
        }
        alt=""
      />
      {expanded && (
        <div
          style={{
            boxShadow: "0px 14px 29.6px 0px rgba(0, 0, 0, 0.05)",
          }}
          className="bg-[#FFF] p-2.5 rounded-[10px] absolute top-12 left-0 right-0"
        >
          <div
            className={`p-2 cursor-pointer rounded-[5px] text-[12px] font-medium text-[#111F47] hover:bg-[#E9EDFC] hover:text-[#1E4CDC]`}
            onClick={(e) => handleSelect(EVSERoamingType.Published, e)}
          >
            {EVSERoamingType.Published}
          </div>
          <div
            className={`p-2 cursor-pointer rounded-[5px] text-[12px] font-medium text-[#111F47] hover:bg-[#E9EDFC] hover:text-[#1E4CDC]`}
            onClick={(e) => handleSelect(EVSERoamingType.Unpublished, e)}
          >
            {EVSERoamingType.Unpublished}
          </div>
        </div>
      )}
    </div>
  );
};

export default EVSEStatusDropdown;
