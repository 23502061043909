import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import {
  GetUnPublishedChargers,
  RoamingStore,
} from "../../stores/roamingStore";
import { IChargerRoaming } from "../../interfaces/IRoaming";

const UNPUBLISHED_CHARGERS_CACHE_KEY = "unPublishedChargers";

async function fetchUnPublishedChargers(
  companyMarketId: number,
  sortingField: string,
  sortingDescending: boolean,
  pageIndex: number,
  pageSize: number
): Promise<IChargerRoaming[]> {
  await GetUnPublishedChargers(
    companyMarketId,
    sortingField,
    sortingDescending,
    pageIndex,
    pageSize
  );
  return RoamingStore.state.unpublishedChargers;
}

const defaultUnPublishedChargersQueryOptions: UseQueryOptions<
  IChargerRoaming[]
> = {
  queryKey: [UNPUBLISHED_CHARGERS_CACHE_KEY],
  staleTime: 10 * 60 * 1000,
  retry: 1,
  refetchOnWindowFocus: false,
};

type CustomUnPublishedChargersQueryOptions = Omit<
  UseQueryOptions<IChargerRoaming[]>,
  "queryKey" | "queryFn"
>;

export function useUnPublishedChargersQuery(
  companyMarketId: number,
  sortingField: string,
  sortingDescending: boolean,
  pageIndex: number,
  pageSize: number,
  options?: CustomUnPublishedChargersQueryOptions
) {
  return useQuery({
    ...defaultUnPublishedChargersQueryOptions,
    queryKey: [UNPUBLISHED_CHARGERS_CACHE_KEY],
    queryFn: () =>
      fetchUnPublishedChargers(
        companyMarketId,
        sortingField,
        sortingDescending,
        pageIndex,
        pageSize
      ),
    ...options,
  });
}
