import React, { useState } from "react";
import PasswordReset from "./PasswordReset";
import { useStore } from "react-stores";
import { AuthStore } from "../../stores/authStore";
import "./Profile.css";

const Profile: React.FC = () => {
    const [changePasswordMode, setChangePasswordMode] = useState(false);

    const authStore = useStore(AuthStore);
    const { user } = authStore;


    return (
        <div className="flex-1 p-3.5 flex flex-col gap-3.5 relative">
            <div className="items-stretch justify-start p-5 profile-card-container">
                <div className="flex flex-col py-10 px-14 gap-8">
                    <div className="flex items-center gap-10">
                        <img
                            src="/icons/nav/profileIcon.png"
                            alt=""
                            className="w-[140px] m-3"
                        />
                        <div>
                            <div className="name-surname">{user?.firstName} {user?.lastName}</div>
                            <div className="super-admin">{user?.roleName}</div>
                        </div>
                    </div>
                    <div className="w-full h-[2px] bg-[#B5BAC680] mb-2"></div>
                    <div className="flex flex-col items-center justify-center gap-8">
                        <div className="flex w-full mt-2">
                            <div className="account-info-text">Account Information</div>
                        </div>
                        <div className="flex flex-col justify-start items-stretch w-full gap-6">
                            <div className="flex justify between">
                                <div className="w-full flex flex-col gap-[10px] flex-1">
                                    <label className="user-header">Name</label>
                                    <input
                                        placeholder="First Name"
                                        name="firstName"
                                        className="account-info-input-readonly"
                                        value={user?.firstName}
                                        readOnly
                                    />
                                </div>
                                <div className="w-full flex flex-col gap-[10px] flex-1">
                                    <label className="user-header">Surname</label>
                                    <input
                                        placeholder="Last Name"
                                        className="account-info-input-readonly"
                                        name="lastName"
                                        value={user?.lastName}
                                        readOnly
                                    />
                                </div>
                                <div className="w-full flex flex-col gap-[10px] flex-1">
                                    <label className="user-header">E-mail</label>
                                    <input
                                        className="account-info-input-readonly"
                                        placeholder="Email"
                                        name="email"
                                        value={user?.email}
                                        readOnly
                                    />
                                </div>
                            </div>
                            <div className="flex justify between">
                                <div className="w-full flex flex-col gap-[10px] flex-1">
                                    <label className="user-header">Password</label>
                                    <input
                                        className="account-info-input-readonly"
                                        value="********"
                                        readOnly
                                    />
                                </div>
                                <div className="w-full flex flex-col flex-1 justify-end">
                                    <button className='change-password-btn' onClick={() => setChangePasswordMode(true)}>
                                        Change Password
                                    </button>
                                </div>
                                <div className="w-full flex-1">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            {changePasswordMode && (
                <PasswordReset
                    changePasswordMode={changePasswordMode}
                    setExit={setChangePasswordMode}
                />
            )}
        </div>
    );
};

export default Profile;
