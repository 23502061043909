import React, { useState } from "react";
import { changePasswordHandler } from "../../stores/authStore";
import "./Profile.css";
import { useToast } from '../../shared/toast/ToastContent';
import Spinner from "../../shared/spinner/Spinner";


interface Props {
  changePasswordMode: boolean;
  setExit: Function;
}

const ChangePasswordModal: React.FC<Props> = ({
  changePasswordMode,
  setExit,
}) => {
  const [password, setPassword] = useState<string>("");
  const [newPassword, setNewPassword] = useState<string>("");
  const [repeatedPassword, setRepeatedPassword] = useState<string>("");
  const [visiblePassword, setVisiblePassword] = useState<boolean>(false);
  const [visibleNewPassword, setVisibleNewPassword] = useState<boolean>(false);
  const [weakPassword, setWeakPassword] = useState<boolean>(true);
  const [okPassword, setOkPassword] = useState<boolean>(false);
  const [strongPassword, setStrongPassword] = useState<boolean>(false);
  const { showToast } = useToast();
  const [loading, setLoading] = useState(false);


  const confirmApproved =
    !weakPassword &&
    newPassword === repeatedPassword &&
    newPassword &&
    repeatedPassword;

  const handlePasswordInput = (
    label: string,
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = e.target.value;
    if (label === "newPassword") {
      setNewPassword(value);
    } else if (label === "repeatedPassword") {
      setRepeatedPassword(value);
    } else if (label === "oldPassword") {
      setPassword(value);
    }
  };

  const handlePaswordCompexity = (e: React.ChangeEvent<HTMLInputElement>) => {
    const password = e.target.value;
    if (
      password.length >= 8 &&
      /[A-Z]/.test(password) &&
      /[a-z]/.test(password)
    ) {
      if (/[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(password)) {
        setWeakPassword(false);
        setOkPassword(false);
        setStrongPassword(true);
        return;
      }
      setWeakPassword(false);
      setStrongPassword(false);
      setOkPassword(true);
    } else {
      setWeakPassword(true);
      setOkPassword(false);
      setStrongPassword(false);
    }
  };

  const handlePasswordChange = async () => {
    setLoading(true);
    const data = {
      oldPassword: password,
      newPassword: newPassword,
      newPasswordRepeated: repeatedPassword,
    };
    const response = await changePasswordHandler(JSON.stringify(data));
    setLoading(false);
    if (response) {
      showToast("Password changed successfully", 'success');
      setExit(false);
    } else {
      showToast("An error happened while trying to change your password", 'error');
    }
  };

  return (
    <>
      {changePasswordMode && (
        <>
          <div className="modal-background" onClick={() => setExit(false)} />
          <div className="absolute-change-password pt-2">
            <div className="flex flex-col items-center justify-start gap-2">
              <div className="flex justify-end items-center w-full p-5">
                <img
                  src="/icons/exit/cross-big.svg"
                  alt=""
                  onClick={() => setExit(false)}
                  className="cursor-pointer"
                />
              </div>
              <div className="horizontal-gray-line w-full"></div>
              <div className="flex flex-col justify-start items-stretch w-full px-10 py-5 gap-6">
                <div className="flex justify-start items-center">
                  <p className="change-password-text">Change Password</p>
                </div>
                <div className="flex flex-col gap-5 items-stretch justify-between p-2">
                  <div className="flex flex-col gap-2">
                    <label htmlFor="password" className="change-password-header">
                      Old Password
                    </label>
                    <div className="relative">
                      <input
                        className="password-input"
                        value={password}
                        type={visiblePassword ? "text" : "password"}
                        id="password"
                        onChange={(e) => handlePasswordInput("oldPassword", e)}
                      />
                      <img
                        src={
                          visiblePassword
                            ? "/icons/eye/eye-closed.svg"
                            : "/icons/eye/eye-open.svg"
                        }
                        alt=""
                        className="visible-passwords"
                        onClick={() => setVisiblePassword(!visiblePassword)}
                      />
                    </div>
                  </div>
                  <div className="flex flex-col gap-2">
                    <label htmlFor="newPassword" className="change-password-header">
                      New Password
                    </label>
                    <div className="relative">
                      <input
                        className="password-input"
                        value={newPassword}
                        type={visibleNewPassword ? "text" : "password"}
                        id="newPassword"
                        onChange={(e) => {
                          handlePasswordInput("newPassword", e);
                          handlePaswordCompexity(e);
                        }}
                      />
                      <img
                        src={
                          visibleNewPassword
                            ? "/icons/eye/eye-closed.svg"
                            : "/icons/eye/eye-open.svg"
                        }
                        alt=""
                        className="visible-passwords"
                        onClick={() => setVisibleNewPassword(!visibleNewPassword)}
                      />
                    </div>
                    {newPassword && (
                      <div className="flex flex-row justify-between items-center flex-wrap">
                        <div
                          className="flex flex-row justify-start gap-1 items-center"
                        >
                          <div
                            className={`three-stripes ${weakPassword
                              ? "red"
                              : okPassword
                                ? "yellow"
                                : "green"
                              }`}
                          />
                          <div
                            className={`three-stripes ${okPassword
                              ? "yellow"
                              : strongPassword
                                ? "green"
                                : ""
                              }`}
                          />
                          <div
                            className={`three-stripes ${strongPassword && "green"
                              }`}
                          />
                        </div>
                        <div
                          className={`${weakPassword
                            ? "red-font"
                            : okPassword
                              ? "yellow-font"
                              : strongPassword
                                ? "green-font"
                                : ""
                            }`}
                        >
                          {weakPassword
                            ? "Too Weak"
                            : okPassword
                              ? "Could be Stronger"
                              : "Strong Password"}
                        </div>
                      </div>
                    )}
                    <div className="relative">
                      <p className="password-requirements">
                        Password must be 8-16 characters long, and contain one
                        uppercase and one lowercase character.
                      </p>
                    </div>
                  </div>
                  <div className="flex flex-col gap-2">
                    <label htmlFor="repeatedPassword" className="change-password-header">
                      Confirm Password
                    </label>
                    <input
                      className="password-input"
                      value={repeatedPassword}
                      type="password"
                      id="repeatedPassword"
                      onChange={(e) => handlePasswordInput("repeatedPassword", e)}
                    />
                    <div className="relative">
                      <p className="password-requirements">
                        Both password must match.
                      </p>
                    </div>
                  </div>
                  <button
                    className={`mt-8 change-password-confirm-btn ${confirmApproved ? "change-password-confirm-btn-active" : ""
                      }`}
                    disabled={!confirmApproved}
                    onClick={handlePasswordChange}
                  >
                    Confirm
                  </button>
                </div>
              </div>
            </div>
          </div>
          {loading && <Spinner />}
        </>
      )}
    </>
  );
};

export default ChangePasswordModal;
