import { useState } from "react";
import Paging from "../../shared/paging/Paging";
import Table from "../../shared/table/Table";
import { RoamingStore } from "../../stores/roamingStore";
import { useStore } from "react-stores";
import { IChargerRoaming } from "../../interfaces/IRoaming";
import { generateQR } from "../../helpers/dataHelper";

interface Props {
  data: IChargerRoaming[];
  selected: number;
  handleSelection: (id: number) => void;
  setPaging: (params: { page: number; perPage: number }) => void;
  setSorting: (params: { field: string; descending: boolean }) => void;
}
const PublishedEVSETable = ({
  selected,
  handleSelection,
  data,
  setPaging,
  setSorting,
}: Props) => {
  const [expanded, setExpanded] = useState<number>(0);

  const handleSort = async (sortingField, sortingMethod) => {
    if (sortingMethod === "default") {
      setSorting({ field: "", descending: true });
    } else {
      setSorting({
        field: sortingField,
        descending: sortingMethod === "descending" ? true : false,
      });
    }
  };

  const handleExpanded = (id: number) => {
    if (expanded === id) {
      setExpanded(0);
    } else {
      setExpanded(id);
    }
  };

  const handleCheckboxClick = (chargerId: number) => {
    handleExpanded(chargerId);
    handleSelection(chargerId);
  };

  const downloadQRCode = (qrString: string, connectorId: string) => {
    const qrDataUrl = generateQR(qrString);
    const link = document.createElement("a");
    link.href = qrDataUrl;
    link.download = "connector-" + connectorId + ".png";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div className="p-5 table-container">
      <Table
        tableHeaders={tableSeedHeaders}
        tableRowOrder={tableSeedRowOrder}
        handleSelection={(id) => handleSelection(id)}
        selected={selected}
        tableRows={data ? data : []}
        sortColumns={[
          "Charger ID",
          "Location Name",
          "Published connectors",
          "Charger information",
        ]}
        handleSort={(sortingField, sortingMethod) =>
          handleSort(sortingField, sortingMethod)
        }
        expanded={expanded}
        handleExpanded={(id) => handleCheckboxClick(id)}
      >
        {data.map((charger) => (
          <tr key={charger.id} className="subtable-row">
            <td colSpan={8} id="initial-td">
              {expanded === charger.id && (
                <div>
                  <table className="w-[100%]">
                    <thead>
                      <tr>
                        <th className="subtable-header"> </th>
                        <th className="subtable-header"> </th>
                        <th className="subtable-header">Connector ID</th>
                        <th className="subtable-header">Connector status</th>
                        <th className="subtable-header">Connector type</th>
                        <th className="subtable-header">Electric current</th>
                        <th className="subtable-header">Roaming EVSE Id</th>
                        <th className="subtable-header">Roaming QR code</th>
                        <th className="subtable-header">Roaming status</th>
                        <th className="subtable-header">Note</th>
                      </tr>
                    </thead>
                    <tbody>
                      {charger.connectors && charger.connectors.length > 0 ? (
                        charger.connectors.map((connector) => (
                          <tr className="subtable-tr" key={connector.id}>
                            <td className="subtable-td" colSpan={2}></td>
                            <td className="subtable-td">
                              {connector.connectorName}
                            </td>

                            <td className="subtable-td ">
                              <div
                                className={` ${
                                  connector.connectorStatus === "Available"
                                    ? "available-roaming-green"
                                    : connector.connectorStatus === "Broken"
                                    ? "offline-roaming-red"
                                    : "in-use-roaming-blue"
                                } connector-status-container-charger`}
                              >
                                {connector.connectorStatus}
                              </div>
                            </td>
                            <td className="subtable-td">
                              {connector.connectorType}
                            </td>
                            <td className="subtable-td">
                              {connector.powerType}
                            </td>
                            <td className="subtable-td">{connector.evseId}</td>
                            <td
                              className="text-[12px] font-medium text-[#183DB0] cursor-pointer underline"
                              onClick={() =>
                                downloadQRCode(
                                  connector.qrString,
                                  connector.evseId
                                )
                              }
                            >
                              Download
                            </td>
                            <td className="subtable-td">Published</td>
                            <td className="subtable-td">{connector.note}</td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td className="subtable-td" colSpan={10}>
                            No connectors available for this charger.
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              )}
            </td>
          </tr>
        ))}
      </Table>

      <Paging
        tableSize={data.length}
        pageChangeFunc={(page, perPage) =>
          setPaging({ page: page, perPage: perPage })
        }
      />
    </div>
  );
};

export default PublishedEVSETable;

const tableSeedHeaders: string[] = [
  "Charger ID",
  "Location Name",
  "Market",
  "Published connectors",
  "Charger information",
];
const tableSeedRowOrder: string[] = [
  "ocppChargerId",
  "locationName",
  "companyMarketName",
  "publishedConnectors",
  "description",
];
