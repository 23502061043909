import { useState } from "react";
import "./AvailabilityUnlockModal.css";
import ModalTable from "./ModalTable";
import { IConnectorInCharger } from "../../../../interfaces/IConnector";

interface Props {
  showModal: Function;
  type: "unlock" | "changeAvailability" | "";
  connectors: IConnectorInCharger[];
  chargerId: number;
  handleConfirm: (connectorId: number, availability?: 0 | 1) => void
}

const AvailabilityUnlockModal = ({
  showModal,
  type,
  connectors,
  chargerId,
  handleConfirm,
}: Props) => {
  const [selected, setSelected] = useState<number>(0);
  const tableHeaders = ["Connector ID", "Connector Type", "Electric Current"];
  const rowOrder = ["ocppChargerId", "connectorType", "electricCurrent"];
  const [availableToggle, setAvailableToggle] = useState<boolean>(true);

  const handleSelection = (id: number) => {
    setSelected(selected === id ? 0 : id);
  };


  return (
    <>
      <div className="modal-background" onClick={() => showModal(false)} />
      <div className="operations-modal" role="dialog" aria-modal="true">
        <div className="modal-content">
          <div className="heading">
            <p className="title">Do you want to {type === "changeAvailability" ? "change availabilty?" : "unlock charger?"}</p>
            {type === "changeAvailability" ? (
              <div className="w-full flex flex-row justify-between items-start">
                <p className="description">Select the connector ID</p>
                <div className="flex flex-row items-center justify-end gap-4 mt-3">
                  <div className="text-[14px] font-medium">Available</div>
                  <label className="relative inline-flex items-end cursor-pointer">
                    <input
                      type="checkbox"
                      className="sr-only peer"
                      checked={availableToggle}
                      onChange={(e) => {
                        e.stopPropagation();
                        setAvailableToggle((prev) => !prev);
                      }}
                    />
                    <div className="w-[60px] h-[30px] bg-[#B5BAC6] peer-focus:outline-none rounded-full peer peer-checked:bg-[#1E4CDC] after:content-[''] after:absolute after:top-[3px] after:left-[4.4px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-[24px] after:w-[24px] after:transition-all after:duration-500 peer-checked:after:translate-x-[28px]"></div>
                  </label>
                </div>
              </div>
            ) : (
              <p className="description">Select the connector ID</p>
            )}
            <div className="h-[2.5px] bg-[#E7E9ED] w-[100%] mt-2"></div>
          </div>
          <ModalTable
            tableHeaders={tableHeaders}
            tableRowOrder={rowOrder}
            handleSelection={(id) => handleSelection(id)}
            selected={selected}
            tableRows={connectors}
          />
        </div>
        <div className="modal-btns">
          <button
            className="rounded-bl-[10px] close-button-border"
            onClick={() => showModal(false)}
          >
            Close
          </button>
          <button
            className={
              selected
                ? "confirm-selected rounded-br-[10px]"
                : "rounded-br-[10px]"
            }
            disabled={!selected}
            onClick={() => {
              const availability = type === 'unlock' ? undefined : (availableToggle ? 1 : 0);
              handleConfirm(selected, availability);
            }}
          >
            Confirm
          </button>
        </div>
      </div>
    </>
  );
};

export default AvailabilityUnlockModal;
