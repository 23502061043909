import React, { useState, useEffect } from "react";
import { useStore } from "react-stores";
import {
  HistoryStore,
  handleGetBillingHistory,
  handleGetInvoiceBySessionId,
} from "../../../stores/historyStore";
import "./BillingHistory.css";
import Table from "../../../shared/table/Table";
import Paging from "../../../shared/paging/Paging";
import MarketDropdown from "../../../shared/marketDropdown/MarketDropdown";
import {
  ILocationDropdown,
  IMarketDropdown,
} from "../../../interfaces/IMarket";
import AdvancedFilter from "../../../shared/advancedFilter/AdvancedFilter";
import { IChargerDropdown } from "../../../interfaces/ICharger";
import Spinner from "../../../shared/spinner/Spinner";
import { useToast } from "../../../shared/toast/ToastContent";

const BillingHistory = () => {
  const [selected, setSelected] = useState(0);
  const [paging, setPaging] = useState({ page: 1, perPage: 10 });

  const [selectedFilters, setSelectedFilters] = useState({
    markets: [] as IMarketDropdown[],
    locations: [] as ILocationDropdown[],
    chargers: [] as IChargerDropdown[],
  });
  const [sort, setSort] = useState({ field: "", descending: true });
  const [loading, setLoading] = useState(false);
  const { showToast } = useToast();

  const historyStore = useStore(HistoryStore);
  const { billingHistories, billingHistoriesCount } = historyStore;

  const tableHeaders = [
    "Transaction ID",
    "Stop Time",
    "Connector ID",
    "Electricity Consumed",
    "Billed Amount",
    "Market",
    "Location Name",
  ];

  const tableRow = [
    "id",
    "timeStop",
    "connectorName",
    "electricityConsumed",
    "amount",
    "companyMarketName",
    "locationName",
  ];

  useEffect(() => {
    if (selectedFilters.markets?.length > 0) {
      handleGetBillingHistory(
        selectedFilters.markets.map((market) => market.id),
        selectedFilters.locations.map((location) => location.id),
        selectedFilters.chargers.map((charger) => charger.id),
        paging.page,
        paging.perPage,
        sort.field,
        sort.descending
      );
    }
  }, [paging, selectedFilters, sort]);

  const handleSelection = (id) => {
    setSelected(selected === id ? 0 : id);
  };

  const handleSort = async (sortingField, sortingMethod) => {
    if (sortingMethod === "default") {
      setSort({ field: "", descending: true });
    } else {
      setSort({
        field: sortingField,
        descending: sortingMethod === "descending" ? true : false,
      });
    }
  };

  const handleInvoice = async () => {
    setLoading(true);
    const pdf = await handleGetInvoiceBySessionId(selected);
    if (pdf) {
      const linkSource = `data:application/pdf;base64,${pdf}`;
      const downloadLink = document.createElement("a");
      const fileName = `invoice-${selected}.pdf`;
      downloadLink.href = linkSource;
      downloadLink.download = fileName;
      setLoading(false);
      downloadLink.click();
    } else {
      setLoading(false);
      showToast("Error while fetching invoice", "error");
    }
  };

  return (
    <div className="flex-1 p-3.5 flex flex-col gap-3.5 items-stretch main-wrapper">
      <div className="bg-white card p-5">
        <div className="flex flex-row justify-between items-center header flex-wrap">
          <div className="charging-sessions-history">
            <span>
              <span className="charging-sessions-history-span">
                Commercial/
              </span>
              <span className="charging-sessions-history-span2">
                Billing History
              </span>
            </span>
          </div>
          <div className="flex flex-row gap-5 flex-wrap">
            <AdvancedFilter
              handleSelection={(newMarkets) => {
                setSelectedFilters(newMarkets);
                setPaging((prev) => ({ page: 1, perPage: prev.perPage }));
              }}
            />

            <button
              className={`invoiceButton ${selected ? "invoiceButtonActive" : ""
                } flex flex-row gap-3 items-center justify-center`}
              disabled={!selected}
              onClick={handleInvoice}
            >
              <img
                src={
                  selected
                    ? "/icons/download/download-gray.svg"
                    : "/icons/download/download-darkblue.svg"
                }
                alt=""
              />
              Invoice
            </button>
          </div>
        </div>
      </div>
      <div className="table-container p-5">
        <Table
          tableHeaders={tableHeaders}
          tableRowOrder={tableRow}
          tableRows={billingHistories}
          handleSelection={(id) => handleSelection(id)}
          selected={selected}
          sortColumns={[
            "Stop Time",
            "Transaction ID",
            "Connector ID",
            "Electricity Consumed",
            "Billed Amount",
            "Market",
            'Location Name'
          ]}
          handleSort={(sortingField, sortingMethod) =>
            handleSort(sortingField, sortingMethod)
          }
          preloadRowNumber={10}
        />
        <Paging
          tableSize={billingHistoriesCount}
          pageChangeFunc={(page, perPage) =>
            setPaging({ page: page, perPage: perPage })
          }
        />
      </div>
      {loading && <Spinner />}
    </div>
  );
};

export default BillingHistory;
