// import React, { useState } from "react";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css";
import "./DateSelectRange.css";
import { useState } from "react";
import "react-calendar/dist/Calendar.css";
import Calendar from "react-calendar";
import { enUS } from "date-fns/locale";
import moment from "moment/moment";

interface DateRange {
  startDate: Date | null;
  endDate: Date | null;
}

interface Props {
  showCalendar: Function;
  start: string
  end: string
  handleApply: Function
}

const BackupCalendar: React.FC<Props> = ({ showCalendar, start, end, handleApply }) => {
  const [selectionRange, setSelectionRange] = useState<DateRange>({
    startDate: start ? new Date(start) : null,
    endDate: end ? new Date(end) : null,
  });
  const [activeButton, setActiveButton] = useState<string>("");

  const handleDateChange = (range) => {
    const [startDate, endDate] = range;
    setSelectionRange({ startDate, endDate });
  };

  const handleTodayClick = () => {
    const today = new Date();
    setSelectionRange({ startDate: today, endDate: today });
    setActiveButton("Today");
  };

  //hehe
  const handleYesterdayClick = () => {
    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);
    setSelectionRange({ startDate: yesterday, endDate: yesterday });
    setActiveButton("Yesterday");
  };
  const handleThisMonthClick = () => {
    const start = new Date(new Date().getFullYear(), new Date().getMonth(), 1);
    const end = new Date(
      new Date().getFullYear(),
      new Date().getMonth(),
      new Date().getDate()
    );
    setSelectionRange({ startDate: start, endDate: end });
    setActiveButton("This month");
  };
  const handleThisYearClick = () => {
    const startDate = new Date(new Date().getFullYear(), 0, 1);
    const endDate = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate());
    setSelectionRange({ startDate, endDate });
  };

  const handlePastMonthClick = () => {
    const startDate = new Date(
      new Date().getFullYear(),
      new Date().getMonth() - 1,
      1
    );
    const endDate = new Date(
      new Date().getFullYear(),
      new Date().getMonth(),
      0
    );
    setSelectionRange({ startDate, endDate });
  };

  const handlePast3MonthsClick = () => {
    const today = new Date();
    const startDate = new Date(today.getFullYear(), today.getMonth() - 2, 1);
    const endDate = new Date(today.getFullYear(), new Date().getMonth(), new Date().getDate());

    setSelectionRange({ startDate, endDate });
  };

  const tileClassName = ({ date, view }) => {
    if (view === "month") {
      const { startDate, endDate } = selectionRange;
      if (date >= startDate! && date <= endDate!) {
        return "react-calendar__tile--active";
      }
    }
    return null;
  };

  const handleResetClick = () => {
    setSelectionRange({ startDate: null, endDate: null });
    setActiveButton("");
  };

  const handleApplyClick = () => {
    const { startDate, endDate } = selectionRange;
    if (startDate && endDate) {
      let formattedStartDate = startDate.toISOString();
      let formattedEndDate = endDate.toISOString();
      console.log("Start Date:", formattedStartDate);
      console.log("End Date:", formattedEndDate);
      showCalendar(false);
      handleApply(formattedStartDate, formattedEndDate);
    }
  };

  return (
    <>
      <div className="calendar-modal-background" onClick={() => showCalendar(false)} />
      <div className="modal" role="dialog" aria-modal="true">
        <div style={{ display: "flex", padding: "5px" }}>
          <Calendar
            locale="en-UK"
            className="calendar"
            tileClassName={tileClassName}
            next2Label={null}
            prev2Label={null}
            selectRange={true}
            onChange={handleDateChange}
            value={[selectionRange.startDate, selectionRange.endDate]}
          />
        </div>
        <div className="line-item-divider" />
        <div className="calendar-btn-container">
          <div className="col">
            <button
              className={`calendar-btn ${activeButton === "Today" ? "focus" : ""
                }`}
              onClick={handleTodayClick}
            >
              Today
            </button>
            <button
              className={`calendar-btn ${activeButton === "Yesterday" ? "focus" : ""
                }`}
              onClick={handleYesterdayClick}
            >
              Yesterday
            </button>
            <button
              className={`calendar-btn ${activeButton === "This month" ? "focus" : ""
                }`}
              onClick={handleThisMonthClick}
            >
              This month
            </button>
          </div>
          <div className="col">
            <button className="calendar-btn" onClick={handlePastMonthClick}>
              Past month
            </button>
            <button className="calendar-btn" onClick={handlePast3MonthsClick}>
              Past 3 months
            </button>
            <button className="calendar-btn" onClick={handleThisYearClick}>
              This year
            </button>
          </div>
        </div>
        <div className="line-item-divider" />
        <div className="flex flex-row justify-center items-center w-[100%] p-2">
          <button className="resetButtonRdr mr-5" onClick={handleResetClick}>
            Reset
          </button>
          <button className="saveButtonRdr" onClick={handleApplyClick}>
            Apply
          </button>
        </div>
      </div>
    </>
  );
};

export default BackupCalendar;
