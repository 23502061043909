import Chart, { CategoryScale } from "chart.js/auto";
import { Line } from "react-chartjs-2";
import { ThreeDots } from "../../../shared/threeDots/ThreeDots";
import { DashboardStore } from "../../../stores/dashboardStore";
import { useStore } from "react-stores";
import { downloadCSV } from "../../../helpers/csvHelper";
import { useState } from "react";

const UtilizationChart = ({ xValues, yTime, yCapacity }) => {
  const { timeUtilization, capacityUtilization } = useStore(DashboardStore);
  const [stepSize, setStepSize] = useState<number>(0);

  Chart.register(CategoryScale);
  const threeDotstext = () => (
    <div className="flex flex-row justify-between items-center gap-4">
      <p className="threedots-text">Download data in .csv</p>
      <img src="/icons/download/download-blue.svg" alt="" />
    </div>
  );

  const data = {
    labels: xValues,
    datasets: [
      {
        data: yCapacity,
        borderColor: "#1C1C1C",
        backgroundColor: "#1C1C1C",
        pointBackgroundColor: "white",
        pointBorderColor: "#1C1C1C",
        pointBorderWidth: 2,
        pointRadius: 4.5,
        pointHoverRadius: 6.5,
        pointHoverBackgroundColor: 'white',
        pointHoverBorderColor: "#1C1C1C",
        pointHoverBorderWidth: 2,
        parsing: {
          yAxisKey: 'value',
          xAxisKey: 'middle',
        },
      },
      {
        data: yTime,
        borderColor: "#B9C8F4",
        pointBackgroundColor: "white",
        pointBorderColor: "#B9C8F4",
        pointBorderWidth: 2,
        pointRadius: 4.5,
        pointHoverRadius: 6.5,
        pointHoverBackgroundColor: 'white',
        pointHoverBorderColor: "#1E4CDC",
        pointHoverBorderWidth: 2,
        parsing: {
          yAxisKey: 'value',
          xAxisKey: 'middle',
        },
      },
    ],
  };

  const options = {
    maintainAspectRatio: false,
    responsive: true,
    scales: {
      x: {
        grid: {
          display: false,
        },
        offset: true,
        ticks: {
          font: {
            family: 'Inter',
          },
          color: "#B5BAC6",
          maxTicksLimit: 12,
          maxRotation: 0,
          minRotation: 0
        },
      },
      y: {
        grid: {
          display: true,
          color: "#F4F4F4",
          lineWidth: 1,
        },
        ticks: {
          font: {
            family: 'Inter',
          },
          color: "#B5BAC6",
          maxTicksLimit: 8,
          maxRotation: 0,
          minRotation: 0
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        backgroundColor: "#E9EDFC",
        borderColor: 'white',
        borderWidth: 2,
        titleColor: '#111F47',
        titleAlign: 'center' as const,
        titleFont: {
          family: 'Inter',
          size: 11,
        },
        bodyColor: '#111F47',
        bodyFont: {
          family: 'Inter',
          size: 11,
        },
        displayColors: false,
        cursor: "pointer",
        padding: {
          top: 6,
          bottom: 6,
          left: 8,
          right: 8,
        },
        callbacks: {
          title: function (tooltipItem) {
            // Remove the title
            return `${tooltipItem[0].raw.value.toFixed(2)} %`;
          },
          label: function (tooltipItem) {
            const { dataset, dataIndex } = tooltipItem;
            // Customize the label of the tooltip
            return `${dataset.data[dataIndex].from} - ${dataset.data[dataIndex].to}`;
          },
        },
      },
    },
    onHover: (event, activeElements) => {
      (event?.native?.target as HTMLElement).style.cursor =
        activeElements?.length > 0 ? "pointer" : "auto";
    },
  };

  const handleGetUtilizationCSV = () => {
    const formattedData = yTime.map((timePoint, index) => {
      const capacityPoint = yCapacity[index];
      return {
        fromDate: timePoint.from,
        toDate: timePoint.to,
        timeUtilization: timePoint.value.toFixed(2),
        capacityUtilization: capacityPoint.value.toFixed(2),
      };
    });
    downloadCSV(formattedData, "utilization");
  };

  return (
    <>
      <div className="flex flex-row justify-between items-center w-[100%] relative pr-[50px]">
        <div className="flex flex-row justify-start items-center gap-1">
          <div className="chart-title">Utilization,</div>
          <p>%</p>
        </div>
        <div className="flex flex-row justify-start items-center gap-10">
          <div className="flex flex-row gap-2 justify-start items-center">
            <div className="blue-dot" />
            <div className="chart-legend">Time Utilization</div>
          </div>
          <div className="absolute top-0 right-0">
            <ThreeDots
              actions={[
                {
                  text: threeDotstext(),
                  handleClick: handleGetUtilizationCSV,
                },
              ]}
            />
          </div>
          <div className="flex flex-row gap-2 justify-start items-center">
            <div className="black-dot" />
            <div className="chart-legend">Capacity Utilization</div>
          </div>
        </div>
      </div>
      <Line data={data} options={options} className="w-[100%]" />
    </>
  );
};

export default UtilizationChart;
