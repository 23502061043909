import { Store } from "react-stores";
import { IHistoryStore } from "../interfaces/IStores";
import { AuthStore } from "./authStore";
import {
  convertToLocalTime,
  extractTime,
  formatDate,
  formatDateLocale,
  formatTime,
  getFormattedQueryString,
  getMarketsToformattedString,
} from "../helpers/dataHelper";

const PUBLIC_BASE_URL = process.env.REACT_APP_BASE_URL;

export const HistoryStore = new Store<IHistoryStore>({
  sessionHistories: [],
  sessionHistoriesCount: 0,
  billingHistories: [],
  billingHistoriesCount: 0,
});

export const handleGetHistorySessions = async (
  companyMarketIds: number[],
  locationIds: number[] = [],
  chargerIds: number[] = [],
  pageIdx: number = 1,
  pageSize: number = 10,
  startDate: string = "",
  endDate: string = "",
  searched: string = "",
  sortingField: string = "",
  sortingDescending: boolean = true
) => {
  try {
    let jwtString: string | null = AuthStore.state.jwt;
    if (!jwtString) jwtString = sessionStorage.getItem("jwt");
    const myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + jwtString);

    const queryString = getFormattedQueryString({
      CompanyMarketIds: companyMarketIds,
      LocationIds: locationIds,
      ChargerIds: chargerIds,
    });

    const response = await fetch(
      `${PUBLIC_BASE_URL}api/v1.1/B2B/Sessions/GetHistorySessionsPaginated${queryString}&pageIndex=${pageIdx}&pageSize=${pageSize}${
        startDate ? "&dateStartFrom=" + startDate : ""
      }${endDate ? "&DateStartTo=" + endDate : ""}${
        searched ? "&keyword=" + searched : ""
      }${
        sortingField
          ? `&SortingCriteria.Field=${sortingField}&SortingCriteria.Descending=${sortingDescending}`
          : `&SortingCriteria.Field=timeStart&SortingCriteria.Descending=${sortingDescending}`
      }`,
      {
        method: "GET",
        headers: myHeaders,
      }
    );
    const data = await response.json();
    data.data.forEach((el) => {
      el.timeStart =
        formatDateLocale(el.timeStart) + " " + extractTime(el.timeStart);
      el.timeStop =
        formatDateLocale(el.timeStop) + " " + extractTime(el.timeStop);
      el.duration = formatTime(el.duration);
      el.electricityConsumed =
        String((Math.round(el.electricityConsumed * 100) / 100).toFixed(2)) +
        " kWh";
      el.totalPrice =
        String((Math.round(el.totalPrice * 100) / 100).toFixed(2)) +
        " " +
        el.currencyISO;
    });
    HistoryStore.setState({
      ...HistoryStore.state,
      sessionHistories: data.data,
      sessionHistoriesCount: data.totalCount,
    });
    // sessionHistories.set(data.data);
    // sessionHistoriesCount.set(data.totalCount);
  } catch (e) {
    console.log("Error: ", e);
  }
};

export const handleGetBillingHistory = async (
  companyMarketIds: number[],
  locationIds: number[] = [],
  chargerIds: number[] = [],
  pageIdx: number = 1,
  pageSize: number = 10,
  sortingField: string = "",
  sortingDescending: boolean = true
) => {
  try {
    let jwtString: string | null = AuthStore.state.jwt;
    if (!jwtString) jwtString = sessionStorage.getItem("jwt");
    const myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + jwtString);

    const queryString = getFormattedQueryString({
      CompanyMarketIds: companyMarketIds,
      LocationIds: locationIds,
      ChargerIds: chargerIds,
    });

    const response = await fetch(
      `${PUBLIC_BASE_URL}api/v1.1/B2B/Sessions/GetBillingHistoryPaginated${queryString}&pageIndex=${pageIdx}&pageSize=${pageSize}${
        sortingField
          ? `&SortingCriteria.Field=${sortingField}&SortingCriteria.Descending=${sortingDescending}`
          : `&SortingCriteria.Field=timeStop&SortingCriteria.Descending=${sortingDescending}`
      }`,
      {
        method: "GET",
        headers: myHeaders,
      }
    );
    const data = await response.json();
    data.data.forEach((el) => {
      el.electricityConsumed =
        String((Math.round(el.electricityConsumed * 100) / 100).toFixed(2)) +
        " kWh";
      el.amount =
        String((Math.round(el.amount * 100) / 100).toFixed(2)) +
        " " +
        el.currencyISO;
      el.timeStop =
        formatDateLocale(el.timeStop) + " " + extractTime(el.timeStop);
    });
    HistoryStore.setState({
      ...HistoryStore.state,
      billingHistories: data.data,
      billingHistoriesCount: data.totalCount,
    });
  } catch (e) {
    console.log("Error: ", e);
  }
};

export const handleGetInvoiceBySessionId = async (sessionId: number) => {
  try {
    let jwtString: string | null = AuthStore.state.jwt;
    if (!jwtString) jwtString = sessionStorage.getItem("jwt");
    const myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + jwtString);

    const response = await fetch(
      `${PUBLIC_BASE_URL}api/v1.1/B2B/Payments/GetInvoiceBySessionId?sessionId=${sessionId}`,
      {
        method: "GET",
        headers: myHeaders,
      }
    );
    if (response.ok) {
      const data = await response.json();
      return data.invoiceBase64PDF;
    } else {
      return null;
    }
  } catch (e) {
    console.log("Error: ", e);
    return null;
  }
};
