export const MAPBOX_ACCESS_TOKEN =
  "pk.eyJ1IjoiZHVqbWExNjA4IiwiYSI6ImNscnlxZTRvMzFtaHYyaW11ZDQ1cTRhczYifQ.NNWHqyeKHUH06Pw4-EegTQ";

export const MAP_URL = `https://api.mapbox.com/styles/v1/dujma1608/clx4nwfr4001101pj2bsldsxo/tiles/{z}/{x}/{y}?access_token=${MAPBOX_ACCESS_TOKEN}`;

// export const fetchCoordinates = async (address: string) => {
//   try {
//     const response = await fetch(
//       `https://api.mapbox.com/geocoding/v5/mapbox.places/${encodeURIComponent(
//         address
//       )}.json?access_token=${MAPBOX_ACCESS_TOKEN}`
//     );
//     const data = await response.json();
//     if (data.features && data.features.length > 0) {
//       const coordinates = data.features[0]?.geometry?.coordinates;
//       if (coordinates && coordinates.length >= 2) {
//         const lon = coordinates[0];
//         const lat = coordinates[1];
//         return { lat, lon };
//       }
//     }
//     throw new Error("Coordinates not found for the address");
//   } catch (error) {
//     console.error("Error fetching coordinates:", error);
//     return null;
//   }
// };

export const fetchCoordinatesOnlyLatLon = async (address: string) => {
  try {
    const response = await fetch(
      `https://api.mapbox.com/geocoding/v5/mapbox.places/${encodeURIComponent(
        address
      )}.json?access_token=${MAPBOX_ACCESS_TOKEN}`
    );
    const data = await response.json();
    if (data.features && data.features.length > 0) {
      const [longitude, latitude] = data.features[0].center;
      return { latitude, longitude };
    }
    return null;
  } catch (error) {
    console.error("Error fetching coordinates:", error);
    return null;
  }
};

export const fetchCoordinates = async (address: string) => {
  try {
    const response = await fetch(
      `https://nominatim.openstreetmap.org/search?q=${encodeURIComponent(
        address
      )}&format=json&addressdetails=1&bounded=1&viewbox=10.0,55.3,25.26,35.5`
    );
    const data = await response.json();

    if (data.length > 0) {
      const firstResult = data[0];
      const address = firstResult.address;
      const street = address.road || "";
      const postalNumber = address.postcode || "";
      const city = address.city || address.town || address.village || "";
      const latitude = parseFloat(firstResult.lat);
      const longitude = parseFloat(firstResult.lon);

      return { street, postalNumber, city, latitude, longitude };
    }

    throw new Error("Address components not found");
  } catch (error) {
    console.error("Error fetching coordinates:", error);
    return null;
  }
};
