import { useState } from "react";
import { useStore } from "react-stores";
import "./Roaming.css";
import Table from "../../shared/table/Table";
import Paging from "../../shared/paging/Paging";
import { RoamingStore } from "../../stores/roamingStore";
import { ICompanyMarketRoaming } from "../../interfaces/IRoaming";

interface PartnersTableProps {
  data: ICompanyMarketRoaming[];
  selected: number;
  handleSelection: (id: number) => void;
  setPaging: (params: { page: number; perPage: number }) => void;
  setSorting: (params: { field: string; descending: boolean }) => void;
}

const PartnersTable = ({
  selected,
  handleSelection,
  data,
  setPaging,
  setSorting,
}: PartnersTableProps) => {
  const { paginatedCount } = useStore(RoamingStore);

  const handleSort = async (sortingField, sortingMethod) => {
    if (sortingMethod === "default") {
      setSorting({ field: "", descending: true });
    } else {
      setSorting({
        field: sortingField,
        descending: sortingMethod === "descending" ? true : false,
      });
    }
  };
  const partnersRowsStyling =
    data &&
    data.map((row) => {
      if (row.registrationStatus === "Registered")
        return { registrationStatus: "green-text" };
      else if (row.registrationStatus === "RequestSent")
        return { registrationStatus: "orange-text" };
      else return { registrationStatus: "red-text" };
    });

  const allUnregistered =
    data && data.every((row) => row.registrationStatus === "Unregistered");

  return (
    <>
      {allUnregistered && (
        <div className="p-4 text-[#111F47] bg-[#E9EDFC] text-[15px] font-semibold">
          None of your company markets are registered for roaming. To register,
          select one from the list below and send a registration request to our
          team!
        </div>
      )}
      <Table
        tableHeaders={tableHeaders}
        tableRowOrder={tableRowOrder}
        tableRows={data || []}
        selected={selected}
        sortColumns={tableHeaders}
        handleSort={(sortingField, sortingMethod) =>
          handleSort(sortingField, sortingMethod)
        }
        handleSelection={(id) => {
          data &&
          data.find((el) => el.id === id)?.registrationStatus !== "Unregistered"
            ? handleSelection(0)
            : handleSelection(id);
        }}
        preloadRowNumber={6}
        rowsStyling={partnersRowsStyling}
      />
      <Paging
        tableSize={paginatedCount}
        pageChangeFunc={(page, perPage) => setPaging({ page, perPage })}
      />
    </>
  );
};

export default PartnersTable;

const tableHeaders = [
  "Company Market",
  "Country",
  "Company",
  "Address",
  "Registration Status",
];

const tableRowOrder = [
  "companyMarketName",
  "country",
  "companyName",
  "address",
  "registrationStatus",
];
