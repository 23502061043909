import { transliterate } from "transliteration";

export const fetchAddressSuggestions = async (
  query: string,
  accessToken: string
) => {
  if (query.length <= 3) return [];

  try {
    const response = await fetch(
      `https://api.mapbox.com/geocoding/v5/mapbox.places/${query}.json?access_token=${accessToken}&autocomplete=true&proximity=18.29,43.29&bbox=12.29,41.29,21.89,47.09`
    );

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const data = await response.json();
    const options = data.features.slice(0, 3).map((feature: any) => ({
      value: feature.place_name,
      label: transliterate(feature.place_name),
    }));

    return options;
  } catch (error) {
    console.error("Fetching suggestions failed:", error);
    return [];
  }
};
