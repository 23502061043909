import { Store } from "react-stores";
import { IUserStore } from "../interfaces/IStores";
import { AuthStore } from "./authStore";
import { downloadCSV } from "../helpers/csvHelper";
import { duration } from "moment";
import { extractTime, formatDateLocale, formatTime } from "../helpers/dataHelper";

const PUBLIC_BASE_URL = process.env.REACT_APP_BASE_URL;

export const UserStore = new Store<IUserStore>({
  users: [],
  usersCount: 0,
  revenue: null,
  sessionsCSV: [],
});

export const GetUsers = async (
  currencyISO: string,
  dateFrom: string,
  dateTo: string,
  companyMarketIds: number[],
  pageIndex: number,
  pageSize: number,
  sortingField?: string | null,
  sortingDescending?: boolean | null
) => {
  try {
    let jwtString: string | null = AuthStore.state.jwt;
    if (!jwtString) jwtString = sessionStorage.getItem("jwt");
    const myHeaders = new Headers();

    let marketsString = "?";
    companyMarketIds.forEach(
      (market) =>
        (marketsString += `CompanyMarketsFilterDto.CompanyMarketIds=${market}&`)
    );
    marketsString = marketsString.slice(0, -1);
    myHeaders.append("Authorization", "Bearer " + jwtString);

    // Helper function to format the date as YYYY-MM-DD
    const formatDate = (dateString: string) => {
      const date = new Date(dateString);
      const day = ("0" + date.getDate()).slice(-2);
      const month = ("0" + (date.getMonth() + 1)).slice(-2);
      const year = date.getFullYear();
      return `${year}-${month}-${day}`;
    };

    const url = new URL(
      `${PUBLIC_BASE_URL}api/v1.1/B2B/Users/GetUsers${marketsString}`
    );
    const params = {
      CurrencyISO: currencyISO,
      "DateFilter.from": dateFrom ? formatDate(dateFrom) : null,
      "DateFilter.to": dateTo ? formatDate(dateTo) : null,
      "SortingCriteria.Field": sortingField,
      "SortingCriteria.Descending": sortingDescending?.toString(),
      PageIndex: pageIndex.toString(),
      PageSize: pageSize.toString(),
    };

    Object.keys(params).forEach((key) => {
      if (params[key] !== null) {
        url.searchParams.append(key, params[key]);
      }
    });

    const response = await fetch(url.toString(), {
      method: "GET",
      headers: myHeaders,
    });
    const data = await response.json();

    // Format totalSpend and averageSpend to 2 decimal places if they are not zero
    const formattedData = data.data.map((user: any) => ({
      ...user,
      totalSpend: String((Math.round(user.totalSpend * 100) / 100).toFixed(2)) + ' ' + currencyISO,
      averageSpend: String((Math.round(user.averageSpend * 100) / 100).toFixed(2)) + ' ' + currencyISO,
      lastActivity: user.lastActivity ? formatDateLocale(user.lastActivity) + " " + extractTime(user.lastActivity) : 'N/A',
    }));

    UserStore.setState({
      ...UserStore.state,
      users: formattedData,
      usersCount: data.totalCount,
    });
  } catch (e) {
    console.log("Error: ", e);
  }
};

const formatDataDate = (dateString) => {
  const date = new Date(dateString);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-indexed
  const day = String(date.getDate()).padStart(2, "0");
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");
  const seconds = String(date.getSeconds()).padStart(2, "0");

  return `${year}/${month}/${day} ${hours}:${minutes}:${seconds}`;
};

export const GetUserSessions = async (
  id: number,
  companyMarketIds: number[],
  currencyISO?: string,
  dateFrom?: string,
  dateTo?: string
) => {
  try {
    let jwtString: string | null = AuthStore.state.jwt;
    if (!jwtString) jwtString = sessionStorage.getItem("jwt");
    const myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + jwtString);

    const url = new URL(
      `${PUBLIC_BASE_URL}api/v1.1/B2B/Users/GetSessionsByUserId`
    );
    url.searchParams.append("userId", id.toString());

    const formatDate = (dateString: string) => {
      const date = new Date(dateString);
      const day = ("0" + date.getDate()).slice(-2);
      const month = ("0" + (date.getMonth() + 1)).slice(-2);
      const year = date.getFullYear();
      return `${year}-${month}-${day}`;
    };

    if (currencyISO) {
      url.searchParams.append("CurrencyISO", currencyISO);
    }
    if (dateFrom) {
      url.searchParams.append("DateFilter.from", formatDate(dateFrom));
    }
    if (dateTo) {
      url.searchParams.append("DateFilter.to", formatDate(dateTo));
    }
    companyMarketIds.forEach((id) => {
      url.searchParams.append("CompanyMarketIds", id.toString());
    });

    const response = await fetch(url.toString(), {
      method: "GET",
      headers: myHeaders,
    });
    const data = await response.json();

    const formattedData = data.map((session) =>
      session.timeStop
        ? {
          ...session,
          timeStart: formatDataDate(session.timeStart),
          timeStop: formatDataDate(session.timeStop),
          electricityConsumed: session.electricityConsumed.toFixed(2),
          totalPrice: session.totalPrice.toFixed(2) + " " + currencyISO,
        }
        : {
          ...session,
          timeStart: formatDataDate(session.timeStart),
          timeStop: "N/A",
          duration: formatTime(session.duration),
          electricityConsumed: Number(0).toFixed(2),
          totalPrice: session.totalPrice
            ? session.totalPrice.toFixed(2) + " " + currencyISO
            : "N/A",
        }
    );

    return formattedData;
  } catch (e) {
    console.log("Error: ", e);
  }
};

export const GetUserRevenue = async (
  currencyISO: string,
  dateFrom: string,
  dateTo: string,
  companyMarketIds: number[]
) => {
  try {
    let jwtString: string | null = AuthStore.state.jwt;
    if (!jwtString) jwtString = sessionStorage.getItem("jwt");
    const myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + jwtString);

    const formatDate = (dateString: string) => {
      const date = new Date(dateString);
      const day = ("0" + date.getDate()).slice(-2);
      const month = ("0" + (date.getMonth() + 1)).slice(-2);
      const year = date.getFullYear();
      return `${year}-${month}-${day}`;
    };

    const params = new URLSearchParams();
    params.append("CurrencyISO", currencyISO);
    if (dateFrom) params.append("DateFilter.from", formatDate(dateFrom));
    if (dateTo) params.append("DateFilter.to", formatDate(dateTo));
    companyMarketIds.forEach((id) => {
      params.append("CompanyMarketIds", id.toString());
    });

    const url = new URL(`${PUBLIC_BASE_URL}api/v1.1/B2B/Users/GetUserRevenues`);
    url.search = params.toString();

    const response = await fetch(url.toString(), {
      method: "GET",
      headers: myHeaders,
    });
    const data = await response.json();

    UserStore.setState({
      ...UserStore.state,
      revenue: data,
    });
  } catch (e) {
    console.log("Error: ", e);
  }
};

export const UpdateUserInfo = async (data) => {
  try {
    let jwtString: string | null = AuthStore.state.jwt;
    if (!jwtString) jwtString = sessionStorage.getItem("jwt");
    let response = await fetch(
      `${PUBLIC_BASE_URL}api/v1.1/B2B/Users/UpdateUsersName`,
      {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
          Accept: "text/plain",
          Authorization: "Bearer " + jwtString,
        },
      }
    );
    return response.ok;
  } catch (e) {
    console.log("Error: ", e);
  }
};

const formatMarkets = (markets: number[]): string => {
  let marketsString = "?";
  markets.forEach((market) => (marketsString += `CompanyMarketIds=${market}&`));
  marketsString = marketsString.slice(0, -1);
  return marketsString;
};

export const GetSessionsForCSV = async (
  companyMarketIds: number[],
  dateFrom?: string,
  dateTo?: string,
  keyword?: string,
  sortingField?: string,
  sortingDescending?: boolean
) => {
  try {
    let jwtString: string | null = AuthStore.state.jwt;
    if (!jwtString) jwtString = sessionStorage.getItem("jwt");
    const myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + jwtString);

    const url = new URL(
      `${PUBLIC_BASE_URL}api/v1.1/B2B/Sessions/GenerateSessionCsv`
    );

    const formatDate = (dateString: string) => {
      const date = new Date(dateString);
      return date.toISOString();
    };

    if (dateFrom) {
      url.searchParams.append("DateStartFrom", formatDate(dateFrom));
    }
    if (dateTo) {
      url.searchParams.append("DateStartTo", formatDate(dateTo));
    }
    if (keyword) {
      url.searchParams.append("Keyword", keyword);
    }
    if (sortingField) {
      url.searchParams.append("SortingCriteria.Field", sortingField);
    }
    if (sortingDescending !== undefined) {
      url.searchParams.append(
        "SortingCriteria.Descending",
        sortingDescending.toString()
      );
    }
    companyMarketIds.forEach((id) => {
      url.searchParams.append("CompanyMarketIds", id.toString());
    });

    const response = await fetch(url.toString(), {
      method: "GET",
      headers: myHeaders,
    });
    const data = await response.json();

    const formattedData = data.map((session) => ({
      ...session,
      timeStart: formatDateLocale(session.timeStart) + " " + extractTime(session.timeStart),
      timeStop: formatDateLocale(session.timeStop) + " " + extractTime(session.timeStop),
    }));

    downloadCSV(formattedData, `sessions${(dateFrom && dateTo) ? `-${formatDateLocale(dateFrom)}-${formatDateLocale(dateTo)}` : ''}`);

    UserStore.setState({
      ...UserStore.state,
      sessionsCSV: formattedData,
    });

    return formattedData;
  } catch (e) {
    console.log("Error: ", e);
  }
};
