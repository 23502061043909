import { Store } from "react-stores";
import { IPricingStore } from "../interfaces/IStores";
import { AuthStore } from "./authStore";
import { getConnectorsToformattedString, getMarketsToformattedString, getMarketsToformattedString2 } from "../helpers/dataHelper";

const PUBLIC_BASE_URL = process.env.REACT_APP_BASE_URL;

export const PricingStore = new Store<IPricingStore>({
  unassignedConnectors: [],
  unassignedConnectorsSize: 0,
  connectorsNotInPricingGroup: [],
  connectorsNotInPricingGroupSize: 0,
  assignedConnectors: [],
  assignedConnectorsSize: 0,
  pricingGroups: [],
  pricingGroupsSize: 0,
  pricingGroupById: null,
});

export const handleGetConnectorsForMarket = async (
  marketId: number,
  pageIdx: number = 1,
  pageSize: number = 10,
  sortingField: string = "",
  sortingDescending: boolean = true) => {
  try {
    let jwtString: string | null = AuthStore.state.jwt;
    if (!jwtString) jwtString = sessionStorage.getItem("jwt");
    const myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + jwtString);
    let response = await fetch(
      `${PUBLIC_BASE_URL}api/v1.1/B2B/Connectors/GetConnectorsByCompanyMarketId?CompanyMarketId=${marketId}&PageIndex=${pageIdx}&PageSize=${pageSize}${sortingField ? `&SortingCriteria.Field=${sortingField}&SortingCriteria.Descending=${sortingDescending}` : ''}`,
      {
        method: "GET",
        headers: myHeaders,
      }
    );

    const data = await response.json();
    data.data.forEach(el => {
      el.street = `${el.street}, ${el.postalNumber}, ${el.city}`
    });
    PricingStore.setState({
      ...PricingStore.state,
      unassignedConnectors: data.data,
      unassignedConnectorsSize: data.totalCount,
    });
  } catch (e) {
    console.log("Error: ", e);
  }
};

export const handleGetPricingGroups = async (
  pageIdx: number = 1,
  pageSize: number = 10,
  sortingField: string = "",
  sortingDescending: boolean = true
) => {
  try {
    let jwtString: string | null = AuthStore.state.jwt;
    if (!jwtString) jwtString = sessionStorage.getItem("jwt");
    const myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + jwtString);
    let response = await fetch(
      `${PUBLIC_BASE_URL}api/v1.1/B2B/PricingGroups/GetPricingGroupsPaginated?pageIndex=${pageIdx}&pageSize=${pageSize}${sortingField ? `&SortingCriteria.Field=${sortingField}&SortingCriteria.Descending=${sortingDescending}` : ''}`,
      {
        method: "GET",
        headers: myHeaders,
      }
    );

    const data = await response.json();
    data.data.forEach(el => {
      el.priceAmount = el.priceAmount > 0 ? `${el.priceAmount.toFixed(2)} ${el.priceUnit}/kWh` : el.priceAmount.toFixed(2)
      el.parkingFeePrice = el.parkingFeePrice && el.gracePeriod ? `${el.parkingFeePrice.toFixed(2)} ${el.priceUnit}/min after ${el.gracePeriod} min` : 'N/A'
      el.startFee = el.startFee ? `${el.startFee.toFixed(2)} ${el.priceUnit}` : 'N/A'
    });
    PricingStore.setState({
      ...PricingStore.state,
      pricingGroups: data.data,
      pricingGroupsSize: data.totalCount,
    });
  } catch (e) {
    console.log("Error: ", e);
  }
};

export const handleGetPricingGroupById = async (id: number) => {
  try {
    let jwtString: string | null = AuthStore.state.jwt;
    if (!jwtString) jwtString = sessionStorage.getItem("jwt");
    const myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + jwtString);
    let response = await fetch(
      `${PUBLIC_BASE_URL}api/v1.1/B2B/PricingGroups/GetPricingGroupById/${id}`,
      {
        method: "GET",
        headers: myHeaders,
      }
    );

    let data = await response.json();
    data.priceAmount = Number((Math.round(data.priceAmount * 100) / 100).toFixed(2))
    PricingStore.setState({
      ...PricingStore.state,
      pricingGroupById: data,
    });
    return data;
    // pricingGroupById.set(data);
  } catch (e) {
    console.log("Error: ", e);
  }
};

export const handleUpdatePricingGroup = async (data) => {
  try {
    let jwtString: string | null = AuthStore.state.jwt;
    if (!jwtString) jwtString = sessionStorage.getItem("jwt");
    if (!jwtString) return false;
    const response = await fetch(
      `${PUBLIC_BASE_URL}api/v1.1/B2B/PricingGroups/UpdatePricingGroup`,
      {
        method: "PUT",
        body: data,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + jwtString,
        },
      }
    );
    return response.ok;
  } catch (e) {
    console.log("Error: ", e);
    return false;
  }
};

export const handlePricingGroupCreation = async (data) => {
  try {
    let jwtString: string | null = AuthStore.state.jwt;
    if (!jwtString) jwtString = sessionStorage.getItem("jwt");
    if (!jwtString) return false;
    const response = await fetch(
      `${PUBLIC_BASE_URL}api/v1.1/B2B/PricingGroups/CreatePricingGroup`,
      {
        method: "POST",
        body: data,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + jwtString,
        },
      }
    );
    return response.ok;
  } catch (e) {
    console.log("Error: ", e);
    return false;
  }
};

export const handleDeletePricingGroup = async (id: number) => {
  try {
    let jwtString: string | null = AuthStore.state.jwt;
    if (!jwtString) jwtString = sessionStorage.getItem("jwt");
    if (!jwtString) return false;
    const response = await fetch(
      `${PUBLIC_BASE_URL}api/v1.1/B2B/PricingGroups/DeletePricingGroup?PricingGroupId=${id}`,
      {
        method: "DELETE",
        headers: {
          accept: "application/json",
          Authorization: "Bearer " + jwtString,
        },
      }
    );
    return response.ok;
  } catch (e) {
    console.log("Error: ", e);
    return false;
  }
};

export const handleGetConnectorForPricingGroup = async (
  pricingGroupId: number,
  pageIdx: number = 1,
  pageSize: number = 10
) => {
  try {
    let jwtString: string | null = AuthStore.state.jwt;
    if (!jwtString) jwtString = sessionStorage.getItem("jwt");
    const myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + jwtString);
    let response = await fetch(
      `${PUBLIC_BASE_URL}api/v1.1/B2B/PricingGroups/GetConnectorsInPricingGroup/?PricingGroupId=${pricingGroupId}&pageIndex=${pageIdx}&pageSize=${pageSize}`,
      {
        method: "GET",
        headers: myHeaders,
      }
    );

    const data = await response.json();
    data.data.forEach((el => el.street = `${el.street}, ${el.postalNumber}, ${el.city}`))
    PricingStore.setState({
      ...PricingStore.state,
      assignedConnectors: data.data,
      assignedConnectorsSize: data.totalCount,
    });
  } catch (e) {
    console.log("Error: ", e);
  }
};

export const moveConnectorInPricingGroup = async (
  toPricingGroupId: number,
  connectors: number[]
) => {
  try {
    let jwtString: string | null = AuthStore.state.jwt;
    if (!jwtString) jwtString = sessionStorage.getItem("jwt");
    if (!jwtString) return false;

    let connectorsString = getConnectorsToformattedString(connectors)

    const response = await fetch(
      `${PUBLIC_BASE_URL}api/v1.1/B2B/PricingGroups/AddConnectorsToPricingGroup${connectorsString}&pricingGroupId=${toPricingGroupId}`,
      {
        method: "POST",
        headers: {
          Authorization: "Bearer " + jwtString,
        },
      }
    );
    return response.ok;
  } catch (e) {
    console.log("Error: ", e);
    return false;
  }
};

export const handleGetPricingGroupsForMoving = async (pricingGroupId: number, companyMarketIds: number[], pageIdx: number, pageSize: number) => {
  try {
    let jwtString: string | null = AuthStore.state.jwt;
    if (!jwtString) jwtString = sessionStorage.getItem("jwt");
    const myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + jwtString);

    let marketsString = getMarketsToformattedString2(companyMarketIds);

    let response = await fetch(
      `${PUBLIC_BASE_URL}api/v1.1/B2B/PricingGroups/GetPricingGroupsByCompanyMarketId${marketsString}&pageIndex=${pageIdx}&pageSize=${pageSize}`,
      {
        method: "GET",
        headers: myHeaders,
      }
    );

    const data = await response.json();
    data.data.forEach(el => {
      el.priceAmount = el.priceAmount > 0 ? `${el.priceAmount.toFixed(2)} ${el.priceUnit}/kWh` : el.priceAmount.toFixed(2)
      el.parkingFeePrice = el.parkingFeePrice && el.gracePeriod ? `${el.parkingFeePrice.toFixed(2)} ${el.priceUnit}/min after ${el.gracePeriod} min` : 'N/A'
      el.startFee = el.startFee ? `${el.startFee.toFixed(2)} ${el.priceUnit}` : 'N/A'
    });
    PricingStore.setState({
      ...PricingStore.state,
      pricingGroups: data.data.filter(el => el.id !== pricingGroupId),
      pricingGroupsSize: data.totalCount,
    });
  } catch (e) {
    console.log("Error: ", e);
  }
};

export const handleGetPricingGroupsForDropdown = async (companyMarketIds) => {
  try {
    let jwtString: string | null = AuthStore.state.jwt;
    if (!jwtString) jwtString = sessionStorage.getItem("jwt");
    const myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + jwtString);

    let marketsString = getMarketsToformattedString2(companyMarketIds);

    let response = await fetch(
      `${PUBLIC_BASE_URL}api/v1.1/B2B/PricingGroups/GetPricingGroupsDropdown${marketsString}`,
      {
        method: "GET",
        headers: myHeaders,
      }
    );

    const data = await response.json();
    return data;
  } catch (e) {
    console.log("Error: ", e);
  }
};

export const handleGetConnectorsForMoving = async (
  pricingGroupId: number,
  companyMarketIds: number[],
  pageIdx: number = 1,
  pageSize: number = 10) => {
  try {
    let jwtString: string | null = AuthStore.state.jwt;
    if (!jwtString) jwtString = sessionStorage.getItem("jwt");
    const myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + jwtString);

    let marketsString = getMarketsToformattedString(companyMarketIds);

    let response = await fetch(
      `${PUBLIC_BASE_URL}api/v1.1/B2B/PricingGroups/GetConnectorsNotInPricingGroup${marketsString}&pricingGroupId=${pricingGroupId}&pageIndex=${pageIdx}&pageSize=${pageSize}`,
      {
        method: "GET",
        headers: myHeaders,
      }
    );

    const data = await response.json();
    PricingStore.setState({
      ...PricingStore.state,
      connectorsNotInPricingGroup: data.data,
      connectorsNotInPricingGroupSize: data.totalCount
    });
  } catch (e) {
    console.log("Error: ", e);
  }
};
