import { useEffect, useState } from "react";
import {
  GetAccessibilityLocationTypes,
  RoamingStore,
} from "../../../stores/roamingStore";
import { useStore } from "react-stores";

interface Props {
  selectedType: string | undefined;
  setSelectedType: (type: string) => void;
}

const AccessibilityLocationDropdown = ({
  selectedType,
  setSelectedType,
}: Props) => {
  const [expanded, setExpanded] = useState(false);

  const { accessibilityLocationTypes } = useStore(RoamingStore);

  useEffect(() => {
    GetAccessibilityLocationTypes();
  }, []);

  const formatLabel = (value: string) => {
    return value.replace(/([a-z])([A-Z])/g, "$1 $2");
  };

  const handleSelect = (type: string, e: React.MouseEvent) => {
    e.stopPropagation();
    setSelectedType(type);
    setExpanded(false);
  };

  return (
    <div
      className="location-info-edit-input flex items-center justify-between cursor-pointer relative"
      onClick={() => setExpanded((prev) => !prev)}
    >
      {selectedType
        ? formatLabel(selectedType)
        : formatLabel(accessibilityLocationTypes[1] || "")}
      <img
        src={
          expanded
            ? "/icons/roaming/arrowUp.svg"
            : "/icons/roaming/arrowDown.svg"
        }
        alt="arrow"
      />

      {expanded && (
        <div
          style={{
            boxShadow: "0px 14px 29.6px 0px rgba(0, 0, 0, 0.05)",
          }}
          className="bg-[#FFF] p-2.5 rounded-[10px] absolute top-12 left-0 right-0"
        >
          {accessibilityLocationTypes.map((type, index) => (
            <div
              key={index}
              className={`p-2 cursor-pointer rounded-[5px] text-[12px] font-medium text-[#111F47] hover:bg-[#E9EDFC] hover:text-[#1E4CDC]`}
              onClick={(e) => handleSelect(type, e)}
            >
              {formatLabel(type)}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default AccessibilityLocationDropdown;
