import React, { Fragment, ReactNode, useState, useEffect } from "react";
import "./Table.css";

type TableProps = {
  tableHeaders: string[];
  tableRows: any[];
  tableRowOrder: string[];
  selected: number | number[];
  handleSelection: Function;
  expanded?: number;
  handleExpanded?: Function;
  children?: ReactNode;
  sortColumns?: string[];
  handleSort?: Function;
  noMargin?: true;
  blankHeight?: true;
  preloadRowNumber?: number;
  rowsStyling?: any[];
};

const Table: React.FC<TableProps> = ({
  tableHeaders,
  tableRows,
  tableRowOrder,
  handleSelection,
  selected,
  expanded,
  handleExpanded,
  children,
  sortColumns,
  handleSort,
  noMargin,
  blankHeight,
  preloadRowNumber,
  rowsStyling,
}) => {
  const [sort, setSort] = useState({ sortBy: -1, sortMethod: "descending" });
  const [showPreload, setShowPreload] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowPreload(false);
    }, 1000);

    return () => clearTimeout(timer);
  }, []);

  const handleSortChanging = (colIndex: number) => {
    if (sort.sortBy !== colIndex) {
      let newSort = { sortBy: colIndex, sortMethod: "descending" };
      setSort(newSort);
      handleSort &&
        handleSort(tableRowOrder.at(newSort.sortBy), newSort.sortMethod);
    } else {
      let newSort = { ...sort };
      newSort.sortMethod =
        sort.sortMethod === "descending" ? "ascending" : "descending";
      newSort.sortBy = sort.sortMethod === "descending" ? sort.sortBy : -1;
      setSort(newSort);
      handleSort?.(
        tableRowOrder.at(newSort.sortBy),
        newSort.sortBy === -1 ? "default" : newSort.sortMethod
      );
    }
  };

  return (
    <>
      <div className="flex flex-col items-stretch justify-between overflow-auto table-container table-scrollbar">
        {blankHeight && <div style={{ height: "110px" }} />}
        <table className="table table-striped">
          <thead>
            <tr className="header-row table-row">
              {typeof handleExpanded !== "undefined" && <th />}
              {tableHeaders.map((tableHeader, index) =>
                sortColumns && sortColumns?.includes(tableHeader) ? (
                  <th key={index} onClick={() => handleSortChanging(index)}>
                    <div className="table-header-with-sort">
                      <p>{tableHeader}</p>
                      {sort.sortBy === index ? (
                        sort.sortMethod === "ascending" ? (
                          <div className="flex flex-col items-center">
                            <img
                              src="/icons/arrow/sortArrowUpActive.svg"
                              alt=""
                            />
                            <img
                              src="/icons/arrow/sortArrowDownInactive.svg"
                              alt=""
                            />
                          </div>
                        ) : (
                          sort.sortMethod === "descending" && (
                            <div className="flex flex-col items-center">
                              <img
                                src="/icons/arrow/sortArrowUpInactive.svg"
                                alt=""
                              />
                              <img
                                src="/icons/arrow/sortArrowDownActive.svg"
                                alt=""
                              />
                            </div>
                          )
                        )
                      ) : (
                        <div className="flex flex-col items-center">
                          <img
                            src="/icons/arrow/sortArrowUpInactive.svg"
                            alt=""
                          />
                          <img
                            src="/icons/arrow/sortArrowDownInactive.svg"
                            alt=""
                          />
                        </div>
                      )}
                    </div>
                  </th>
                ) : (
                  <th key={index}>{tableHeader}</th>
                )
              )}
            </tr>
          </thead>
          <tbody>
            {tableRows.length === 0 && showPreload ? (
              <>
                {Array.from({ length: preloadRowNumber || 0 }).map(
                  (_, rowIndex) => (
                    <tr key={rowIndex} className="blurred-row">
                      {typeof handleExpanded !== "undefined" && <td />}
                      {tableHeaders.map((_, colIndex) => (
                        <td key={colIndex}>
                          <div className="blurred-col"></div>
                        </td>
                      ))}
                    </tr>
                  )
                )}
              </>
            ) : tableRows.length === 0 && !showPreload ? null : (
              tableRows.map((dataRow, index) => (
                <Fragment key={dataRow.id || index}>
                  <tr
                    id={
                      rowsStyling && rowsStyling[index].row
                        ? rowsStyling[index].row
                        : ""
                    }
                    className={
                      selected
                        ? typeof selected === "number"
                          ? selected === dataRow.id
                            ? "selected-row selectable-row"
                            : "selectable-row"
                          : selected.includes(dataRow.id)
                            ? "selected-row selectable-row"
                            : "selectable-row"
                        : "selectable-row"
                    }
                    onClick={() => handleSelection(dataRow.id)}
                  >
                    {typeof handleExpanded !== "undefined" && (
                      <td>
                        <button
                          className="checkbox-container"
                          onClick={() => handleExpanded(dataRow.id)}
                        >
                          <img
                            src={
                              expanded === dataRow.id
                                ? "/icons/table/checkboxIconMinus.svg"
                                : "/icons/table/checkboxIconPlus.svg"
                            }
                            alt=""
                          />
                        </button>
                      </td>
                    )}
                    {tableRowOrder.map((tableElement) => (
                      <td
                        className={
                          selected
                            ? typeof selected === "number"
                              ? selected === dataRow.id
                                ? "td-selected-row"
                                : ""
                              : selected.includes(dataRow.id)
                                ? "td-selected-row"
                                : ""
                            : ""
                        }
                        id={
                          rowsStyling && rowsStyling[index][tableElement]
                            ? rowsStyling[index][tableElement]
                            : ""
                        }
                        key={tableElement}
                      >
                        {dataRow[tableElement]}
                      </td>
                    ))}
                  </tr>
                  {expanded === dataRow.id && <>{children}</>}
                </Fragment>
              ))
            )}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default Table;
