import React, { useEffect, useState } from "react";
import {
  PricingStore,
  handleDeletePricingGroup,
  handleGetPricingGroupById,
  handleGetPricingGroups,
} from "../../stores/pricingStore";
import { useStore } from "react-stores";
import Popup from "../../shared/popup/Popup";
import { useNavigate } from "react-router-dom";
import Table from "../../shared/table/Table";
import Paging from "../../shared/paging/Paging";
import "./PricingPage.css";
import { useToast } from "../../shared/toast/ToastContent";
import Spinner from "../../shared/spinner/Spinner";

const PricingPage = () => {
  const [selected, setSelected] = useState(0);
  const [deletePopup, setDeletePopup] = useState(false);
  const [deleteFailedPopup, setDeleteFailedPopup] = useState(false);
  const [paging, setPaging] = useState({ page: 1, perPage: 10 });
  const [sort, setSort] = useState({ field: "", descending: true });
  const [loading, setLoading] = useState<boolean>(false);

  const pricingStore = useStore(PricingStore);
  const { pricingGroupsSize, pricingGroups, pricingGroupById } = pricingStore;
  const navigate = useNavigate();
  const { showToast } = useToast();

  const tableHeaders = [
    "Group Name",
    "Number of Connectors",
    "Market",
    "Electricity Price",
    "Parking Fee Price",
    "Session Start Price",
  ];

  const tableRows = [
    "name",
    "connectorCount",
    "companyMarketName",
    "priceAmount",
    "parkingFeePrice",
    "startFee",
  ];

  useEffect(() => {
    const getPricingGroups = async () => {
      await handleGetPricingGroups(
        paging.page,
        paging.perPage,
        sort.field,
        sort.descending
      );
    };
    getPricingGroups();
  }, [paging, sort]);

  const handleDeletePopup = async () => {
    selected &&
      handleGetPricingGroupById(selected).then(() => setDeletePopup(true));
  };

  const handleDelete = async (id) => {
    if (pricingGroupById?.connectorCount !== 0) {
      setDeleteFailedPopup(true);
    } else {
      setDeletePopup(false);
      setLoading(true);
      const response = await handleDeletePricingGroup(id);
      setLoading(false);
      if (response) {
        setSelected(0);
        setPaging((prev) => ({ page: 1, perPage: prev.perPage }));
        showToast("Pricing group successfully deleted!", "success");
        setSelected(0);
      } else {
        setSelected(0);
        showToast("There was an error while deleting pricing group", "error");
      }
    }
  };

  const handleSelection = async (id: number) => {
    if (selected === id) {
      setSelected(0);
    } else {
      setSelected(id);
    }
  };

  const handleSort = async (sortingField, sortingMethod) => {
    if (sortingMethod === "default") {
      setSort({ field: "", descending: true });
    } else {
      setSort({
        field: sortingField,
        descending: sortingMethod === "descending" ? true : false,
      });
    }
  };

  const deleteFailedText = () => {
    return (
      <div className="delete-pricing-group-popup">
        <div className="flex flex-row justify-start items-center gap-3">
          <img src="/icons/nav/activeDot.svg" alt="" />
          <div className="text-[18px] font-semibold text-[#111F47]">
            Group Name: {pricingGroupById?.name}
          </div>
        </div>
        <div className="border-b-[2.5px] border-solid border-[#E7E9ED] w-full my-2"></div>
        <div className="text-[16px] font-medium text-[#B5BAC6] text-left mb-5">
          Please remove all connectors from selected pricing group by clicking
          on Edit button before deleting it.
        </div>
      </div>
    );
  };

  const mainText = () => {
    return (
      <div className="flex flex-col items-start gap-3">
        <div className="remote-popup-main-text">
          Do you want to delete the selected pricing group?
        </div>
        <div className="flex flex-row justify-start items-center gap-3">
          <img src="/icons/nav/activeDot.svg" alt="" />
          <div className="text-[16px] font-medium text-[#111F47]">
            Group Name: {pricingGroupById?.name}
          </div>
        </div>
        <div className="h-[2.5px] bg-[#E7E9ED] w-[100%] my-2"></div>
      </div>
    );
  };

  return (
    <div className="flex-1 px-3.5 pt-3.5 flex flex-col gap-3.5 items-stretch main-wrapper overflow-hidden">
      <div className="bg-white card p-5">
        <div className="flex flex-row justify-between items-center header flex-wrap">
          <div className="pricing-groups">
            <span>
              <span className="pricing-groups-span">Commercial/</span>
              <span className="pricing-groups-span2">Pricing Groups</span>
            </span>
          </div>
          <div className="flex flex-row gap-5 flex-wrap justify-around items-center">
            <button
              className={`editPricingGroupBtn ${selected ? "editPricingGroupBtnActive" : ""
                }`}
              onClick={() =>
                selected &&
                navigate(`/dashboard/commercial/pricing/${selected}`)
              }
              disabled={!selected}
            >
              Edit
            </button>
            <button
              className={`editPricingGroupBtn ${selected ? "deletePricingGroupBtnActive" : ""
                }`}
              onClick={handleDeletePopup}
            >
              Delete
            </button>
            <button
              className="createNewBtn"
              onClick={() => navigate(`/dashboard/commercial/pricing/new`)}
            >
              <img src="/icons/admin/plusIcon.svg" alt="" />
              Create new
            </button>
          </div>
        </div>
      </div>
      <div className="p-5 table-container">
        <Table
          tableHeaders={tableHeaders}
          tableRows={pricingGroups}
          tableRowOrder={tableRows}
          handleSelection={(id) => handleSelection(id)}
          selected={selected}
          sortColumns={tableHeaders}
          handleSort={(sortingField, sortingMethod) =>
            handleSort(sortingField, sortingMethod)
          }
          preloadRowNumber={5}
        />

        <Paging
          tableSize={pricingGroupsSize}
          pageChangeFunc={(page, perPage) =>
            setPaging({ page: page, perPage: perPage })
          }
        />
      </div>

      {deletePopup && (
        <Popup
          mainText={mainText()}
          confirmText="Yes"
          closeText="No"
          confirmFunction={() => handleDelete(selected)}
          closeFunction={() => setDeletePopup(false)}
        />
      )}
      {deleteFailedPopup && (
        <Popup
          mainText={deleteFailedText()}
          closeText="Close"
          confirmFunction={() => undefined}
          closeFunction={() => setDeleteFailedPopup(false)}
        />
      )}
      {loading && <Spinner />}
    </div>
  );
};

export default PricingPage;

const dummy = [

];
