import React, { useState, useEffect } from "react";
import Edit from "./Edit";
import Create from "./Create";
import { MarketStore, handleGetMarkets } from "../../stores/marketStore";
import { useStore } from "react-stores";
import { AuthStore } from "../../stores/authStore";
import "./Company.css";
import Table from "../../shared/table/Table";
import Toast from "../../shared/toast/Toast";
import {
  ICompanyInfo,
  useCompanyInfoQuery,
} from "../../hooks/useGetCompanyInfoHook";
import { useCompanyAdminsQuery } from "../../hooks/useGetCompanyAdminsHook";

const Company: React.FC = () => {
  const { data: companyInfoData, isLoading, isError } = useCompanyInfoQuery();
  const { data: companyAdminsData } = useCompanyAdminsQuery();

  const [selected, setSelected] = useState<number | null>(null);
  const [editMode, setEditMode] = useState(false);
  const [createMode, setCreateMode] = useState(false);
  const authStore = useStore(AuthStore);

  const marketStore = useStore(MarketStore);
  const { markets } = marketStore;
  const { user } = authStore;

  const companyInfo = {
    id: companyInfoData?.id,
    name: companyInfoData?.companyName,
    email: companyInfoData?.contactEmail,
    location: companyInfoData?.address,
  };

  useEffect(() => {
    handleGetMarkets();
  }, []);

  const handleSelection = (newMarket: number) => {
    if (selected === newMarket) {
      setSelected(null);
    } else {
      setSelected(newMarket);
    }
  };

  const tableOrder = ["name", "companyName", "country", "address"];
  const tableHeaders = ["Market", "Company Name", "Country", "Company Address"];

  const adminsOrder = ["firstName", "lastName", "email", "activated"];
  const adminsHeaders = ["First Name", "Last Name", "Email", "Activated"];

  const transformedAdminsData = companyAdminsData?.map((admin) => ({
    ...admin,
    activated: admin.activated ? "Yes" : "No",
  }));

  return (
    <div className="flex-1 p-3.5 flex flex-col gap-3.5 items-stretch main-wrapper">
      <div className="bg-white card p-5">
        <div className="company-title">
          <span>
            <span className="company-title-span">Company Settings</span>
          </span>
        </div>
      </div>
      <div className="flex items-center justify-stretch w-full gap-3.5">
        <div className="bg-white card p-4 w-2/5 h-[17rem]">
          <div className="p-3 flex flex-col items-center w-full gap-1">
            <div className="w-full text-left company-info-title">
              Company Information
            </div>
            <div className="flex w-full justify-between items-center mt-3 py-4 company-info-container">
              <div className="company-info-subtitle">Company Name</div>
              <div className="company-info-text">{companyInfo.name}</div>
            </div>
            <div className="flex w-full justify-between items-center py-4 company-info-container">
              <div className="company-info-subtitle">Contact Email</div>
              <div className="company-info-text">{companyInfo.email}</div>
            </div>
            <div className="flex w-full justify-between items-center py-4">
              <div className="company-info-subtitle">Address</div>
              <div className="company-info-text">{companyInfo.location}</div>
            </div>
          </div>
        </div>
        <div className="bg-white card p-5 w-3/5 h-[17rem] overflow-auto custom-scrollbar-company">
          <div className="p-3 flex flex-col items-center w-full gap-5">
            <div className="w-full text-left company-info-title">
              Company Admins
            </div>
            <Table
              tableRows={transformedAdminsData || []}
              tableRowOrder={adminsOrder}
              tableHeaders={adminsHeaders}
              handleSelection={() => undefined}
              selected={0}
              noMargin
              preloadRowNumber={3}
            />
          </div>
        </div>
      </div>
      <div className="bg-white card p-5">
        <div className="flex flex-row justify-end items-center p-2 pt-4 flex-wrap mb-3.5">
          <div className="flex flex-row justify-between items-center gap-5">
            <button
              className={`${
                selected ? "edit-company-btn-active" : ""
              } edit-company-btn`}
              onClick={() => selected && setEditMode(true)}
            >
              Edit Market
            </button>
            <button
              className="create-market-btn flex flex-row justify-center items-center gap-2"
              onClick={() => setCreateMode(true)}
            >
              <img src="/icons/admin/plusIcon.svg" alt="" />
              <div>Create new market</div>
            </button>
          </div>
        </div>
        <Table
          tableRows={markets}
          tableRowOrder={tableOrder}
          tableHeaders={tableHeaders}
          handleSelection={handleSelection}
          selected={selected!}
          noMargin
          preloadRowNumber={5}
        />
      </div>
      {editMode && (
        <Edit
          editMode={editMode}
          selected={selected!}
          setEditMode={setEditMode}
        />
      )}
      {createMode && (
        <Create
          createMode={createMode}
          companyId={user?.companyId!}
          companyName={user?.companyName!}
          setCreateMode={setCreateMode}
        />
      )}
    </div>
  );
};

export default Company;
