import { Store } from "react-stores";
import { ITicketStore } from "../interfaces/IStores";
import { AuthStore } from "./authStore";
import {
  extractTime,
  formatDateLocale,
  formatRelativeTime,
} from "../helpers/dataHelper";
import { ITicketNotification, RespondTicketDto } from "../interfaces/ITickets";

const PUBLIC_BASE_URL = process.env.REACT_APP_BASE_URL;

export const TicketStore = new Store<ITicketStore>({
  tickets: [],
  ticketsCount: 0,
  ticketsDashboardData: null,
  selectedTicket: 0,
});

export const handleGetTickets = async (
  pageIdx: number = 1,
  pageSize: number = 10,
  startDate: string = "",
  endDate: string = "",
  searched: string = "",
  sortingField: string = "",
  sortingDescending: boolean = true
) => {
  try {
    let jwtString: string | null = AuthStore.state.jwt;
    if (!jwtString) jwtString = sessionStorage.getItem("jwt");
    const myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + jwtString);
    let response = await fetch(
      `${PUBLIC_BASE_URL}api/v1.1/B2B/Tickets/GetTickets?PageIndex=${pageIdx}&PageSize=${pageSize}${
        startDate ? "&DateTimeFilter.from=" + startDate : ""
      }${endDate ? "&DateTimeFilter.to=" + endDate : ""}${
        searched ? "&keyword=" + searched : ""
      }${
        sortingField
          ? `&SortingCriteria.Field=${sortingField}&SortingCriteria.Descending=${sortingDescending}`
          : ""
      }`,
      {
        method: "GET",
        headers: myHeaders,
      }
    );

    const data = await response.json();
    data.data.forEach((el) => {
      el.raisedAt =
        formatDateLocale(el.raisedAt) + " " + extractTime(el.raisedAt);
      el.respondedAt = el.respondedAt
        ? `${formatDateLocale(el.respondedAt)} ${extractTime(el.respondedAt)}`
        : "N/A";
      el.userPhoneNumber = el.userPhoneNumber ? el.userPhoneNumber : "N/A";
      el.sessionId = el.sessionId > 0 ? el.sessionId : "N/A";
    });
    TicketStore.setState({
      ...TicketStore.state,
      tickets: data.data,
      ticketsCount: data.totalCount,
    });
  } catch (e) {
    console.log("Error: ", e);
  }
};

export const handleGetTicketCount = async (
  startDate: string = "",
  endDate: string = ""
) => {
  try {
    let jwtString: string | null = AuthStore.state.jwt;
    if (!jwtString) jwtString = sessionStorage.getItem("jwt");
    const myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + jwtString);
    let response = await fetch(
      `${PUBLIC_BASE_URL}api/v1.1/B2B/Tickets/GetTicketsCount${
        startDate && "?from=" + startDate
      }${endDate && "&to=" + endDate}`,
      {
        method: "GET",
        headers: myHeaders,
      }
    );

    const data = await response.json();
    data.resolvedPercentage = data.resolvedPercentage.toFixed(1);
    TicketStore.setState({
      ...TicketStore.state,
      ticketsDashboardData: data,
    });
  } catch (e) {
    console.log("Error: ", e);
  }
};

export const handleTicketResponse = async (data: RespondTicketDto) => {
  try {
    let jwtString: string | null = AuthStore.state.jwt;
    if (!jwtString) jwtString = sessionStorage.getItem("jwt");
    if (!jwtString) return false;
    const response = await fetch(
      `${PUBLIC_BASE_URL}api/v1.1/B2B/Tickets/TicketRespond`,
      {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + jwtString,
        },
      }
    );
    return response.ok;
  } catch (e) {
    console.log("Error: ", e);
    return false;
  }
};

export const handleTicketResolve = async (ticketId: number) => {
  try {
    let jwtString: string | null = AuthStore.state.jwt;
    if (!jwtString) jwtString = sessionStorage.getItem("jwt");
    if (!jwtString) return false;
    const response = await fetch(
      `${PUBLIC_BASE_URL}api/v1.1/B2B/Tickets/ResolveTicket?id=${ticketId}`,
      {
        method: "POST",
        headers: {
          Authorization: "Bearer " + jwtString,
        },
      }
    );
    return response.ok;
  } catch (e) {
    console.log("Error: ", e);
    return false;
  }
};

export const handleSetSelectedTicket = (id: number) => {
  TicketStore.setState({
    ...TicketStore.state,
    selectedTicket: id,
  });
};
