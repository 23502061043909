import React, { useState, useRef, useEffect, ReactNode } from "react";
import "./Popup.css";

interface PopupProps {
  mainText: ReactNode;
  confirmText?: ReactNode;
  closeText?: ReactNode;
  confirmFunction: Function;
  closeFunction: Function;
}

const Popup = (props: PopupProps) => {
  let popupRef = useRef<any>(null);

  useEffect(() => {
    let handler = (e) => {
      if (popupRef.current && !popupRef.current.contains(e.target)) {
        props.closeFunction();
      }
    };

    document.addEventListener("mousedown", handler);
  });

  return (
    <>
      <div className="modal-background" />
      <div className="popup-container" ref={popupRef}>
        <div className="flex flex-col items-stretch relative h-[100%] w-[100%]">
          <div className="popup-text-container">{props.mainText}</div>
          <div className="flex flex-row absolute bottom-0 justify-stretch items-center w-[100%]">
            {props.confirmText ? (
              <>
                {props.closeText && (
                  <div
                    className="flex-1 cancel left-curved"
                    onClick={() => props.closeFunction()}
                  >
                    {props.closeText}
                  </div>
                )}
                <div
                  className={`flex-1 confirm right-curved ${
                    props.closeText ? "" : " bottom-curved"
                  }`}
                  onClick={() => props.confirmFunction()}
                >
                  {props.confirmText}
                </div>
              </>
            ) : (
              props.closeText && (
                <div
                  className="flex-1 cancel bottom-curved"
                  onClick={() => props.closeFunction()}
                >
                  {props.closeText}
                </div>
              )
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Popup;
