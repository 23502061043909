import React from "react";
import { useNavigate } from "react-router-dom";
import { useStore } from "react-stores";
import { AuthStore } from "../stores/authStore";
import "./Header.css";
import NeedHelp from "../shared/header/needHelpModal/NeedHelp";
import NotificationButton from "../shared/header/notificationModal/NotificationButton";
import ProfilePhotoModal from "../shared/header/profilePhotoModal/ProfilePhotoModal";

const Header = () => {
  const userStore = useStore(AuthStore);
  const { user } = userStore;
  const navigate = useNavigate();

  return (
    <header
      className="h-[6rem] bg-white">
      <div className="flex flex-row justify-between items-center h-full pl-[3rem] pr-[3rem]">
        <div />
        <div className="flex gap-5">
          <div className="flex gap-5 items-center">
            <NeedHelp />
            <NotificationButton />
            <div className="header-vertical-line"></div>
          </div>
          <ProfilePhotoModal name={user?.firstName} surname={user?.lastName} />
        </div>
      </div>
    </header>
  );
};

export default Header;
