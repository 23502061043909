import React, { useEffect, useState } from "react";
import Select from "react-select";
import "./CreateLocationModal.css";
import {
  fetchCoordinates,
  MAPBOX_ACCESS_TOKEN,
} from "../../../../helpers/constants";
import { ICreateLocation } from "../../../../interfaces/ILocation";
import { useStore } from "react-stores";
import {
  handleGetMarketsForDropdown,
  MarketStore,
} from "../../../../stores/marketStore";
import { fetchAddressSuggestions } from "../../../../helpers/addressHelpers";
import { selectStyling } from "../../../../helpers/componentsHelper";

interface Props {
  closeModal: Function;
  setLocation: Function;
  setScreen: Function;
}

interface NewLocation {
  locationName: string;
  market: null | number;
  address: string;
  street: string;
  postalNumber: string;
  city: string;
}

interface SelectOption {
  value: string;
  label: string;
  street: string;
  postalNumber: string;
  city: string;
}

const CreateLocationModal = ({ setLocation, setScreen, closeModal }: Props) => {
  const [confirm, setConfirm] = useState(false);
  const [inputsFilled, setInputsFilled] = useState(false);
  const { marketsForDropdown } = useStore(MarketStore);
  const [newLocation, setNewLocation] = useState<NewLocation>({
    locationName: "",
    market: null,
    address: "",
    street: "",
    postalNumber: "",
    city: "",
  });

  const [addressOptions, setAddressOptions] = useState<SelectOption[]>([]);
  const [query, setQuery] = useState("");

  useEffect(() => {
    handleGetMarketsForDropdown();
  }, []);

  useEffect(() => {
    const { locationName, market, address } = newLocation;
    const isComplete = !!locationName && !!market && !!address;
    setInputsFilled(isComplete);
  }, [newLocation]);

  useEffect(() => {
    const fetchSuggestions = async () => {
      const options = await fetchAddressSuggestions(query, MAPBOX_ACCESS_TOKEN);

      if (options.some((option) => option.label === query)) {
        setAddressOptions([]);
      } else {
        setAddressOptions(options);
      }
    };

    fetchSuggestions();
  }, [query]);

  const handleSelectChange = (selectedOption: SelectOption | null) => {
    setNewLocation({
      ...newLocation,
      address: selectedOption?.label || "",
      street: selectedOption?.street || "",
      postalNumber: selectedOption?.postalNumber || "",
      city: selectedOption?.city || "",
    });
    setQuery(selectedOption?.label || "");
    console.log(selectedOption?.label || "");
    setAddressOptions([]);
  };

  const handleCreateLocation = async () => {
    try {
      const result = await fetchCoordinates(newLocation.address);
      if (result) {
        const { street, postalNumber, city, latitude, longitude } = result;

        const updatedLocation: ICreateLocation = {
          companyMarketId: newLocation.market!,
          description: "",
          locationName: newLocation.locationName,
          latitude,
          longitude,
          street,
          postalNumber,
          city,
        };

        setScreen("map");
        setLocation(updatedLocation);
        closeModal(false);
      }
    } catch (error) {
      console.error("Error creating location:", error);
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setNewLocation((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  return (
    <>
      <div className="modal-background" onClick={() => closeModal(false)} />
      <div className={`create-location-container narrow`}>
        <div className="modal-header justify-end">
          <img
            src="/icons/shared/exit-cross.svg"
            alt=""
            onClick={() => closeModal(false)}
            className="cursor-pointer"
          />
        </div>
        <div className="line"></div>

        <div className="content-location-container">
          <div className="flex-location" style={{ margin: "30px 0px" }}>
            <p className="title 2xl:text-[24px] md:text-[20px] sm:text-[16px]">
              Create New Location
            </p>
            <button
              className={`${
                inputsFilled ? "create-location-btn-active" : ""
              } delete-charger-btn`}
              disabled={!inputsFilled}
              onClick={handleCreateLocation}
            >
              <p
                className={inputsFilled ? "delete-text-active" : "delete-text"}
              >
                Create
              </p>
            </button>
          </div>
          <div className="line" />
          <div className="location-information-table">
            <div className="flex justify-between items-center gap-5 w-full">
              <div className="flex-fields">
                <p className="title-1">Location Name</p>
                <input
                  className="location-info-edit-input"
                  placeholder="Location Name"
                  value={newLocation.locationName}
                  onChange={handleInputChange}
                  name="locationName"
                />
              </div>
              <div className="flex-fields">
                <p className="title-1">Market</p>
                <Select
                  options={marketsForDropdown.map((market) => ({
                    value: String(market.id),
                    label: market.name,
                  }))}
                  placeholder="Market"
                  value={marketsForDropdown
                    .map((market) => ({
                      value: String(market.id),
                      label: market.name,
                    }))
                    .find(
                      (option) => option.value === String(newLocation.market)
                    )}
                  styles={selectStyling}
                  onChange={(selectedOption) =>
                    setNewLocation({
                      ...newLocation,
                      market: selectedOption?.value
                        ? parseInt(selectedOption.value)
                        : null,
                    })
                  }
                />
              </div>
            </div>
            <div className="flex justify-between gap-5">
              <div className="flex-fields relative">
                <p className="title-1">Address</p>
                <div className="relative w-full">
                  <input
                    className="location-info-edit-input pr-8"
                    type="text"
                    name="address"
                    placeholder="Address"
                    value={query}
                    onChange={(e) => setQuery(e.target.value)}
                  />
                  <img
                    onClick={(e) => setQuery("")}
                    className={
                      query.length > 0
                        ? "absolute right-4 top-1/2 transform -translate-y-1/2 cursor-pointer"
                        : "opacity-0"
                    }
                    height={16}
                    width={16}
                    src="/icons/shared/exit-cross.svg"
                    alt="cross"
                  />
                </div>

                {addressOptions.length > 0 && (
                  <ul className="address-container">
                    {addressOptions.map((option) => (
                      <li
                        className="address-options"
                        key={option.value}
                        onClick={() => handleSelectChange(option)}
                      >
                        {option.label}
                      </li>
                    ))}
                  </ul>
                )}
              </div>
              <div className="flex-fields-last">
                <p className="title-1">Market</p>
                <div className="unactive-fields">
                  <p className="unactive-data">helper</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateLocationModal;
