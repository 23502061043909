import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import {
  GetRoamingCompanyMarketsPaginated,
  RoamingStore,
} from "../../stores/roamingStore";
import { ICompanyMarketRoaming } from "../../interfaces/IRoaming";

const COMPANY_MARKETS_CACHE_KEY = "roamingCompanyMarketsPaginated";

async function fetchRoamingCompanyMarketsPaginated(
  sortingField: string,
  sortingDescending: boolean,
  pageIndex: number = 1,
  pageSize: number = 10,
  registered?: boolean
): Promise<ICompanyMarketRoaming[]> {
  try {
    await GetRoamingCompanyMarketsPaginated(
      sortingField,
      sortingDescending,
      pageIndex,
      pageSize,
      registered
    );
    return RoamingStore.state.companyMarketsPaginated || [];
  } catch (error) {
    console.error("Error fetching company markets dropdown:", error);
    return [];
  }
}

const defaultCompanyMarketsQueryOptions: UseQueryOptions<
  ICompanyMarketRoaming[]
> = {
  queryKey: [COMPANY_MARKETS_CACHE_KEY],
  staleTime: 10 * 60 * 1000,
  retry: 1,
  refetchOnWindowFocus: false,
};

type CustomCompanyMarketsQueryOptions = Omit<
  UseQueryOptions<ICompanyMarketRoaming[]>,
  "queryKey" | "queryFn"
>;

export function useRoamingCompanyMarketsPaginatedQuery(
  sortingField: string,
  sortingDescending: boolean,
  pageIndex?: number,
  pageSize?: number,
  registered?: boolean,
  options?: CustomCompanyMarketsQueryOptions
) {
  return useQuery({
    ...defaultCompanyMarketsQueryOptions,
    queryKey: [COMPANY_MARKETS_CACHE_KEY],
    queryFn: () =>
      fetchRoamingCompanyMarketsPaginated(
        sortingField,
        sortingDescending,
        pageIndex,
        pageSize,
        registered!
      ),
    ...options,
  });
}
