import React, { useState, useEffect, useMemo } from "react";
import { useStore } from "react-stores";
import "./LoadManagement.css";
import Table from "../../../shared/table/Table";
import SetPowerLimit from "./SetPowerLimit";
import ResetPowerLimit from "./ResetPowerLimit";
import {
    ChargerStore,
    handleGetChargers,
    handleGetConnectorsForCharger,
} from "../../../stores/chargerStore";
import { useCompanyMarketsDropdownQuery } from "../../../hooks/useCompanyMarketDropdown";
import Paging from "../../../shared/paging/Paging";

interface Paging {
    page: number;
    perPage: number;
}

const LoadManagement = () => {
    const [sort, setSort] = useState({ field: "", descending: true });
    const [paging, setPaging] = useState<Paging>({ page: 1, perPage: 10 });
    const [selected, setSelected] = useState<number>(0);
    const [expanded, setExpanded] = useState<number>(0);
    const [setPowerLimitPopup, setSetPowerLimitPopup] = useState<boolean>(false);
    const [resetPowerLimitPopup, setResetPowerLimitPopup] = useState<boolean>(false);

    const { chargers, chargersSize, chargerWithConnectors } = useStore(ChargerStore);
    const { data: marketsData } = useCompanyMarketsDropdownQuery();

    const canReSet = useMemo(() => {
        if (selected) {
            const selectedData = chargers.find(el => el.id === selected);
            return (selectedData && selectedData.havePowerLimitSet) ? true : false;
        } else {
            return false;
        }
    }, [selected])

    const rowsStyling = useMemo(() =>
        chargers.map(el =>
            el.havePowerLimitSet
                ? { row: 'table-red-border' }
                : { row: 'table-blue-border' })
        , [chargers])

    useEffect(() => {
        marketsData && handleGetChargers(
            marketsData.map(el => el.id),
            [],
            [],
            paging.page,
            paging.perPage,
            sort.field,
            sort.descending
        );
    }, [marketsData, paging, sort]);

    useEffect(() => {
        expanded && handleGetConnectorsForCharger(expanded);
        return (() => {
            ChargerStore.setState({
                ...ChargerStore.state,
                chargerWithConnectors: null,
            });
        })
    }, [expanded]);

    const handleSort = async (sortingField, sortingMethod) => {
        if (sortingMethod === "default") {
            setSort({ field: "", descending: true });
        } else {
            setSort({
                field: sortingField,
                descending: sortingMethod === "descending" ? true : false,
            });
        }
    };

    return (
        <div className="flex-1 p-3.5 flex flex-col gap-3.5 items-stretch main-wrapper">
            <div className="bg-white card p-5">
                <div className="flex flex-row justify-between items-center header flex-wrap">
                    <div className="load-management">
                        <span>
                            <span className="load-management-span">Operations/</span>
                            <span className="load-management-span2">
                                Load Management
                            </span>
                        </span>
                    </div>
                    <div className="flex items-center gap-5">
                        <button
                            className={`register-btn ${canReSet && 'register-btn-active'}`}
                            disabled={!canReSet}
                            onClick={() => setResetPowerLimitPopup(true)}
                        >
                            Reset Power Limit
                        </button>
                        <button
                            className={`register-btn ${selected && 'register-btn-active'}`}
                            disabled={!selected}
                            onClick={() => setSetPowerLimitPopup(true)}
                        >
                            Set Power Limit
                        </button>
                    </div>

                </div>
            </div>
            <div className="table-container p-5 flex flex-col gap-3">
                <div className="flex justify-end items-center gap-5 mb-2">
                    <div className="flex items-center gap-1 text-[12px]">
                        <img src="/icons/threeDots/blue-dot.svg" alt="" />
                        <p>No power limit set</p>
                    </div>
                    <div className="flex items-center gap-1 text-[12px]">
                        <img src="/icons/threeDots/red-dot.svg" alt="" />
                        <p>Has power limit set</p>
                    </div>
                </div>
                <Table
                    tableHeaders={tableHeaders}
                    tableRowOrder={tableRowOrder}
                    tableRows={chargers}
                    selected={selected}
                    sortColumns={tableHeaders}
                    expanded={expanded}
                    handleExpanded={(id) => setExpanded(prev => prev === id ? 0 : id)}
                    handleSort={(sortingField, sortingMethod) => handleSort(sortingField, sortingMethod)}
                    handleSelection={(id) => setSelected(prev => prev === id ? 0 : id)}
                    rowsStyling={rowsStyling}
                    preloadRowNumber={6}
                >
                    <tr className="subtable-row">
                        <td id="initial-td" colSpan={6}>
                            <div className="pl-5">
                                <table className="w-[100%]">
                                    <thead>
                                        <tr>

                                            <th className="subtable-header">Connector ID</th>
                                            <th className="subtable-header">Connector Status</th>
                                            <th className="subtable-header">Connector Type</th>
                                            <th className="subtable-header">Electric Current</th>
                                            <th className="subtable-header">Max Power</th>
                                            <th className="subtable-header">Power Limit</th>

                                        </tr>
                                    </thead>
                                    <tbody>
                                        {chargerWithConnectors?.chargerId && chargerWithConnectors.connectors.map((connector) => (
                                            <tr className="subtable-tr" key={connector.id} id={connector.staticPowerLimit ? 'table-red-border' : 'table-blue-border'}>

                                                <td className="subtable-td">
                                                    {connector.connectorName}
                                                </td>
                                                <td className="subtable-td">
                                                    <div
                                                        className={`${connector.connectorStatus === "Available"
                                                            ? "available"
                                                            : connector.connectorStatus === "Faulted"
                                                                ? "offline"
                                                                : "in-use"
                                                            } connector-status-container`}
                                                    >
                                                        {connector.connectorStatus}
                                                    </div>
                                                </td>
                                                <td className="subtable-td">
                                                    {connector.connectorType}
                                                </td>
                                                <td className="subtable-td">
                                                    {connector.electricCurrent}
                                                </td>
                                                <td className="subtable-td">
                                                    {connector.maxPower && connector.maxPower.toFixed(2) + ' kW'}
                                                </td>
                                                <td className="subtable-td">
                                                    {connector.staticPowerLimit ? connector.staticPowerLimit.toFixed(2) + ' kW' : 'N/A'}
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </td>
                    </tr>
                </Table>
                <Paging tableSize={chargersSize}
                    pageChangeFunc={(page, perPage) => setPaging({ page: page, perPage: perPage })}
                />
            </div>
            {setPowerLimitPopup &&
                <SetPowerLimit handleExit={(changed) => {
                    setSetPowerLimitPopup(false);
                    changed && handleGetChargers(
                        marketsData ? marketsData.map(el => el.id) : [],
                        [],
                        [],
                        paging.page,
                        paging.perPage,
                        sort.field,
                        sort.descending
                    );
                }}
                    selectedCharger={selected} />}
            {resetPowerLimitPopup &&
                <ResetPowerLimit handleExit={(changed) => {
                    setResetPowerLimitPopup(false);
                    changed && handleGetChargers(
                        marketsData ? marketsData.map(el => el.id) : [],
                        [],
                        [],
                        paging.page,
                        paging.perPage,
                        sort.field,
                        sort.descending
                    );
                }}
                    selectedCharger={selected} />}
        </div>
    );
};

export default LoadManagement;

const tableHeaders = [
    'Charger ID',
    'Market',
    'Available Connectors',
    'Unavailable/faulted Connectors',
    'Location Name',
    'Address',
];

const tableRowOrder = [
    'ocppChargerId',
    'companyMarketName',
    'numberOfAvailableConnectors',
    'numberOfUnavailableFaultedConnectors',
    'locationName',
    'street',
];
