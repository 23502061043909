import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import { GetPublishedChargers, RoamingStore } from "../../stores/roamingStore";
import { IChargerRoaming } from "../../interfaces/IRoaming";

const PUBLISHED_CHARGERS_CACHE_KEY = "publishedChargers";

async function fetchPublishedChargers(
  companyMarketId: number,
  sortingField: string,
  sortingDescending: boolean,
  pageIndex: number,
  pageSize: number
): Promise<IChargerRoaming[]> {
  await GetPublishedChargers(
    companyMarketId,
    sortingField,
    sortingDescending,
    pageIndex,
    pageSize
  );
  return RoamingStore.state.publishedChargers;
}

const defaultPublishedChargersQueryOptions: UseQueryOptions<IChargerRoaming[]> =
  {
    queryKey: [PUBLISHED_CHARGERS_CACHE_KEY],
    staleTime: 10 * 60 * 1000,
    retry: 1,
    refetchOnWindowFocus: false,
  };

type CustomPublishedChargersQueryOptions = Omit<
  UseQueryOptions<IChargerRoaming[]>,
  "queryKey" | "queryFn"
>;

export function usePublishedChargersQuery(
  companyMarketId: number,
  sortingField: string,
  sortingDescending: boolean,
  pageIndex: number,
  pageSize: number,
  options?: CustomPublishedChargersQueryOptions
) {
  return useQuery({
    ...defaultPublishedChargersQueryOptions,
    queryKey: [PUBLISHED_CHARGERS_CACHE_KEY],
    queryFn: () =>
      fetchPublishedChargers(
        companyMarketId,
        sortingField,
        sortingDescending,
        pageIndex,
        pageSize
      ),
    ...options,
  });
}
