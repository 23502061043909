import { useEffect, useState } from "react";
import Table from "../../../../../shared/table/Table";
import { useStore } from "react-stores";
import { PricingStore } from "../../../../../stores/pricingStore";
import "./ChargerInformation.css";
import EditInformation from "./edit/EditInformation";
import { IChargers, IConnectorInfo } from "../../../../../interfaces/ILocation";
import {
  DeleteCharger,
  GetChargers,
  GetConnectorInfo,
  LocationStore,
} from "../../../../../stores/locationStore";
import Popup from "../../../../../shared/popup/Popup";

import qrcode from "qrcode-generator";
import {
  closeConnection,
  startConnection,
} from "../../../../../helpers/signalRHelper";
import { generateQR } from "../../../../../helpers/dataHelper";

interface Props {
  locationId: number;
}

const ChargerInformation = ({ locationId }: Props) => {
  const PUBLIC_BASE_URL = process.env.REACT_APP_BASE_URL;
  const [selectedCharger, setSelectedCharger] = useState<number>(0);
  const [editCharger, setEditCharger] = useState(false);
  const [expanded, setExpanded] = useState<number>(0);
  const [connectorInfo, setConnectorInfo] = useState<IConnectorInfo[]>([]);
  const [deleteCharger, setDeleteCharger] = useState(false);
  const [deleteChargerToast, setDeleteChargerToast] = useState(false);
  const pricingStore = useStore(PricingStore);
  const { chargers } = useStore(LocationStore);
  const { assignedConnectors } = pricingStore;

  const [connection, setConnection] = useState<signalR.HubConnection | null>(
    null
  );
  let connectorsOfChargerTemp: IConnectorInfo[] = [];

  const tableHeaders = [
    "Charger ID",
    "Market",
    "Connectors",
    "Charger Information",
  ];

  const tableRowOrder = [
    "ocppChargerId",
    "companyMarketName",
    "connectorsCount",
    "chargerInformation",
  ];

  const handleSelection = (id: number) => {
    setSelectedCharger((prevSelectedCharger) => {
      const newSelectedCharger = prevSelectedCharger === id ? 0 : id;
      return newSelectedCharger;
    });
  };

  useEffect(() => {
    const fetchData = async () => {
      const result = await GetConnectorInfo(expanded);
      // eslint-disable-next-line react-hooks/exhaustive-deps
      connectorsOfChargerTemp = [...result];
      setConnectorInfo(result);

      if (result && result.length > 0) handleSignalRConnection(result);
    };

    expanded ? fetchData() : setConnectorInfo([]);
    return () => {
      connection && closeConnection(connection);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [expanded]);


  const handleSignalRConnection = async (connectors) => {
    const newConnection = await startConnection(
      `${PUBLIC_BASE_URL}hubs/connectorStatus`
    );
    setConnection(newConnection);

    const connectorIds = connectors.map((connector) => connector.id);
    await newConnection
      .invoke("Subscribe", connectorIds)
      .then(() => console.log("Subscribed Id's: " + connectorIds))
      .catch((err) => console.error("Error while subscribing", err));

    newConnection.on(
      "updateConnectorStatus",
      (updateConnectorStatusSignalRDto) => {
        console.log(
          `Status for connector ${updateConnectorStatusSignalRDto.connectorId}: ${updateConnectorStatusSignalRDto.status}`
        );
        if (connectorsOfChargerTemp && connectorsOfChargerTemp.length > 0) {
          console.log("Connectors of charger:");
          console.log(connectorsOfChargerTemp);
          let updatedConnectors = connectorsOfChargerTemp.map((con) => {
            if (
              Number(con.id) ===
              Number(updateConnectorStatusSignalRDto.connectorId)
            ) {
              return {
                ...con,
                connectorStatus: updateConnectorStatusSignalRDto.status,
              };
            } else return con;
          });
          console.log("Updated: ");
          console.log(updatedConnectors);
          connectorsOfChargerTemp = [...updatedConnectors];
          setConnectorInfo(connectorsOfChargerTemp);
        }
      }
    );
  };

  const handleExpanded = (id: number) => {
    if (expanded === id) {
      LocationStore.setState({
        ...LocationStore.state,
        chargerInfoExpanded: false,
      });
      setExpanded(0);
    } else {
      LocationStore.setState({
        ...LocationStore.state,
        chargerInfoExpanded: true,
      });
      setExpanded(id);
    }
  };

  const handleCheckboxClick = (connectorId: number) => {
    handleExpanded(connectorId);
    handleSelection(connectorId);
  };

  const popupMainText = () => {
    return (
      <div className="delete-charger-popup">
        <div className="popout-main-text">
          Do you want to delete selected charger?
        </div>
        <div className="flex flex-row justify-start items-center gap-3">
          <img src="/icons/nav/activeDot.svg" alt="" />
          <div className="popout-other-text">Charger ID: {selectedCharger}</div>
        </div>
        <div className="border-b-[2.5px] border-solid border-[#E7E9ED] w-full my-3"></div>
      </div>
    );
  };

  const handleDeleteCharger = async () => {
    try {
      await DeleteCharger(selectedCharger);
      const result = await GetConnectorInfo(expanded);
      GetChargers(locationId);
      setConnectorInfo(result);
      setDeleteCharger(false);
      setDeleteChargerToast(true);
    } catch (error) {
      console.error(
        "Error deleting charger or fetching connector info:",
        error
      );
    }
  };

  const downloadQRCode = (qrString: string, connectorId: string) => {
    const qrDataUrl = generateQR(qrString);
    const link = document.createElement("a");
    link.href = qrDataUrl;
    link.download = "connector-" + connectorId + ".png";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <>
      <div className="flex-location" style={{ margin: "30px 0px" }}>
        <p className="title 2xl:text-[24px] md:text-[20px] sm:text-[16px]">
          Charger Information
        </p>
        <button
          className={`${selectedCharger ? "delete-charger-btn-active" : ""
            } delete-charger-btn`}
          disabled={!selectedCharger}
          onClick={() => setDeleteCharger(true)}
        >
          <p className={selectedCharger ? "delete-text-active" : "delete-text"}>
            Delete
          </p>
        </button>
      </div>
      <div className="charger-location-line" />
      <Table
        tableHeaders={tableHeaders}
        tableRowOrder={tableRowOrder}
        tableRows={chargers ? chargers : []}
        selected={selectedCharger}
        handleSelection={(id) => handleSelection(id)}
        noMargin
        expanded={expanded}
        handleExpanded={handleCheckboxClick}
      >
        <tr className="subtable-row">
          <td id="initial-td" colSpan={5}>
            <div>
              <table className="w-[100%]">
                <thead>
                  <tr>
                    <th className="subtable-header"> </th>
                    <th className="subtable-header"> </th>
                    <th className="subtable-header">Connector ID</th>
                    <th className="subtable-header">Connector Status</th>
                    <th className="subtable-header">Connector Type</th>
                    <th className="subtable-header">Electric Current</th>
                    <th className="subtable-header">Electricity Price</th>
                    <th className="subtable-header">QR Code</th>
                  </tr>
                </thead>
                <tbody>
                  {connectorInfo && connectorInfo.length > 0 ? (
                    connectorInfo.map((connector) => (
                      <tr className="subtable-tr" key={connector.id}>
                        <td
                          className="subtable-td"
                          style={{ whiteSpace: "nowrap" }}
                          colSpan={2}
                        ></td>
                        <td
                          className="subtable-td"
                          style={{ whiteSpace: "nowrap" }}
                        >
                          {connector.connectorName}
                        </td>
                        <td className="subtable-td">
                          <div
                            className={`${connector.connectorStatus === "Available"
                                ? "available-green"
                                : connector.connectorStatus === "Faulted"
                                  ? "offline-red"
                                  : "in-use-blue"
                              } connector-status-container-charger`}
                          >
                            {connector.connectorStatus}
                          </div>
                        </td>
                        <td className="subtable-td">
                          {connector.connectorType}
                        </td>
                        <td className="subtable-td">
                          {connector.electricCurrent}
                        </td>
                        <td className="subtable-td">
                          {connector.pricePerKwh} {connector.currencyIso}/kWh
                        </td>
                        <td
                          className="subtable-td"
                          style={{ padding: "1rem 1rem" }}
                        >
                          <button
                            className="underline"
                            onClick={() =>
                              downloadQRCode(
                                connector.qrString,
                                connector.connectorName
                              )
                            }
                          >
                            Download QR
                          </button>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td
                        className="subtable-td"
                        style={{ whiteSpace: "nowrap" }}
                        colSpan={2}
                      ></td>
                      <td colSpan={5}>No connectors in selected charger</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </td>
        </tr>
      </Table>
      {editCharger && <EditInformation showModal={setEditCharger} />}
      {deleteCharger && (
        <Popup
          mainText={popupMainText()}
          confirmText="Yes"
          closeText="Close"
          confirmFunction={handleDeleteCharger}
          closeFunction={() => setDeleteCharger(false)}
        />
      )}
    </>
  );
};

export default ChargerInformation;
