import { Store } from "react-stores";
import { IActiveStore } from "../interfaces/IStores";
import { AuthStore } from "./authStore";
import {
  concatenateDateTime,
  convertToLocalTime,
  extractTime,
  formatDate,
  formatDateLocale,
  formatTime,
  getFormattedQueryString,
  getMarketsToformattedString,
} from "../helpers/dataHelper";
const PUBLIC_BASE_URL = process.env.REACT_APP_BASE_URL;

export const ActiveStore = new Store<IActiveStore>({
  activePerPageData: [],
  totalCount: 0,
});

export const handleGetActiveSessions = async (
  companyMarketIds: number[],
  locationIds: number[] = [],
  chargerIds: number[] = [],
  pageIdx: number = 1,
  pageSize: number = 10,
  keyword: string = "",
  sortingField: string = "",
  sortingDescending: boolean = true,
  startDate: string = "",
  endDate = ""
) => {
  try {
    let jwtString: string | null = AuthStore.state.jwt;
    if (!jwtString) jwtString = sessionStorage.getItem("jwt");
    const myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + jwtString);

    const queryString = getFormattedQueryString({
      CompanyMarketIds: companyMarketIds,
      LocationIds: locationIds,
      ChargerIds: chargerIds,
    });

    const response = await fetch(
      `${PUBLIC_BASE_URL}api/v1.1/B2B/Sessions/GetActiveSessionsPaginated${queryString}&pageIndex=${pageIdx}&pageSize=${pageSize}${
        keyword ? "&keyword=" + keyword : ""
      }${startDate ? "&dateStartFrom=" + startDate : ""}${
        endDate ? "&DateStartTo=" + endDate : ""
      }${
        sortingField
          ? `&SortingCriteria.Field=${sortingField}&SortingCriteria.Descending=${sortingDescending}`
          : `&SortingCriteria.Field=timeStart&SortingCriteria.Descending=${sortingDescending}`
      }`,
      {
        method: "GET",
        headers: myHeaders,
      }
    );
    const data = await response.json();
    data.data.forEach((el) => {
      el.timeStart =
        formatDateLocale(el.timeStart) + " " + extractTime(el.timeStart);
      el.electricityConsumed =
        String(
          Math.abs(Math.round(el.electricityConsumed * 100) / 100).toFixed(2)
        ) + " kWh";
      el.duration = formatTime(el.duration);
      el.currentCarBattery =
        el.currentCarBattery >= 0
          ? String(el.currentCarBattery.toFixed(0)) + "%"
          : "n/a";
      el.totalPrice =
        String((Math.round(el.totalPrice * 100) / 100).toFixed(2)) +
        " " +
        el.currencyISO;
    });
    ActiveStore.setState({
      activePerPageData: data.data,
      totalCount: data.totalCount,
    });
    return data.data;
    // activePerPageData.set(data.data);
    // totalCount.set(data.totalCount);
  } catch (e) {
    console.log("Error: ", e);
  }
};
