import { Store } from "react-stores";
import { IAlarmsStore } from "../interfaces/IStores";
import { AuthStore } from "./authStore";
import {
  extractTime,
  formatDateLocale,
  formatRelativeTime,
} from "../helpers/dataHelper";
import { AlarmType, IAlarmsNotification } from "../interfaces/IAlarms";
import moment from "moment";

const PUBLIC_BASE_URL = process.env.REACT_APP_BASE_URL;

export const AlarmsStore = new Store<IAlarmsStore>({
  alarms: [],
  alarmsCount: 0,
  alarmsCountByDay: [],
  alarmsCountByType: null,
  alarmsCountLastDay: 0,
  selectedAlarm: 0,
});

export const handleGetAlarms = async (
  pageIdx: number = 1,
  pageSize: number = 10,
  startDate: string = "",
  endDate: string = "",
  alarmType: AlarmType[],
  sortingField: string = "",
  sortingDescending: boolean = true
) => {
  try {
    let jwtString: string | null = AuthStore.state.jwt;
    if (!jwtString) jwtString = sessionStorage.getItem("jwt");
    const myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + jwtString);

    let alarmTypeParams = "";
    alarmType.forEach((type, idx) => {
      alarmTypeParams += `&TypeFilter.Types=${type}`;
    });
    let response = await fetch(
      `${PUBLIC_BASE_URL}api/v1.1/B2B/Alarms/GetAlarms?PageIndex=${pageIdx}&PageSize=${pageSize}${alarmTypeParams}${
        startDate && "&DateTimeFilter.from=" + startDate
      }${endDate && "&DateTimeFilter.to=" + endDate}${
        alarmType ? `&TypeFilter.Type=${alarmType}` : ""
      }${
        sortingField
          ? `&SortingCriteria.Field=${sortingField}&SortingCriteria.Descending=${sortingDescending}`
          : ""
      }`,
      {
        method: "GET",
        headers: myHeaders,
      }
    );

    const data = await response.json();
    data.data.forEach((el) => {
      el.createdAt =
        formatDateLocale(el.createdAt) + " " + extractTime(el.createdAt);
      el.connectorStringId =
        el.connectorStringId === "__" ? undefined : el.connectorStringId;
    });
    AlarmsStore.setState({
      ...AlarmsStore.state,
      alarms: data.data,
      alarmsCount: data.totalCount,
    });
  } catch (e) {
    console.log("Error: ", e);
  }
};

export const handleGetAlarmsCountByType = async (
  startDate: string = "",
  endDate: string = ""
) => {
  try {
    let jwtString: string | null = AuthStore.state.jwt;
    if (!jwtString) jwtString = sessionStorage.getItem("jwt");
    const myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + jwtString);
    let response = await fetch(
      `${PUBLIC_BASE_URL}api/v1.1/B2B/Alarms/GetAlarmsCountByType${
        startDate && endDate ? `?from=${startDate}&to=${endDate}` : ""
      }`,
      {
        method: "GET",
        headers: myHeaders,
      }
    );

    const data = await response.json();
    AlarmsStore.setState({
      ...AlarmsStore.state,
      alarmsCountByType: data,
    });
  } catch (e) {
    console.log("Error: ", e);
  }
};

export const handleGetAlarmsCountPerDay = async (
  startDate: string = "",
  endDate: string = ""
) => {
  try {
    let jwtString: string | null = AuthStore.state.jwt;
    if (!jwtString) jwtString = sessionStorage.getItem("jwt");
    const myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + jwtString);
    let response = await fetch(
      `${PUBLIC_BASE_URL}api/v1.1/B2B/Alarms/GetCountOfAlarmsPerDay${
        startDate && endDate ? `?from=${startDate}&to=${endDate}` : ""
      }`,
      {
        method: "GET",
        headers: myHeaders,
      }
    );

    const data = await response.json();
    data.alarmsPerDay.forEach((el) => {
      el.day = moment(el.day).format("DD MMM");
    });
    AlarmsStore.setState({
      ...AlarmsStore.state,
      alarmsCountByDay: data.alarmsPerDay,
    });
  } catch (e) {
    console.log("Error: ", e);
  }
};

export const handleGetAlarmsCountInLastDay = async () => {
  try {
    let jwtString: string | null = AuthStore.state.jwt;
    if (!jwtString) jwtString = sessionStorage.getItem("jwt");
    const myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + jwtString);
    let response = await fetch(
      `${PUBLIC_BASE_URL}api/v1.1/B2B/Alarms/GetCountOfAlaramsInLastDay`,
      {
        method: "GET",
        headers: myHeaders,
      }
    );

    const data = await response.json();
    AlarmsStore.setState({
      ...AlarmsStore.state,
      alarmsCountLastDay: data,
    });
  } catch (e) {
    console.log("Error: ", e);
  }
};

export const handleMarkAsRead = async (alarmIds: number[]) => {
  try {
    let jwtString: string | null = AuthStore.state.jwt;
    if (!jwtString) jwtString = sessionStorage.getItem("jwt");
    if (!jwtString) return false;
    const response = await fetch(
      `${PUBLIC_BASE_URL}api/v1.1/B2B/Alarms/MarkAsread`,
      {
        method: "POST",
        body: JSON.stringify(alarmIds),
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + jwtString,
        },
      }
    );
    return response.ok;
  } catch (e) {
    console.log("Error: ", e);
    return false;
  }
};

export const handleMarkAllAsRead = async () => {
  try {
    let jwtString: string | null = AuthStore.state.jwt;
    if (!jwtString) jwtString = sessionStorage.getItem("jwt");
    if (!jwtString) return false;
    const response = await fetch(
      `${PUBLIC_BASE_URL}api/v1.1/B2B/Alarms/MarkAllAsRead`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + jwtString,
        },
      }
    );
    return response.ok;
  } catch (e) {
    console.log("Error: ", e);
    return false;
  }
};

export const handleSetSelectedAlarm = (id: number) => {
  AlarmsStore.setState({
    ...AlarmsStore.state,
    selectedAlarm: id,
  });
};
