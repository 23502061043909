import React, { useState, useEffect } from "react";
import "./EvseStatusPopup.css";
import Table from "../../shared/table/Table";
import Paging from "../../shared/paging/Paging";
import { EVSEStatus } from "./DashboardOverview";

interface Props {
  handleExit: () => void;
  data: any[];
  status: EVSEStatus;
}

const EvseStatusPopup: React.FC<Props> = ({ handleExit, status, data }) => {
  return (
    <>
      <div className="modal-background" onClick={() => handleExit()} />
      <div className="absolute-evse-status pt-2">
        <div className="flex flex-col items-center justify-start gap-3">
          <div className="flex justify-end items-center w-full p-5">
            <img
              src="/icons/exit/cross-big.svg"
              alt=""
              onClick={() => handleExit()}
              className="cursor-pointer"
            />
          </div>
          <div className="horizontal-gray-line w-full"></div>
          <div className="flex flex-col justify-start items-stretch w-full p-10 gap-6">
            <div className="flex justify-start items-center gap-3">
              <div
                className={`evse-circle ${status === "Available"
                  ? "evse-green-circle"
                  : status === "Unavailable"
                    ? "evse-yellow-circle"
                    : status === "OutOfService"
                      ? "evse-red-circle"
                      : ""
                  }`}
              ></div>
              <p className="availableText">
                {status === "Available"
                  ? "Available"
                  : status === "Unavailable"
                    ? "Occupied"
                    : status === "OutOfService"
                      ? "Out of order"
                      : ""}{" "}
                EVSEs
              </p>
            </div>
            <div className="h-[2.5px] bg-[#B5BAC680] w-full mt-3"></div>
            <div className="flex flex-col justify-start items-stretch w-full">
              <Table
                tableHeaders={tableHeaders}
                tableRowOrder={tableRowOrder}
                selected={0}
                handleSelection={() => undefined}
                tableRows={data}
                preloadRowNumber={10}
                noMargin={true}
              />
              <Paging
                tableSize={tableHeaders.length}
                pageChangeFunc={(page, perPage) => undefined}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EvseStatusPopup;

const tableHeaders = [
  "EVSE Id",
  "Current Type",
  "Connector Type",
  "Location Name",
];

const tableRowOrder = [
  "evseId",
  "electricityCurrent",
  "connectorType",
  "locationName",
];

const dummyData = [
  {
    id: "TAC5583793_0_1",
    current: "DC",
    connectorType: "CHAdeMo",
    location: "Avenue Mall Garaža -3",
  },
  {
    id: "TAC5583793_0_1",
    current: "DC",
    connectorType: "CHAdeMo",
    location: "Avenue Mall Garaža -3",
  },
  {
    id: "TAC5583793_0_1",
    current: "DC",
    connectorType: "CHAdeMo",
    location: "Avenue Mall Garaža -3",
  },
  {
    id: "TAC5583793_0_1",
    current: "DC",
    connectorType: "CHAdeMo",
    location: "Avenue Mall Garaža -3",
  },
  {
    id: "TAC5583793_0_1",
    current: "DC",
    connectorType: "CHAdeMo",
    location: "Avenue Mall Garaža -3",
  },
  {
    id: "TAC5583793_0_1",
    current: "DC",
    connectorType: "CHAdeMo",
    location: "Avenue Mall Garaža -3",
  },
];
