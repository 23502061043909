import L from "leaflet";
import {
  useEffect,
  useRef,
  useState,
  forwardRef,
  useImperativeHandle,
} from "react";
import { useMap } from "react-leaflet";
import "leaflet.markercluster/dist/leaflet.markercluster";
import "leaflet.markercluster/dist/MarkerCluster.css";
import "leaflet.markercluster/dist/MarkerCluster.Default.css";
import { CustomMarker, CustomMarkerOptions } from "./CustomMarkerClass";
import MarkerModal from "../markerModal/MarkerModal";
import {
  CustomMarkerIcon,
  NewCustomMarkerIcon,
} from "../../../../helpers/componentsHelper";
import { ICreateLocation, ILocation } from "../../../../interfaces/ILocation";
import {
  CreateLocation,
  GetLocations,
  LocationStore,
  UpdateLocationInformation,
} from "../../../../stores/locationStore";
import { useStore } from "react-stores";
import { useToast } from "../../../../shared/toast/ToastContent";

interface Props {
  locations: ILocation[];
  newLocation: ICreateLocation | null;
  setLocation: Function;
}
export interface MarkerClusterRef {
  onLocationUpdate: (updatedLocation: ILocation) => void;
}
const MarkerCluster = forwardRef<MarkerClusterRef, Props>(
  ({ locations, setLocation, newLocation }, ref) => {
    const map = useMap();

    const { showToast } = useToast();

    const [selectedMarker, setSelectedMarker] = useState<number>(0);

    const [markerLocations, setMarkerLocations] = useState<ILocation[]>([]);
    const draggableMarkerRef = useRef<L.Marker | null>(null);

    const { deletingLocation } = useStore(LocationStore);
    const markersRef = useRef<{ [key: number]: L.Marker }>({});

    const mcg = useRef(
      L.markerClusterGroup({
        iconCreateFunction: (cluster) => {
          const { connectorsCountSum, connectorsSum } = cluster
            .getAllChildMarkers()
            .reduce(
              (acc, marker) => {
                const options = marker.options as CustomMarkerOptions;
                return {
                  connectorsCountSum:
                    acc.connectorsCountSum + (options.connectorsCount || 0),
                  connectorsSum:
                    acc.connectorsSum + (options.connectorsSum || 0),
                };
              },
              { connectorsCountSum: 0, connectorsSum: 0 }
            );

          return CustomMarkerIcon(connectorsCountSum, connectorsSum);
        },
      })
    ).current;

    const onLocationUpdate = async (updatedLocation: ILocation) => {
      deleteMarker(updatedLocation.id);

      // const updatedMarkers = markerLocations.map((location) =>
      //   location.id === updatedLocation.id ? updatedLocation : location
      // );
      // setMarkerLocations(updatedMarkers);

      console.log("I just got here.");

      let updatedMarker;
      updatedMarker = new CustomMarker(
        new L.LatLng(updatedLocation.latitude, updatedLocation.longitude),
        {
          icon: NewCustomMarkerIcon(updatedLocation.connectorsCount),
          connectorsCount: updatedLocation.connectorsCount,
          draggable: true,
        }
      );

      map.setView([updatedLocation.latitude, updatedLocation.longitude], 17);

      updatedMarker.on("add", () => {
        const confirmButton = updatedMarker
          .getElement()
          ?.querySelector(".confirm-button");
        if (confirmButton) {
          confirmButton.addEventListener("click", async () => {
            if (draggableMarkerRef.current) {
              const { lat, lng } = draggableMarkerRef.current.getLatLng();
              const updatedLocationNew = {
                ...updatedLocation,
                latitude: lat,
                longitude: lng,
              };
              const success = await UpdateLocationInformation(
                updatedLocationNew
              );
              if (success) {
                showToast("Location successfully updated", "success");
                const updatedLocations = await GetLocations();
                if (updatedLocations) {
                  setMarkerLocations(updatedLocations);
                }

                markersRef.current = updatedMarker;
                mcg.addLayer(updatedMarker);
                map.addLayer(updatedMarker);
                map.setView(
                  [updatedLocationNew.latitude, updatedLocationNew.longitude],
                  17
                );
              } else {
                showToast("Failed to update location", "error");
              }
            }
          });
        }
      });
      draggableMarkerRef.current = updatedMarker;

      map.addLayer(updatedMarker);
      map.setView([updatedLocation.latitude, updatedLocation.longitude], 18);
    };

    useImperativeHandle(ref, () => ({
      onLocationUpdate,
    }));

    useEffect(() => {
      const fetchLocations = async () => {
        const locations = await GetLocations();
        if (locations) {
          setMarkerLocations(locations);
        }
      };

      fetchLocations();
    }, []);

    const handleConfirm = async () => {
      if (draggableMarkerRef.current && newLocation) {
        const { lat, lng } = draggableMarkerRef.current.getLatLng();
        const updatedLocation = {
          ...newLocation,
          latitude: lat,
          longitude: lng,
        };
        const success = await CreateLocation(updatedLocation);
        if (success) {
          setLocation(null);
          map.removeLayer(draggableMarkerRef.current);
          draggableMarkerRef.current = null;
          const updatedLocations = await GetLocations();
          if (updatedLocations) {
            showToast("Location successfully created", "success");
            map.setView(
              [updatedLocation.latitude, updatedLocation.longitude],
              18
            );
            setMarkerLocations(updatedLocations);
          }
        } else {
          console.error("Failed to create location");
        }
      }
    };

    const deleteMarker = (locationId: number) => {
      const marker = markersRef.current[locationId];

      if (marker) {
        mcg.removeLayer(marker);
        map.removeLayer(marker);
      }

      const updatedLocations = locations.filter(
        (location) => location.id !== locationId
      );

      map.setZoom(16);
      setMarkerLocations(updatedLocations);
    };

    useEffect(() => {
      map.invalidateSize(false);
      mcg.clearLayers();

      if (markerLocations && markerLocations.length > 0) {
        markerLocations.forEach((location: ILocation) => {
          const marker = new CustomMarker(
            new L.LatLng(location.latitude, location.longitude),
            {
              icon: CustomMarkerIcon(
                location.availableConnectorsCount,
                location.connectorsCount
              ),
              connectorsCount: location.availableConnectorsCount,
              connectorsSum: location.connectorsCount,
            }
          ).on("click", () => {
            setSelectedMarker(location.id);
          });
          markersRef.current[location.id] = marker;
          mcg.addLayer(marker);
          map.setZoom(17);
        });
      }
      map.addLayer(mcg);
    }, [map, setSelectedMarker, locations, markerLocations]);

    useEffect(() => {
      if (deletingLocation?.isDeleted && deletingLocation.locationId) {
        deleteMarker(deletingLocation.locationId);
        LocationStore.setState({
          ...LocationStore.state,
          deletingLocation: null,
        });
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [deletingLocation]);

    useEffect(() => {
      let tempMarker;
      if (newLocation) {
        if (draggableMarkerRef.current) {
          map.removeLayer(draggableMarkerRef.current);
          draggableMarkerRef.current = null;
        }

        tempMarker = new CustomMarker(
          new L.LatLng(newLocation.latitude, newLocation.longitude),
          {
            icon: NewCustomMarkerIcon(0),
            connectorsCount: 0,
            draggable: true,
          }
        );
        tempMarker.on("add", () => {
          const confirmButton = tempMarker
            .getElement()
            ?.querySelector(".confirm-button");
          if (confirmButton) {
            confirmButton.addEventListener("click", handleConfirm);
          }
        });
        draggableMarkerRef.current = tempMarker;

        map.addLayer(tempMarker);
        map.setView([newLocation.latitude, newLocation.longitude], 18);
      }

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [newLocation]);

    const closeMarkerModal = () => {
      setSelectedMarker(0);
    };

    useEffect(() => {
      if (selectedMarker !== 0) {
        map.dragging.disable();
        map.doubleClickZoom.disable();
        map.touchZoom.disable();
        map.boxZoom.disable();
        map.keyboard.disable();
        map.scrollWheelZoom.disable();
      } else {
        map.dragging.enable();
        map.doubleClickZoom.enable();
        map.touchZoom.enable();
        map.boxZoom.enable();
        map.keyboard.enable();
        map.scrollWheelZoom.enable();
      }
    }, [selectedMarker, map]);

    return (
      <>
        {selectedMarker !== 0 && (
          <MarkerModal
            locationId={selectedMarker}
            closeModal={closeMarkerModal}
            onLocationUpdate={onLocationUpdate}
          />
        )}
      </>
    );
  }
);

export default MarkerCluster;
