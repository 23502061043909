import React, { useEffect, useRef, useState } from "react";
import { ICompanyMarketRoaming } from "../../../interfaces/IRoaming";
import { useOutsideAlerter } from "../../../helpers/componentsHelper";
import { useCompanyMarketsDropdownQuery } from "../../../hooks/roaming/useCompanyMarketsDropdown";

interface MarketDropdownProps {
  handleSelection: (selectedMarket: ICompanyMarketRoaming | null) => void;
}

const RoamingMarketDropdown: React.FC<MarketDropdownProps> = ({
  handleSelection,
}) => {
  const { data: companyMarkets } = useCompanyMarketsDropdownQuery(true);
  const [expanded, setExpanded] = useState(false);
  const [selectedMarket, setSelectedMarket] =
    useState<ICompanyMarketRoaming | null>(null);

  useEffect(() => {
    if (companyMarkets && companyMarkets.length > 0) {
      handleSelection(null);
    }
  }, [companyMarkets]);

  const wrapperRef = useRef<HTMLDivElement>(null);
  useOutsideAlerter(wrapperRef, () => setExpanded(false));

  const handleMarketChange = (market: ICompanyMarketRoaming) => {
    setSelectedMarket(market);
    handleSelection(market);
    setExpanded(false);
  };

  return (
    <div
      className="flex flex-col justify-start items-end gap-3 relative"
      ref={wrapperRef}
    >
      <button
        className={`market-selection-btn ${
          expanded ? "market-selection-btn-active" : ""
        }`}
        onClick={() => setExpanded((prev) => !prev)}
      >
        <p>{selectedMarket?.companyMarketName || "Company Market"}</p>
        <img
          src={
            expanded
              ? "/icons/arrow/arrowUpWhite.svg"
              : "/icons/arrow/arrowDownDarkBlue.svg"
          }
          alt="Toggle Dropdown"
        />
      </button>
      {expanded && (
        <div
          className={`market-dropdown-roaming-container ${
            companyMarkets && companyMarkets?.length >= 10
              ? "overflow-y-auto"
              : "overflow-y-visible"
          }`}
          style={{
            height:
              companyMarkets && companyMarkets?.length! >= 10
                ? "450px"
                : "auto",
          }}
        >
          {companyMarkets &&
            companyMarkets.map((market) => (
              <div
                key={market.id}
                className={`market-dropdown-hoverable ${
                  selectedMarket?.id === market.id
                    ? "market-dropdown-selected"
                    : ""
                }`}
                onClick={() => handleMarketChange(market)}
              >
                {selectedMarket?.id === market.id ? (
                  <img
                    src="/icons/correct-incorrect-icons/correct-icon-squared.svg"
                    alt="Selected"
                    className="correct-icon"
                  />
                ) : (
                  <div className="checker-border" />
                )}
                <p className="text-left">{market.companyMarketName}</p>
              </div>
            ))}
        </div>
      )}
    </div>
  );
};

export default RoamingMarketDropdown;
