import L from "leaflet";
import { useEffect } from "react";

function useOutsideAlerter(ref, handlerFunction) {
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        handlerFunction();
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);
}

export const CustomMarker = (number: number, connectorSum: number) => {
  return new L.DivIcon({
    className: "custom-marker-icon",
    iconSize: [72, 75],
    iconAnchor: [15, 45],
    html: `<div class="marker">
             <img src="/icons/map/marker.svg" alt="Marker" style="width: 100%; height: 100%;"/>
             <div class="circle"></div>
            <div class="number-overlay">${number}/${connectorSum}</div>
          </div>`,
  });
};

export const CustomMarkerIcon = (number: number, connectorSum: number) => {
  const borderColor =
    number === 0 ? "#ED1F0E" : number === connectorSum ? "#00FF26" : "#EDE60E";

  return new L.DivIcon({
    className: "custom-marker-icon",
    iconSize: [72, 75],
    iconAnchor: [30, 65],
    html: `<div class="marker">
             <img src="/icons/map/marker.svg" alt="Marker" style="width: 100%; height: 100%;"/>
             <div class="circle" style="border-color: ${borderColor};">
              <div class="number-overlay">${number}/${connectorSum}</div>
             </div>
            
          </div>`,
  });
};

export const NewCustomMarkerIcon = (number: number) => {
  return new L.DivIcon({
    className: "custom-marker-icon",
    iconSize: [72, 75],
    iconAnchor: [30, 190],
    html: `<div class="marker-container">
            <div class="custom-popup">
              <div class="upper-part">
               <p class="text">Confirm location on the map or move pin to new more accurate location.</p>
              </div>
              <div class="lower-part">
                <button class="confirm-button">Confirm</button>
              </div>
            </div>
            <div class="newMarker">
             <img src="/icons/map/marker.svg" alt="Marker" style="width: 100%; height: 100%;"/>
             <div class="circle">
              <div class="number-overlay">${number}</div>
             </div>
            </div>
          </div>`,
  });
};

export const selectStyling = {
  control: (styles, state) => ({
    ...styles,
    "&:hover": {
      border: "2px solid #111F47",
    },
    boxShadow: "none",
    fontSize: "14px",
    fontWeight: "500",
    color: "#111F47 !important",
    fontFamily: "'Inter'",
    borderRadius: "10px",
    height: "45px",
    width: "100%",
    border: state.isFocused ? "2px solid #111F47" : "2px solid #B5BAC6",
    background: "white",
  }),
  option: (baseStyles, state) => ({
    ...baseStyles,
    "&:hover": {
      background: "#E9EDFC",
    },
    background: state.isSelected ? "#E9EDFC" : "#FAFBFB",
    fontSize: "12px",
    fontWeight: "500",
    fontFamily: "'Inter'",
    color: "#111F47",
    borderRadius: "10px",
    border: "4px solid white",
    padding: "10px",
  }),
  placeholder: (defaultStyles) => {
    return {
      ...defaultStyles,
      color: "#B5BAC6",
    };
  },
};

export { useOutsideAlerter };
