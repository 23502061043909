import React, { useState, useEffect, Fragment, useRef } from "react";
import { useStore } from "react-stores";
import "./Alarms.css";
import DateSelectRange from "../../../shared/calendar/DateSelectRange";
import { formatDateLocale } from "../../../helpers/dataHelper";
import Paging from "../../../shared/paging/Paging";
import AlarmTypeDropdown from "./alarmTypeDropdown/AlarmTypeDropdown";
import AlarmsByType from "./charts/AlarmsByType";
import NewAlarms from "./NewAlarms";
import { AlarmType, IAlarm } from "../../../interfaces/IAlarms";
import {
  AlarmsStore,
  handleGetAlarms,
  handleGetAlarmsCountByType,
  handleGetAlarmsCountInLastDay,
  handleGetAlarmsCountPerDay,
  handleMarkAllAsRead,
  handleMarkAsRead,
  handleSetSelectedAlarm,
} from "../../../stores/alarmsStore";
import Spinner from "../../../shared/spinner/Spinner";

const Alarms = () => {
  const [paging, setPaging] = useState({ page: 1, perPage: 10 });
  const [loading, setLoading] = useState(false);
  const [calendar, setCalendar] = useState({ startDate: "", endDate: "" });
  const [showDateModal, setShowDateModal] = useState<boolean>(false);
  const [expanded, setExpanded] = useState<IAlarm | null>(null);
  const [hasUnread, setHasUnread] = useState<boolean>(false);
  const [sort, setSort] = useState({ field: "", method: "descending" });
  const [selectedType, setSelectedType] = useState<AlarmType[]>([
    AlarmType.Warning,
    AlarmType.Error,
    AlarmType.Notification,
  ]);
  const {
    alarms,
    alarmsCount,
    alarmsCountByDay,
    alarmsCountByType,
    alarmsCountLastDay,
    selectedAlarm,
  } = useStore(AlarmsStore);

  useEffect(() => {
    handleGetAlarms(
      paging.page,
      paging.perPage,
      calendar.startDate,
      calendar.endDate,
      selectedType,
      sort.field,
      sort.method === "descending"
    );
  }, [paging, sort, selectedType, calendar]);

  useEffect(() => {
    handleGetAlarmsCountPerDay(calendar.startDate, calendar.endDate);
    handleGetAlarmsCountByType(calendar.startDate, calendar.endDate);
    handleGetAlarmsCountInLastDay();
  }, [calendar]);

  useEffect(() => {
    if (selectedAlarm) {
      handleExpanded(selectedAlarm);
      handleSetSelectedAlarm(0);
    }
    const el = alarms.find((el) => el.hasBeenRead === false);
    el ? setHasUnread(true) : setHasUnread(false);
  }, [alarms]);

  const handleClearDate = (event) => {
    event.stopPropagation();
    setCalendar({ startDate: "", endDate: "" });
  };

  const markAllAsRead = async () => {
    const alarmsCopy = [...alarms];
    alarmsCopy.forEach((alarm) => (alarm.hasBeenRead = true));
    AlarmsStore.setState({
      ...AlarmsStore.state,
      alarms: alarmsCopy,
    });
    await handleMarkAllAsRead();
    await handleGetAlarms(
      paging.page,
      paging.perPage,
      calendar.startDate,
      calendar.endDate,
      selectedType,
      sort.field,
      sort.method === "descending"
    );
  };

  const handleExpanded = (id: number) => {
    if (expanded?.id !== id) {
      setExpanded(alarms.find((el) => el.id === id) || null);
      const alarmsCopy = [...alarms];
      const index = alarmsCopy.findIndex((el) => el.id === id);
      if (index !== -1 && !alarmsCopy[index].hasBeenRead) {
        alarmsCopy[index].hasBeenRead = true;
        handleMarkAsRead([alarmsCopy[index].id]);
        AlarmsStore.setState({
          ...AlarmsStore.state,
          alarms: alarmsCopy,
        });
      }
    } else {
      setExpanded(null);
    }
  };

  const handleSortChanging = (sortingColumn: string) => {
    if (sort.field !== sortingColumn) {
      let newSort = { field: sortingColumn, method: "descending" };
      setSort(newSort);
    } else {
      let newSort = { ...sort };
      newSort.method =
        sort.method === "descending" ? "ascending" : "descending";
      newSort.field = sort.method === "descending" ? sort.field : "";
      setSort(newSort);
    }
  };

  return (
    <div className="flex-1 p-3.5 flex flex-col gap-3.5 items-stretch main-wrapper">
      <div className="bg-white card p-5">
        <div className="flex flex-row justify-between items-center header flex-wrap">
          <div className="alarms">
            <span>
              <span className="alarms-span">Operations/</span>
              <span className="alarms-span2">Alarms</span>
            </span>
          </div>
          <div className="flex flex-row gap-5 flex-wrap">
            <button
              onClick={() => setShowDateModal(!showDateModal)}
              className={`calendarButton ${
                showDateModal ? "calendarButtonActive" : ""
              } flex flex-row gap-3 items-center justify-center`}
            >
              {calendar.startDate && calendar.endDate ? (
                <div
                  className={
                    showDateModal
                      ? "calendarButtonActive picked-date-container-blue"
                      : "picked-date-container"
                  }
                >
                  <p className="date">
                    {formatDateLocale(calendar.startDate)} -{" "}
                    {formatDateLocale(calendar.endDate)}
                  </p>
                  {!showDateModal && (
                    <div className="date-exit-container">
                      <img
                        className="cursor-pointer exit-calendar-cross"
                        src="/icons/exit/cross.svg"
                        alt=""
                        onClick={handleClearDate}
                      />
                    </div>
                  )}
                </div>
              ) : (
                <>
                  <img
                    src={
                      showDateModal
                        ? "/icons/calendar/calendarIconWhite.svg"
                        : "/icons/calendar/calendarIcon.svg"
                    }
                    alt=""
                  />
                  Time Period
                  <img
                    src={
                      showDateModal
                        ? "/icons/arrow/arrowUpWhite.svg"
                        : "/icons/arrow/arrowDownDarkBlue.svg"
                    }
                    alt=""
                  />
                </>
              )}
            </button>
            {showDateModal && (
              <DateSelectRange
                showCalendar={setShowDateModal}
                start={calendar.startDate}
                end={calendar.endDate}
                handleApply={(start, end) => {
                  setCalendar({ startDate: start, endDate: end });
                  setPaging((prev) => ({ page: 1, perPage: prev.perPage }));
                }}
              />
            )}
          </div>
        </div>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-9 gap-3">
        <div className="md:col-span-3 flex flex-col gap-2.5 items-stretch justify-start p-4 alarms-stats-container h-[250px]">
          <p className="alarms-chart-title">Alarms by type</p>
          <AlarmsByType
            xValues={["Warning", "Error", "Notification"]}
            yValues={[
              alarmsCountByType?.warningCount || 0,
              alarmsCountByType?.errorCount || 0,
              alarmsCountByType?.notificationCount || 0,
            ]}
          />
        </div>
        <div className="md:col-span-2 flex flex-col gap-1 items-center justify-center p-7 alarms-stats-container">
          <p className="arrived-last-24">Arrived last 24h</p>
          <p className="arrived-last-24-num">{alarmsCountLastDay}</p>
        </div>
        <div className="md:col-span-4 flex flex-col gap-2.5 items-stretch justify-start p-4 alarms-stats-container h-250px]">
          <p className="alarms-chart-title">New alarms</p>
          <NewAlarms
            xValues={alarmsCountByDay.map((el) => el.day)}
            yValues={alarmsCountByDay.map((el) => el.count)}
          />
        </div>
      </div>
      <div className="table-container p-5">
        <div className="flex flex-col items-stretch justify-between overflow-auto gap-5 table-container overflow-visible">
          <div className="flex justify-end items-center gap-5">
            <AlarmTypeDropdown
              handleSelection={(selected) => {
                setSelectedType(selected);
              }}
            />
            <button
              className={hasUnread ? "mark-all-btn-active" : "mark-all-btn"}
              disabled={!hasUnread}
              onClick={markAllAsRead}
            >
              Mark all as a read
            </button>
          </div>
          <table className="table table-striped">
            <thead>
              <tr className="header-row table-row">
                <th></th>
                {tableHeaders.map((tableHeader, index) =>
                  sortColumns.includes(tableHeader) ? (
                    <th
                      key={index}
                      onClick={() => handleSortChanging(tableRowOrder[index])}
                    >
                      <div className="table-header-with-sort">
                        <p>{tableHeader}</p>
                        {sort.field === tableRowOrder[index] ? (
                          sort.method === "ascending" ? (
                            <div className="flex flex-col items-center">
                              <img
                                src="/icons/arrow/sortArrowUpActive.svg"
                                alt=""
                              />
                              <img
                                src="/icons/arrow/sortArrowDownInactive.svg"
                                alt=""
                              />
                            </div>
                          ) : sort.method === "descending" ? (
                            <div className="flex flex-col items-center">
                              <img
                                src="/icons/arrow/sortArrowUpInactive.svg"
                                alt=""
                              />
                              <img
                                src="/icons/arrow/sortArrowDownActive.svg"
                                alt=""
                              />
                            </div>
                          ) : (
                            <div className="flex flex-col items-center">
                              <img
                                src="/icons/arrow/sortArrowUpInactive.svg"
                                alt=""
                              />
                              <img
                                src="/icons/arrow/sortArrowDownInactive.svg"
                                alt=""
                              />
                            </div>
                          )
                        ) : (
                          <div className="flex flex-col items-center">
                            <img
                              src="/icons/arrow/sortArrowUpInactive.svg"
                              alt=""
                            />
                            <img
                              src="/icons/arrow/sortArrowDownInactive.svg"
                              alt=""
                            />
                          </div>
                        )}
                      </div>
                    </th>
                  ) : (
                    <th key={index}>{tableHeader}</th>
                  )
                )}
              </tr>
            </thead>
            <tbody>
              {alarms.map((dataRow) => (
                <Fragment key={dataRow.id}>
                  <div className="relative">
                    {!dataRow.hasBeenRead && (
                      <img
                        src="/icons/threeDots/blue-dot.svg"
                        alt=""
                        className="blue-dot-absolute"
                      />
                    )}
                  </div>
                  <tr
                    key={dataRow.id}
                    className={
                      !dataRow.hasBeenRead
                        ? "unread-row selectable-row relative"
                        : expanded?.id === dataRow.id
                        ? "selected-row selectable-row relative"
                        : "selectable-row relative"
                    }
                    onClick={() => handleExpanded(dataRow.id)}
                  >
                    <td>
                      <div className="checkbox-container">
                        <img
                          src={
                            expanded?.id === dataRow.id
                              ? "/icons/table/checkboxIconMinus.svg"
                              : "/icons/table/checkboxIconPlus.svg"
                          }
                          alt=""
                        />
                      </div>
                    </td>
                    {tableRowOrder.map((tableElement) =>
                      tableElement === "type" ? (
                        <td
                          className={
                            expanded?.id === dataRow.id ? "td-selected-row" : ""
                          }
                          key={tableElement}
                        >
                          <div
                            className={`${
                              dataRow[tableElement] === "Warning"
                                ? "warning-row"
                                : dataRow[tableElement] === "Error"
                                ? "error-row"
                                : "notification-row"
                            } 
                                                                connector-status-container`}
                          >
                            {dataRow[tableElement]}
                          </div>
                        </td>
                      ) : (
                        <td
                          className={
                            !dataRow.hasBeenRead
                              ? "unread-td"
                              : expanded?.id === dataRow.id
                              ? "td-selected-row"
                              : ""
                          }
                          key={tableElement}
                        >
                          {dataRow[tableElement]}
                        </td>
                      )
                    )}
                  </tr>
                  {expanded?.id === dataRow.id && (
                    <>
                      <tr className="subtable-row">
                        <td></td>
                        <td className="expanded-text">Full Description</td>
                        <td colSpan={3} className="expanded-text">
                          {expanded?.fullDescription}
                        </td>
                      </tr>
                      {expanded?.connectorStringId && (
                        <tr className="subtable-row">
                          <td></td>
                          <td className="expanded-text">Connector ID</td>
                          <td colSpan={3} className="expanded-text">
                            {expanded.connectorStringId}
                          </td>
                        </tr>
                      )}
                      {expanded?.chargerStringId && (
                        <tr className="subtable-row">
                          <td></td>
                          <td className="expanded-text">Charger ID</td>
                          <td colSpan={3} className="expanded-text">
                            {expanded.chargerStringId}
                          </td>
                        </tr>
                      )}
                      {expanded?.location && (
                        <tr className="subtable-row">
                          <td></td>
                          <td className="expanded-text">Location</td>
                          <td colSpan={3} className="expanded-text">
                            {expanded.location}
                          </td>
                        </tr>
                      )}
                    </>
                  )}
                </Fragment>
              ))}
            </tbody>
          </table>
        </div>
        <Paging
          tableSize={alarmsCount}
          pageChangeFunc={(page, perPage) =>
            setPaging({ page: page, perPage: perPage })
          }
        />
      </div>
      {loading && <Spinner />}
    </div>
  );
};

export default Alarms;

const tableHeaders = ["Datetime", "Title", "Alarm Type", "Description"];

const tableRowOrder = ["createdAt", "title", "type", "shortDescription"];

const sortColumns = ["Datetime", "Title", "Alarm Type"];
