import React, { useEffect, useRef, useState } from "react";
import "./MarketDropdown.css";
import { useOutsideAlerter } from "../../helpers/componentsHelper";
import {
  MarketStore,
  handleGetMarketsForDropdown,
} from "../../stores/marketStore";
import { useStore } from "react-stores";
import { IMarketDropdown } from "../../interfaces/IMarket";

interface MarketDropdownProps {
  handleSelection: Function;
}

const MarketDropdown: React.FC<MarketDropdownProps> = ({ handleSelection }) => {
  const [expanded, setExpanded] = useState(false);
  const [selectedMarkets, setSelectedMarkets] = useState<IMarketDropdown[]>([]);
  const { marketsForDropdown } = useStore(MarketStore);

  useEffect(() => {
    const getMarkets = async () => {
      handleGetMarketsForDropdown().then((res) => {
        setSelectedMarkets(res);
        handleSelection(res);
      });
    };

    getMarkets();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef, () => setExpanded(false));

  const handleMarketChange = (market: IMarketDropdown) => {
    if (selectedMarkets.some((m) => m.id === market.id)) {
      if (selectedMarkets.length > 1) {
        let newArray = [...selectedMarkets];
        let index = newArray.findIndex((m) => m.id === market.id);
        newArray.splice(index, 1);
        setSelectedMarkets(newArray);
        handleSelection(newArray);
      }
    } else {
      let newArray = [...selectedMarkets];
      newArray = newArray.concat(market);
      setSelectedMarkets(newArray);
      handleSelection(newArray);
    }
  };

  return (
    <div
      className="flex flex-col justify-start items-end gap-3 relative"
      ref={wrapperRef}
    >
      <button
        className={`market-selection-btn ${
          expanded ? "market-selection-btn-active" : ""
        }`}
        onClick={() => {
          setExpanded((prev) => !prev);
        }}
      >
        <p>Market</p>

        <img
          src={
            expanded
              ? "/icons/arrow/arrowUpWhite.svg"
              : "/icons/arrow/arrowDownDarkBlue.svg"
          }
          alt=""
        />
      </button>
      {expanded && (
        <div className="market-dropdown-container overflow-y-auto">
          {marketsForDropdown.map((market) => (
            <div
              key={market.id}
              className={`market-dropdown-hoverable ${
                selectedMarkets.some((m) => m.id === market.id) &&
                " market-dropdown-selected"
              }`}
              onClick={() => handleMarketChange(market)}
            >
              {selectedMarkets.some((m) => m.id === market.id) ? (
                <img
                  src="/icons/correct-incorrect-icons/correct-icon-squared.svg"
                  alt=""
                  className="correct-icon"
                />
              ) : (
                <div className="checker-border" />
              )}
              <p className="text-left">{market.name}</p>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default MarketDropdown;
