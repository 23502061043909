import React, { useState } from "react";
import { AuthStore, loginHandler, onSuccess } from "../../stores/authStore";
import Spinner from "../../shared/spinner/Spinner";
import Popup from "../../shared/popup/Popup";
import LoginLayout from "../LoginLayout";
import { useNavigate } from "react-router-dom";
import { GoogleLogin } from "@react-oauth/google";
import "./Login.css";

const Login = () => {
  const [visiblePassword, setVisiblePassword] = useState(false);
  const [loginFailed, setLoginFailed] = useState(false);
  const [loading, setLoading] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    setLoading(true);
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const object = {};
    data.forEach((value, key) => (object[key] = value));
    object["rememberMe"] = rememberMe;
    const json = JSON.stringify(object);
    const res = await loginHandler(json, rememberMe);
    if (res.error === "") {
      navigate("/dashboard/overview");
    } else {
      setLoading(false);
      setLoginFailed(true);
    }
  };

  const handleGoogleLogin = async (googleResponse) => {
    setLoading(true);
    console.log('Success: ', googleResponse)
    const result = await onSuccess(googleResponse);
    result.error
      ? setLoading(false)
      : navigate("/dashboard/overview");
  };

  const loginRenderPopup = () => {
    return (
      <>
        <img
          src="/icons/correct-incorrect-icons/incorrect-icon.svg"
          alt=""
          className="w-[81px]"
        />
        <div className="popup-main-text">Wrong password or e-mail</div>
        <div className="popup-other-text">Please try again</div>
      </>
    );
  };

  return (
    <LoginLayout>
      <div className="flex flex-col gap-10 items-center flex-1 self-center w-[30rem]">
        <h1 className="log-in">Log in</h1>
        <form
          className="flex flex-col gap-6 md:w-[30rem]"
          onSubmit={handleSubmit}
        >
          <div className="flex flex-col gap-1">
            <label htmlFor="email" className="login-email-password">
              E-mail
            </label>
            <input
              name="email"
              required
              id="email"
              type="email"
              className="login-input"
            />
          </div>
          <div className="flex flex-col gap-1">
            <label htmlFor="password" className="login-email-password">
              Password
            </label>
            <div className="relative">
              <input
                name="password"
                required
                id="password"
                type={visiblePassword ? "text" : "password"}
                className="login-input w-[100%]"
              />
              <img
                src={
                  visiblePassword
                    ? "/icons/eye/eye-closed.svg"
                    : "/icons/eye/eye-open.svg"
                }
                alt=""
                className="login-visible-passwords"
                onClick={() => setVisiblePassword(!visiblePassword)}
              />
            </div>
          </div>
          <div className="flex flex-row justify-between items-center">
            <label className="remember-me">
              <input
                type="checkbox"
                name="rememberMe"
                className="mr-1"
                onClick={() => setRememberMe(!rememberMe)}
              />
              Remember me
            </label>
            <p className="forgot-password">
              Forgot Password?{" "}
              <a
                className="forgot-password-click-here"
                href="/auth/forgotPassword"
              >
                Click here
              </a>
            </p>
          </div>
          <div className="flex flex-col gap-4 justify-center items-center">
            <button type="submit" className="login-button">
              Continue
            </button>
            <GoogleLogin
              text="continue_with"
              onSuccess={handleGoogleLogin}
              onError={() => {
                console.log("Login Failed");
              }}
              locale="EN-GB"
            />
          </div>
        </form>
        {loginFailed && (
          <Popup
            mainText={loginRenderPopup()}
            closeText="Close"
            confirmFunction={() => undefined}
            closeFunction={() => setLoginFailed(false)}
          ></Popup>
        )}
        {loading && <Spinner />}
      </div>
    </LoginLayout>
  );
};

export default Login;
