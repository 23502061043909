import React, { useEffect, useState } from "react";
import "./NetworkOnboarding.css";
import PendingConnection from "./pendingConnection/PendingConnection";
import FinishOnboardingTable from "./finishOnboarding/FinishOnboardingTable";
import AddNewCharger from "./addNewCharger/AddNewCharger";
import { useNavigate } from "react-router-dom";
import {
  handleCreateCharger,
  handleGetChargersOnboardingStatusCount,
} from "../../../stores/chargerStore";
import { IChargersOnboardingStatusCount } from "../../../interfaces/ICharger";
import CreateChargerModal from "./createChargerModal/CreatechargerModal";

interface NewCharger {
  id: string;
  locationId: number | undefined;
}

const NetworkOnboarding = () => {
  const [mode, setMode] = useState("Pending connection");
  const [chargerAdded, setChargerAdded] = useState(false);
  const [selected, setSelected] = useState(0);
  const [selectedCompanyMarket, setSelectedCompanyMarket] = useState(0);
  const [createChargerMode, setCreateChargerMode] = useState(false);
  const [creationPopup, setCreationPopup] = useState<boolean>(false);
  const [onboardingStatusCount, setOnboardingStatusCount] =
    useState<IChargersOnboardingStatusCount>({
      pendingCount: 0,
      connectedCount: 0,
    });
  const [newCharger, setNewCharger] = useState<NewCharger>({
    id: "",
    locationId: undefined,
  });
  const navigate = useNavigate();

  useEffect(() => {
    const getStatusCount = async () => {
      setOnboardingStatusCount(
        await handleGetChargersOnboardingStatusCount().then(
          (res) =>
            res || {
              pendingCount: 0,
              connectedCount: 0,
            }
        )
      );
    };
    getStatusCount();
  }, []);

  const handleSelection = (id: number) => {
    setSelected(selected === id ? 0 : id);
  };

  const creationPopupText = () => {
    return (
      <>
        <p className="instructions-popover-main-text">
          Onboard physical charger to Power Up backend using below instructions
          and paramenters
        </p>
        <div className="instructions-popover-row-container">
          <p className="instructions-popover-row-left">OCPP proxy url</p>
          <p className="instructions-popover-row-right">
            wss://ocpp.power-up.green
          </p>
        </div>
        <div className="instructions-popover-row-container">
          <p className="instructions-popover-row-left">WSS/WS</p>
          <p className="instructions-popover-row-right">wss</p>
        </div>
        <div className="instructions-popover-row-container">
          <p className="instructions-popover-row-left">Port</p>
          <p className="instructions-popover-row-right">443</p>
        </div>
        <div className="instructions-popover-row-container">
          <p className="instructions-popover-row-left">Available protocols</p>
          <p className="instructions-popover-row-right">OCPP16j, OCPP201</p>
        </div>
        <div className="instructions-popover-row-container">
          <p className="instructions-popover-row-left">Password</p>
          <p className="instructions-popover-row-right">abcdef</p>
        </div>
      </>
    );
  };

  const handleChargerCreation = async () => {
    const data = {
      chargerIdentificator: newCharger.id,
      locationId: newCharger.locationId,
    };
    await handleCreateCharger(JSON.stringify(data));
    setOnboardingStatusCount(await handleGetChargersOnboardingStatusCount());
    setChargerAdded((prev) => !prev);
    setMode("Pending connection");
    setCreationPopup(false);
  };

  return (
    <div className="flex-1 px-3.5 pt-3.5 flex flex-col gap-3.5 items-stretch main-wrapper">
      <div className="bg-white card p-5">
        <div className="flex flex-row justify-between items-center header flex-wrap">
          <div className="network-onboarding">
            <span>
              <span className="network-onboarding-span">Network/</span>
              <span className="network-onboarding-span2">
                Chargers Onboarding
              </span>
            </span>
          </div>
          <div className="flex flex-row gap-5 flex-wrap items-center">
            {mode === "Finish onboarding" && (
              <button
                className={`finish-onboarding-btn ${
                  selected && " finish-onboarding-btn-active"
                }`}
                onClick={() =>
                  navigate(
                    "/dashboard/network/onboarding/finishOnboarding/" +
                      selected +
                      "/" +
                      selectedCompanyMarket
                  )
                }
                disabled={!(selected && selectedCompanyMarket)}
              >
                <p>Finish Onboarding</p>
              </button>
            )}
            <button
              className="add-new-charger-btn flex flex-row justify-around items-center gap-2"
              onClick={() => setCreateChargerMode(true)}
            >
              <img src="/icons/admin/plusIcon.svg" alt="" />
              <p>Add New Charger</p>
            </button>
          </div>
        </div>
      </div>
      <div className="flex-1 bg-white card p-5 flex flex-col justify-start items-start overflow-visible relative">
        <div className="max-h-[calc(80vh-15rem)]"></div>
        <div className="onboarding-buttons-container mt-2 mb-6">
          <div
            className={`flex flex-row justify-center items-center onboarding-btn rounded-l-[10px] ${
              mode === "Pending connection" && " onboarding-btn-active"
            }`}
            onClick={() =>
              mode !== "Pending connection" && setMode("Pending connection")
            }
          >
            <div>Pending connection</div>
            <div
              className={`onboarding-btn-num ${
                mode === "Pending connection" && " onboarding-btn-num-active"
              }`}
            >
              <p className="onboarding-num">
                {onboardingStatusCount.pendingCount}
              </p>
            </div>
          </div>
          <div
            className={`flex flex-row justify-center items-center onboarding-btn rounded-r-[10px] ${
              mode === "Finish onboarding" && " onboarding-btn-active"
            }`}
            onClick={() =>
              mode !== "Finish onboarding" && setMode("Finish onboarding")
            }
          >
            <div>Finish onboarding</div>
            <div
              className={`onboarding-btn-num ${
                mode === "Finish onboarding" && " onboarding-btn-num-active"
              }`}
            >
              <p className="onboarding-num">
                {onboardingStatusCount.connectedCount}
              </p>
            </div>
          </div>
        </div>
        {mode === "Pending connection" ? (
          <PendingConnection chargerAdded={chargerAdded} />
        ) : (
          <FinishOnboardingTable
            handleSelection={(id, companyMarketId) => {
              handleSelection(id);
              setSelectedCompanyMarket(companyMarketId);
            }}
            selected={selected}
          />
        )}
      </div>
      {createChargerMode && (
        <AddNewCharger
          handleExit={(newCh: NewCharger) => {
            setCreateChargerMode(false);
            setNewCharger(newCh);
          }}
          showPopup={() => setCreationPopup(true)}
        />
      )}
      {creationPopup && (
        <CreateChargerModal
          mainText={creationPopupText()}
          closeFunction={() => setCreationPopup(false)}
          confirmText="OK"
          confirmFunction={() => handleChargerCreation()}
        />
      )}
    </div>
  );
};

export default NetworkOnboarding;
